
.settings-body 
{
	font-family: 'Poppins', sans-serif;
	background-color: rgba(255, 255, 255);

	.header-area {
		padding: 1vw 2vw;
	}

	.breadcrumb-area {
		padding: 2vw 2vw 0vw 2vw;
	}

	.breadcrumb .breadcrumb-item a {
		font-size: 1vw;
		text-decoration: none;
		font-weight: 400;
		color: rgba(100, 100, 100);
	}

	.breadcrumb .active {
		font-weight: 500;
		color: rgba(10, 10, 10);
	}

	.breadcrumb .breadcrumb-item a:hover {
		text-decoration: underline;
		color: rgba(20, 20, 20);
	}

	.main-heading {
		font-size: 1.5vw;
		font-weight: 600;
	}

	.settings-tabs-area .settings-tab {
		font-size: 1.1vw;
		margin: 0.5vw 0.5vw;
		display: inline-block;
		color: rgba(120, 120, 120);
		font-weight: 500;
		letter-spacing: 0.01rem;
		text-decoration: none;
		border-radius: 0.4vw;
		padding: 0.5vw 1vw;
	}

	.settings-tabs-area .settings-tab:first-child {
		margin: 0.5vw 0;
		margin-right: 0.6vw;
	}

	.settings-tabs-area .active {
		color: rgba(1, 1, 1);
		padding: 0.5vw 1vw;
		background: rgba(245, 245, 245);
		font-weight: 500;
	}

	.settings-tabs-area .settings-tab:hover {
		color: rgba(1, 1, 1);
	}

	.secondary-heading-area {
		padding: 1.5vw 0;
		margin-bottom: 1vw;
		border-top: 0.2vw solid rgba(10, 10, 10);
		border-bottom: 0.1vw solid rgba(230, 230, 230);
	}

	.secondary-heading {
		font-size: 1.1vw;
		font-weight: 600;
	}

	.secondary-subheading {
		font-size: 0.9vw;
		line-height: 1.5vw;
		color: rgba(120, 120, 120);
	}

	.main-area {
		padding: 1vw 2vw;
	}

	.form-area {
		padding-bottom: 2vw;
		margin-bottom: 2vw;
		padding-top: 1vw;
		border-bottom: 0.1vw solid rgba(230, 230, 230);
	}

	.primary-button-settings {
		margin-top: 1vw;
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(255, 255, 255);
		border: 0.14vw solid rgba(2, 96, 105);
		background: rgba(2, 96, 105);
	}

	.primary-outline-button-settings {
		margin-top: 1vw;
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(100, 100, 100);
		border: 0.14vw solid rgba(200, 200, 200);
		background: rgba(255, 255, 255);
	}

	.input-forms-settings {
		padding: 0.55vw 0.8vw;
		border-radius: 0.3vw;
		outline: none;
		font-size: 0.8vw;
		border: 0.11vw solid rgba(200, 200, 200);
	}

	.input-forms-settings:focus {
		border: 0.11vw solid rgba(2, 96, 105);
	}

	table {
		width: 100%;
		border: 0.1vw solid #ddd;
		border-collapse: separate;
		border-spacing: 0;
		border-radius: 1vw;
		overflow: hidden;
	}

	th, td {
		padding: 1.2vw;
		text-align: left;
		border-top: 0.1vw solid rgba(240, 240, 240);
		border-radius: 0;
	}

	thead {
		background-color: rgba(150, 150, 150);
	}

	th {
		background-color: rgba(240, 240, 240);
		color: rgba(50, 50, 50);
		font-size: 1vw;
		font-weight: 500;
	}

	table .member-name {
		padding-top: 1vw;
		font-size: 1.1vw;
	}

	table .member-email {
		font-size: 1vw;
		color: rgba(125, 125, 125);
	}

	table td {
		font-size: 1vw;
	}

	table .member-icon {
		width: 4.2vw;
		height: 4.2vw;
		border-radius: 50vw;
		border: 0.1vw solid rgba(240, 240, 240);
		object-fit: cover;
	}

	table td .bi {
		font-size: 1.5vw;
		margin: 0 0.5vw;
		color: rgba(100, 100, 100);
	}

	.notification-radio-area {
		margin-bottom: 2vw;
		margin-left: 4vw;
	}

	.main-usage-area {
		padding: 2vw;
		padding-top: 0;
		border-radius: 1vw;
		border: 0.1vw solid rgba(240, 240, 240);
	}
	/* Content truncated for length */
	.paid-status{
		font-size:0.8vw;
		padding:0.5vw 1vw;
		border-radius:50vw;
		font-weight:600;
		background:rgba(233, 247, 239);
		color:rgba(30, 132, 73);
		border: 0.1vw solid rgba(30, 132, 73);
	}.failed-status{
		font-size:0.8vw;
		padding:0.5vw 1vw;
		border-radius:50vw;
		font-weight:600;
		background:rgba(253, 237, 236);
		color:rgba(231, 76, 60);
		border: 0.1vw solid rgba(231, 76, 60);
	}.pending-status{
		font-size:0.8vw;
		padding:0.5vw 1vw;
		border-radius:50vw;
		font-weight:600;
		background:rgba(254, 245, 231);
		color:rgba(243, 156, 18);
		border: 0.1vw solid rgba(243, 156, 18);
	}.transaction-date{
		font-size: 0.9vw;
	}.transaction-id{
		font-size: 1vw;
	}.invoice-id{
		font-size: 0.9vw;
	}.transaction-amount{
		font-size: 0.9vw;
	}.transaction-invoice-id{
		font-size: 0.9vw;
	}.transaction-method{
		font-size: 0.9vw;
	}.error-message{
		font-size: 0.8vw;
	}.primary-label-my-plan{
		font-size: 14px;
		color:rgba(150, 150, 150);
	}.current-plan-label-my-plan{
		font-size: 18px;
		color:rgba(0,0,0);
	}.plan-usage-label-my-plan{
		font-size: 1vw;
	}

}.copy-button {
background: none;
border: none;
color: #007bff;
cursor: pointer;
text-decoration: underline;
}
.copy-button:hover {
text-decoration: none;
}