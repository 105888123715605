.pricing-body{
	font-family: 'Poppins', sans-serif;
	padding:3vw 4vw;
.header-area{
	padding:1vw 6vw;
}a{
	color:rgba(2, 96, 105);
}.main-heading{
	text-align:center;
	font-size:2vw;
	font-weight:600;
	line-height:4vw;
}.main-subheading{
	text-align:center;
	font-size:1vw;
	color:rgba(90, 90, 90);
}.plan-category-area{
	padding:1vw 2vw;
}.plan-category{
	padding:1vw;
	cursor:pointer;
	border-radius:0.4vw;
	border:0.2vw solid rgba(240, 240, 240);
}.plan-category-icon{
	font-size:2vw;
	text-align:center;
	padding:0.5vw;
	box-shadow: 0 0 0.2vw rgba(0,0,0,0.1);
	border-radius:0.4vw;
	background:rgba(255,255,255);
	border: 0.2vw solid rgba(240, 240, 240);
}.active .plan-category  .plan-category-icon{
	box-shadow: 0 0 0.2vw rgba(2,96,105,0.9);
	border-radius:0.4vw;
	color:rgba(255,255,255);
	background:rgba(2,96,105);
	border: 0.2vw solid rgba(2, 96, 105);
}.active .plan-category{
	border: 0.2vw solid rgba(2, 96, 105);
	background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 70%, rgba(240, 240, 240) 100%);
}.plan-category-heading{
	font-size:1vw;
	padding-top:0.5vw;
	font-weight:600;
}.plan-category-subheading{
	font-size:0.8vw;
	font-weight:500;
	line-height: 1.2vw;
	color:rgba(120, 120, 120);
}.plans-column-area{
	padding:1vw 2vw;
}.filters-area{
	padding:2vw 2vw;
}.plan-column{
	padding:1vw;
	background:rgba(255,255, 255);
	display: flex;
	flex-direction: column;
	border-radius:0.4vw;
	border: 0.2vw solid rgba(240, 240, 240);
}.plan-column-heading{
	font-size:1.2vw;
	font-weight:600;
	display:block;
}.plan-column-description{
	font-size:15px;
	line-height:1.5vw;
	font-weight:500;
	color:rgba(125, 125, 125);
}.credits-area{
	margin:1vw 0;
	border-top:0.2vw solid rgba(235, 235, 235);
	border-bottom:0.2vw dashed rgba(200, 200, 200);
	padding: 1vw 0;
}.credits-area .left h6{
	color:rgba(125, 125, 125);
	font-weight:400;
	font-size:15px;
	line-height:1.5vw;
	text-align:left;
}.credits-area .right h6{
	text-align:right;
	color:rgba(2,96,105);
	font-weight:600;
	line-height:1.5vw;
	font-size:15px;
}.feature-list{
	font-size:14px;
	display:block;
	line-height:32px;
}.feature-list .bi{
	margin-right:0.5vw;
}.bi-check-circle-fill{
	color:rgba(2,96,105);
}.primary-button {
  width: 100%;
  margin-top: 1vw;
  border-radius: 0.4vw;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.5vw 1.2vw;
  color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(2, 96, 105);
  background: rgba(2, 96, 105);
}
.primary-outline-button {
  width: 100%;
  margin-top: 1vw;
  border-radius: 0.4vw;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.5vw 1.2vw;
  color: rgba(2, 96, 105);
  border: 0.2vw solid rgba(2, 96, 105);
  background: rgba(255, 255, 255);
}.plan-column .button-area {
	margin-top: auto; /* Pushes the button container to the bottom */
}.plan-column .button-area button{
	margin-top:1vw;
}.popular .plan-column{
	background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 40%, rgba(240, 240, 240) 100%);
}.popular button{
  width: 100%;
  margin-top: 1vw;
  border-radius: 0.4vw;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.5vw 1.2vw;
  color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(2, 96, 105);
  background: rgba(2, 96, 105);
}.plan-column-heading span{
  color:rgba(255, 255, 255);
  font-size:1vw;
  border-radius:10vw;
  float:right;
  padding:8px 14px;
  background-color:rgba(2, 96, 105);
}.whatsapp-label{
  color:rgba(255, 255, 255);
  font-size:0.8vw;
  border-radius:10vw;
  float:right;
  padding:0.3vw 0.6vw;
  background-color:rgba(243, 156, 18);
}.product-type-tab{
	background:rgba(240, 240, 240);
	display:block;
	border-radius:0.5vw;
	padding:0.4vw;
}.product-type-tab a{
	text-align:center;
	display:block;
	color:rgba(10, 10, 10);
	padding:0.5vw;
	font-size:1vw;
	text-decoration:none;
	cursor:pointer;
}.product-type-tab .active{
	color:rgba(255, 255, 255);
	background-color:rgba(2, 96, 105);
	padding:0.5vw;
	border-radius:0.5vw;
}.product-type-tab a:hover{
	opacity:0.5;
}.product-type-tab .active:hover{
	opacity:1;
}.currency-select {
  background-color: rgba(255,255,255);
  border-radius: 0.35vw;
  width:100%;
}

.currency-select .MuiSelect-select {
  padding: 0.9vw;
  display: flex;
  border:none;
  align-items: center;
}.slider-container {
	width: 100%;
	text-align: center;
}

.labels {
	display: flex;
	justify-content: space-between;
	margin-top: 0.25vw;
	margin-top:0.5vw;
	font-size:1vw;
}

.current-value {
	font-weight: 500;
	color: rgba(100,100,100);
}

.max-value {
	font-weight: 500;
	color: rgba(100,100,100);
}

.range-slider {
	width: 100%;
	-webkit-appearance: none;
	appearance: none;
	height: 0.6vw;
	border-radius:0.2vw;
	background: linear-gradient(to right, rgba(2, 96, 105) 0%, rgba(2, 96, 105) var(--value), #ddd var(--value), #ddd 100%);
	outline: none;
	opacity: 0.8;
	transition: opacity 0.2s, background 0.2s;
}

.range-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1.5vw;
	height: 1.5vw;
	padding:0.5vw;
	background: rgba(255, 255,255);
	border:0.4vw solid rgba(2, 96, 105);
	cursor: pointer;
	border-radius: 0.5vw;
}.feature-comparision-area{
	padding:2vw 2vw;
	margin:2vw 0vw;
	border-radius:1vw;
}.feature-comparision-area .heading{
	text-align:center;
	color:rgba(2, 96, 105);
	font-size:1.5vw;
	line-height:3vw;
	font-weight:500;
}.feature-comparision-area .description{
	text-align:center;
	font-size:0.8vw;
	font-weight:500;
	color:rgba(50,50,50);
}.table-container {
  width: 100%;
  overflow-x: auto;
  margin:2.5vw 0;
  margin-bottom:0vw;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 0.2vw solid rgba(249, 249, 249);
  border-radius:5vw;
}

th, td {
  padding: 0.8vw;
  text-align: left;
}

th:first-child, td:first-child {
  width: 40%; /* Adjust this value as needed */
}

th, td:not(:first-child) {
  width: 15%; /* Adjust this value as needed */
}

/* Alternate row colors */
tbody tr:nth-child(odd) {
  background-color: rgba(250, 250, 250); /* Light grey color */
}
tbody tr:nth-child(even) {
  background-color: #ffffff; /* White color */
}.feature-heading{
  font-size:1.2vw;
  font-weight:500;
}td .bi-check-circle-fill{
	color: rgba(39, 174, 96);
	font-size:1.2vw;
	margin-left:2vw;
}td .bi-x-circle-fill{
	color: rgba(231, 76, 60);
	font-size:1.2vw;
	margin-left:2vw;
}.faqss-area{
	padding:1vw 2vw;
}.faqss-area .heading{
	text-align:center;
	color:rgba(2, 96, 105);
	font-size:2vw;
	line-height:3vw;
	font-weight:500;
}.faqss-area .description{
	text-align:center;
	font-size:1.1vw;
	font-weight:500;
	color:rgba(50,50,50);
}.accordion {
  max-width: 50vw;
  margin: 2vw auto;
}

.faq-item {
  border-bottom: 0.1vw solid rgba(240, 240, 240);
  padding: 0.8vw 0;
}

.faq-question {
  cursor: pointer;
  padding: 1vw;
  border-radius:0.5vw;
  background-color: rgba(245, 245, 245);
  font-weight: 600;
  display: flex;
  font-size:1vw;
  justify-content: space-between;
  align-items: center;
}

.faq-question.active {
  background-color: rgba(235, 235, 235);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 1vw;
  font-size:1vw;
  font-weight:500;
}

.faq-answer.open {
  max-height: 20vw;
  padding: 1.5vw 1vw;
  margin-top: 1vw;
  border-radius: 0.5vw;
  background-color: #fefefe;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.rotate {
  transform: rotate(180deg);
}.whatsapp-plans-body{
	margin-bottom:10vw;
}.whatsapp-plans-body img{
	width:100%;
	height:25vw;
	display:block;
	object-fit:contain;
}.whatsapp-plans-body .heading{
	text-align:center;
	font-size:2.5vw;
	font-weight:600;
	line-height:4vw;
}.whatsapp-plans-body .subheading{
	text-align:center;
	font-size:1.2vw;
	color:rgba(90, 90, 90);
}

.range-slider::-moz-range-thumb {
	width: 1vw;
	height: 1vw;
	background: rgba(255, 255,255);
	cursor: pointer;
	border-radius: 0.5vw;
}.custom-select {
font-size: 128px; /* Change this to your desired font size */
}
}.custom-typography {
font-size: 25px; /* Change this to your desired font size */
}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.pricing-body{
		font-family: 'Poppins', sans-serif;
		padding:3vw 4vw;
		a{
		color:rgba(2, 96, 105);
	}a{
		color:rgba(2, 96, 105);
	}
	.header-area{
		padding:3vw 0vw;
	}.main-heading{
		text-align:center;
		font-size:4.5vw;
		font-weight:600;
		line-height:6vw;
	}.main-subheading{
		text-align:center;
		font-size:2.5vw;
		line-height:3.5vw;
		color:rgba(90, 90, 90);
	}.plan-category{
		padding:2vw;
		cursor:pointer;
		border-radius:1vw;
		margin:1vw 0;
		border:0.8vw solid rgba(240, 240, 240);
	}.plan-category-icon{
		font-size:6vw;
		text-align:center;
		padding:3vw 0.5vw;
		box-shadow: 0 0 0.2vw rgba(0,0,0,0.1);
		border-radius:1vw;
		background:rgba(255,255,255);
		border: 0.8vw solid rgba(240, 240, 240);
	}.active .plan-category  .plan-category-icon{
		box-shadow: 0 0 0.2vw rgba(2,96,105,0.9);
		border-radius:0.4vw;
		color:rgba(255,255,255);
		background:rgba(2,96,105);
		border: 0.8vw solid rgba(2, 96, 105);
	}.active .plan-category{
		border: 0.8vw solid rgba(2, 96, 105);
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 70%, rgba(240, 240, 240) 100%);
	}.plan-category-heading{
		font-size:4vw;
		padding-top:3vw;
		font-weight:600;
	}.plan-category-subheading{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(120, 120, 120);
	}.plan-category-area , .plans-column-area {
	  overflow-x: auto; /* Enable horizontal scrolling */
	  white-space: normal; /* Prevent wrapping of child elements */
	  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
	}

	.plan-category-area .horizontalrow, .plans-column-area .horizontalrow {
	  display: flex; /* Use flexbox for horizontal layout */
	  flex-wrap: nowrap; /* Prevent wrapping */
	}

	.plan-category-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 88vw; /* Set a specific width for each column */
	}.plans-column-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 75vw; /* Set a specific width for each column */
	}
.plans-column-area{
		padding:1vw 0vw;
		margin:10vw 0vw;
	}.filters-area{
		padding:2vw 0vw;
		margin-top:4vw;
	}.plan-column{
		padding:2.5vw;
		background:rgba(255,255, 255);
		display: flex;
		flex-direction: column;
		border-radius:1vw;
		border: 0.5vw solid rgba(240, 240, 240);
	}.plan-column-heading{
		font-size:4vw;
		font-weight:600;
		display:block;
	}.plan-column-description{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(125, 125, 125);
	}.credits-area{
		margin:2vw 0;
		border-top:0.2vw solid rgba(235, 235, 235);
		border-bottom:0.2vw dashed rgba(200, 200, 200);
		padding: 2.5vw 0;
	}.credits-area .left h6{
		color:rgba(125, 125, 125);
		font-weight:400;
		font-size:3.5vw;
		line-height:4.5vw;
		text-align:left;
	}.credits-area .right h6{
		text-align:right;
		color:rgba(2,96,105);
		font-weight:600;
		line-height:4.5vw;
		font-size:3.5vw;
	}.feature-list{
		font-size:3.5vw;
		display:block;
		line-height:4.5vw;
	}.feature-list .bi{
		margin-right:1.5vw;
	}.bi-check-circle-fill{
		color:rgba(2,96,105);
	}
    .primary-outline-button {
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }.plan-column .button-area {
		margin-top: auto; /* Pushes the button container to the bottom */
	}.plan-column .button-area button{
		margin-top:1vw;
	}.popular .plan-column{
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 40%, rgba(240, 240, 240) 100%);
	}.popular button, .primary-button{
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(255, 255, 255);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(2, 96, 105);
	}.plan-column-heading span{
	  color:rgba(255, 255, 255);
	  font-size:3vw;
	  border-radius:10vw;
	  float:right;
	  padding:1vw 1.5vw;
      background-color:rgba(2, 96, 105);
	}.whatsapp-label{
	  color:rgba(255, 255, 255);
	  font-size:0.8vw;
	  border-radius:10vw;
	  float:right;
	  padding:0.3vw 0.6vw;
      background-color:rgba(243, 156, 18);
	}.product-type-tab{
		background:rgba(240, 240, 240);
		display:block;
		border-radius:1vw;
		padding:1.5vw;
	}.product-type-tab a{
		text-align:center;
		display:block;
		color:rgba(10, 10, 10);
		padding:1.2vw;
		font-size:4.2vw;
		text-decoration:none;
		cursor:pointer;
	}.product-type-tab .active{
		color:rgba(255, 255, 255);
		background-color:rgba(2, 96, 105);
		padding:1.2vw;
		border-radius:1vw;
	}.product-type-tab a:hover{
		opacity:0.5;
	}.product-type-tab .active:hover{
		opacity:1;
	}.currency-select {
	  background-color: rgba(255,255,255);
	  border-radius: 1vw;
	  width:100%;
	}

	.currency-select .MuiSelect-select {
	  padding: 2vw;
	  display: flex;
	  border:none;
	  align-items: center;
	}.slider-container {
		width: 100%;
		text-align: center;
		margin-bottom:4vw;
	}

	.labels {
		display: flex;
		justify-content: space-between;
		margin-top: 2vw;
		font-size:1.1vw;
	}

	.current-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.max-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.range-slider {
		width: 100%;
		-webkit-appearance: none;
		appearance: none;
		height: 2.5vw;
		border-radius:1vw;
		background: linear-gradient(to right, rgba(2, 96, 105) 0%, rgba(2, 96, 105) var(--value), #ddd var(--value), #ddd 100%);
		outline: none;
		opacity: 0.8;
		transition: opacity 0.2s, background 0.2s;
	}

	.range-slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 5vw;
		height: 5vw;
		padding:0.5vw;
		background: rgba(255, 255,255);
		border:1vw solid rgba(2, 96, 105);
		cursor: pointer;
		border-radius: 1vw;
	}.feature-comparision-area{
		padding:2vw 0vw;
		margin:5vw 0vw;
		border-radius:1vw;
	}.feature-comparision-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:3vw;
		font-weight:500;
	}.feature-comparision-area .description{
		text-align:center;
		font-size:2.5vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(50,50,50);
	}.table-container {
	  width: 100%;
	  overflow-x: auto;
	  margin:2.5vw 0;
	  margin-bottom:0vw;
	}

	table {
	  width: 100%;
	  border-collapse: collapse;
	  border: 1vw solid rgba(240, 240, 240);
	  border-radius:5vw;
	}

	th, td {
	  padding: 1.5vw;
	  font-size:2.5vw;
	  text-align: left;
	}

	th:first-child, td:first-child {
	  width: 30%; /* Adjust this value as needed */
	  font-size:2.5vw;
	}

	th, td:not(:first-child) {
	  width: 17.5%; /* Adjust this value as needed */
	}

	/* Alternate row colors */
	tbody tr:nth-child(odd) {
	  background-color: rgba(250, 250, 250); /* Light grey color */
	}
	tbody tr:nth-child(even) {
	  background-color: #ffffff; /* White color */
	}.feature-heading{
	  font-size:2.2vw;
	  font-weight:500;
	}td .bi-check-circle-fill{
		color: rgba(39, 174, 96);
		font-size:3vw;
		margin-left:2vw;
	}td .bi-x-circle-fill{
		color: rgba(231, 76, 60);
		font-size:3vw;
		margin-left:2vw;
	}.faqss-area{
		padding:1vw 0vw;
	}.faqss-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:5vw;
		font-weight:500;
	}.faqss-area .description{
		text-align:center;
		font-size:3vw;
		font-weight:500;
		line-height:4vw;
		color:rgba(50,50,50);
	}.accordion {
	  max-width: 100%;
	  margin: 4vw auto;
	}

	.faq-item {
	  border-bottom: 0.5vw solid rgba(240, 240, 240);
	  padding: 0.8vw 0;
	  margin:2.5vw 0;
	}

	.faq-question {
	  cursor: pointer;
	  padding: 2vw;
	  border-radius:1vw;
	  background-color: rgba(245, 245, 245);
	  font-weight: 500;
	  display: flex;
	  font-size:3.5vw;
	  justify-content: space-between;
	  align-items: center;
	}

	.faq-question.active {
	  background-color: rgba(240, 240, 240);
	}

	.faq-answer {
	  max-height: 0;
	  overflow: hidden;
	  transition: max-height 0.3s ease;
	  padding: 0.5vw 1vw;
	  font-size:3vw;
	  font-weight:500;
	}

	.faq-answer.open {
	  max-height: 50vw;
	  padding: 2.5vw 1vw;
	}

	.arrow {
	  transition: transform 0.3s ease;
	}

	.arrow.rotate {
	  transform: rotate(180deg);
	}.whatsapp-plans-body{
		margin-bottom:10vw;
	}.whatsapp-plans-body img{
		width:100%;
		height:75vw;
		display:block;
		object-fit:contain;
	}.whatsapp-plans-body .heading{
		text-align:center;
		font-size:5vw;
		font-weight:600;
		line-height:4vw;
	}.whatsapp-plans-body .subheading{
		text-align:center;
		font-size:3.5vw;
		line-height:4.5vw;
		font-weight:400;
		color:rgba(90, 90, 90);
	}

	.range-slider::-moz-range-thumb {
		width: 1vw;
		height: 1vw;
		background: rgba(255, 255,255);
		cursor: pointer;
		border-radius: 0.5vw;
	}
	}
}.primary-outline-button:disabled{
	cursor: not-allowed;
	opacity: 0.5;
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.pricing-body{
		font-family: 'Poppins', sans-serif;
		padding:3vw 4vw;
	.header-area{
		padding:3vw 0vw;
	}a{
		color:rgba(2, 96, 105);
	}.main-heading{
		text-align:center;
		font-size:4.5vw;
		font-weight:600;
		line-height:6vw;
	}.main-subheading{
		text-align:center;
		font-size:2.5vw;
		line-height:3.5vw;
		color:rgba(90, 90, 90);
	}.plan-category{
		padding:2vw;
		cursor:pointer;
		border-radius:1vw;
		margin:1vw 0;
		border:0.8vw solid rgba(240, 240, 240);
	}.plan-category-icon{
		font-size:6vw;
		text-align:center;
		padding:3vw 0.5vw;
		box-shadow: 0 0 0.2vw rgba(0,0,0,0.1);
		border-radius:1vw;
		background:rgba(255,255,255);
		border: 0.8vw solid rgba(240, 240, 240);
	}.active .plan-category  .plan-category-icon{
		box-shadow: 0 0 0.2vw rgba(2,96,105,0.9);
		border-radius:0.4vw;
		color:rgba(255,255,255);
		background:rgba(2,96,105);
		border: 0.8vw solid rgba(2, 96, 105);
	}.active .plan-category{
		border: 0.8vw solid rgba(2, 96, 105);
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 70%, rgba(240, 240, 240) 100%);
	}.plan-category-heading{
		font-size:4vw;
		padding-top:3vw;
		font-weight:600;
	}.plan-category-subheading{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(120, 120, 120);
	}.plan-category-area , .plans-column-area {
	  overflow-x: auto; /* Enable horizontal scrolling */
	  white-space: normal; /* Prevent wrapping of child elements */
	  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
	}

	.plan-category-area .horizontalrow, .plans-column-area .horizontalrow {
	  display: flex; /* Use flexbox for horizontal layout */
	  flex-wrap: nowrap; /* Prevent wrapping */
	}

	.plan-category-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 88vw; /* Set a specific width for each column */
	}.plans-column-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 75vw; /* Set a specific width for each column */
	}
.plans-column-area{
		padding:1vw 0vw;
		margin:10vw 0vw;
	}.filters-area{
		padding:2vw 0vw;
		margin-top:4vw;
	}.plan-column{
		padding:2.5vw;
		background:rgba(255,255, 255);
		display: flex;
		flex-direction: column;
		border-radius:1vw;
		border: 0.5vw solid rgba(240, 240, 240);
	}.plan-column-heading{
		font-size:4vw;
		font-weight:600;
		display:block;
	}.plan-column-description{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(125, 125, 125);
	}.credits-area{
		margin:2vw 0;
		border-top:0.2vw solid rgba(235, 235, 235);
		border-bottom:0.2vw dashed rgba(200, 200, 200);
		padding: 2.5vw 0;
	}.credits-area .left h6{
		color:rgba(125, 125, 125);
		font-weight:400;
		font-size:3.5vw;
		line-height:4.5vw;
		text-align:left;
	}.credits-area .right h6{
		text-align:right;
		color:rgba(2,96,105);
		font-weight:600;
		line-height:4.5vw;
		font-size:3.5vw;
	}.feature-list{
		font-size:3.5vw;
		display:block;
		line-height:4.5vw;
	}.feature-list .bi{
		margin-right:1.5vw;
	}.bi-check-circle-fill{
		color:rgba(2,96,105);
	}
    .primary-outline-button {
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }.plan-column .button-area {
		margin-top: auto; /* Pushes the button container to the bottom */
	}.plan-column .button-area button{
		margin-top:1vw;
	}.popular .plan-column{
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 40%, rgba(240, 240, 240) 100%);
	}.popular button, .primary-button{
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(255, 255, 255);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(2, 96, 105);
	}.plan-column-heading span{
	  color:rgba(255, 255, 255);
	  font-size:3vw;
	  border-radius:10vw;
	  float:right;
	  padding:1vw 1.5vw;
      background-color:rgba(2, 96, 105);
	}.whatsapp-label{
	  color:rgba(255, 255, 255);
	  font-size:0.8vw;
	  border-radius:10vw;
	  float:right;
	  padding:0.3vw 0.6vw;
      background-color:rgba(243, 156, 18);
	}.product-type-tab{
		background:rgba(240, 240, 240);
		display:block;
		border-radius:1vw;
		padding:1.5vw;
	}.product-type-tab a{
		text-align:center;
		display:block;
		color:rgba(10, 10, 10);
		padding:1.2vw;
		font-size:4.2vw;
		text-decoration:none;
		cursor:pointer;
	}.product-type-tab .active{
		color:rgba(255, 255, 255);
		background-color:rgba(2, 96, 105);
		padding:1.2vw;
		border-radius:1vw;
	}.product-type-tab a:hover{
		opacity:0.5;
	}.product-type-tab .active:hover{
		opacity:1;
	}.currency-select {
	  background-color: rgba(255,255,255);
	  border-radius: 1vw;
	  width:100%;
	}

	.currency-select .MuiSelect-select {
	  padding: 2vw;
	  display: flex;
	  border:none;
	  align-items: center;
	}.slider-container {
		width: 100%;
		text-align: center;
		margin-bottom:4vw;
	}

	.labels {
		display: flex;
		justify-content: space-between;
		margin-top: 2vw;
		font-size:1.1vw;
	}

	.current-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.max-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.range-slider {
		width: 100%;
		-webkit-appearance: none;
		appearance: none;
		height: 2.5vw;
		border-radius:1vw;
		background: linear-gradient(to right, rgba(2, 96, 105) 0%, rgba(2, 96, 105) var(--value), #ddd var(--value), #ddd 100%);
		outline: none;
		opacity: 0.8;
		transition: opacity 0.2s, background 0.2s;
	}

	.range-slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 5vw;
		height: 5vw;
		padding:0.5vw;
		background: rgba(255, 255,255);
		border:1vw solid rgba(2, 96, 105);
		cursor: pointer;
		border-radius: 1vw;
	}.feature-comparision-area{
		padding:2vw 0vw;
		margin:5vw 0vw;
		border-radius:1vw;
	}.feature-comparision-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:3vw;
		font-weight:500;
	}.feature-comparision-area .description{
		text-align:center;
		font-size:2.5vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(50,50,50);
	}.table-container {
	  width: 100%;
	  overflow-x: auto;
	  margin:2.5vw 0;
	  margin-bottom:0vw;
	}

	table {
	  width: 100%;
	  border-collapse: collapse;
	  border: 1vw solid rgba(240, 240, 240);
	  border-radius:5vw;
	}

	th, td {
	  padding: 1.5vw;
	  font-size:2.5vw;
	  text-align: left;
	}

	th:first-child, td:first-child {
	  width: 30%; /* Adjust this value as needed */
	  font-size:2.5vw;
	}

	th, td:not(:first-child) {
	  width: 17.5%; /* Adjust this value as needed */
	}

	/* Alternate row colors */
	tbody tr:nth-child(odd) {
	  background-color: rgba(250, 250, 250); /* Light grey color */
	}
	tbody tr:nth-child(even) {
	  background-color: #ffffff; /* White color */
	}.feature-heading{
	  font-size:2.2vw;
	  font-weight:500;
	}td .bi-check-circle-fill{
		color: rgba(39, 174, 96);
		font-size:3vw;
		margin-left:2vw;
	}td .bi-x-circle-fill{
		color: rgba(231, 76, 60);
		font-size:3vw;
		margin-left:2vw;
	}.faqss-area{
		padding:1vw 0vw;
	}.faqss-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:5vw;
		font-weight:500;
	}.faqss-area .description{
		text-align:center;
		font-size:3vw;
		font-weight:500;
		line-height:4vw;
		color:rgba(50,50,50);
	}.accordion {
	  max-width: 100%;
	  margin: 4vw auto;
	}

	.faq-item {
	  border-bottom: 0.5vw solid rgba(240, 240, 240);
	  padding: 0.8vw 0;
	  margin:2.5vw 0;
	}

	.faq-question {
	  cursor: pointer;
	  padding: 2vw;
	  border-radius:1vw;
	  background-color: rgba(245, 245, 245);
	  font-weight: 500;
	  display: flex;
	  font-size:3.5vw;
	  justify-content: space-between;
	  align-items: center;
	}

	.faq-question.active {
	  background-color: rgba(240, 240, 240);
	}

	.faq-answer {
	  max-height: 0;
	  overflow: hidden;
	  transition: max-height 0.3s ease;
	  padding: 0.5vw 1vw;
	  font-size:3vw;
	  font-weight:500;
	}

	.faq-answer.open {
	  max-height: 50vw;
	  padding: 2.5vw 1vw;
	}

	.arrow {
	  transition: transform 0.3s ease;
	}

	.arrow.rotate {
	  transform: rotate(180deg);
	}.whatsapp-plans-body{
		margin-bottom:10vw;
	}.whatsapp-plans-body img{
		width:100%;
		height:75vw;
		display:block;
		object-fit:contain;
	}.whatsapp-plans-body .heading{
		text-align:center;
		font-size:5vw;
		font-weight:600;
		line-height:4vw;
	}.whatsapp-plans-body .subheading{
		text-align:center;
		font-size:3.5vw;
		line-height:4.5vw;
		font-weight:400;
		color:rgba(90, 90, 90);
	}

	.range-slider::-moz-range-thumb {
		width: 1vw;
		height: 1vw;
		background: rgba(255, 255,255);
		cursor: pointer;
		border-radius: 0.5vw;
	}
	}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.pricing-body{
		font-family: 'Poppins', sans-serif;
		padding:3vw 4vw;
	.header-area{
		padding:3vw 0vw;
	}a{
		color:rgba(2, 96, 105);
	}.main-heading{
		text-align:center;
		font-size:4.5vw;
		font-weight:600;
		line-height:6vw;
	}.main-subheading{
		text-align:center;
		font-size:2.5vw;
		line-height:3.5vw;
		color:rgba(90, 90, 90);
	}.plan-category{
		padding:2vw;
		cursor:pointer;
		border-radius:1vw;
		margin:1vw 0;
		border:0.8vw solid rgba(240, 240, 240);
	}.plan-category-icon{
		font-size:6vw;
		text-align:center;
		padding:3vw 0.5vw;
		box-shadow: 0 0 0.2vw rgba(0,0,0,0.1);
		border-radius:1vw;
		background:rgba(255,255,255);
		border: 0.8vw solid rgba(240, 240, 240);
	}.active .plan-category  .plan-category-icon{
		box-shadow: 0 0 0.2vw rgba(2,96,105,0.9);
		border-radius:0.4vw;
		color:rgba(255,255,255);
		background:rgba(2,96,105);
		border: 0.8vw solid rgba(2, 96, 105);
	}.active .plan-category{
		border: 0.8vw solid rgba(2, 96, 105);
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 70%, rgba(240, 240, 240) 100%);
	}.plan-category-heading{
		font-size:4vw;
		padding-top:3vw;
		font-weight:600;
	}.plan-category-subheading{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(120, 120, 120);
	}.plan-category-area , .plans-column-area {
	  overflow-x: auto; /* Enable horizontal scrolling */
	  white-space: normal; /* Prevent wrapping of child elements */
	  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
	}

	.plan-category-area .horizontalrow, .plans-column-area .horizontalrow {
	  display: flex; /* Use flexbox for horizontal layout */
	  flex-wrap: nowrap; /* Prevent wrapping */
	}

	.plan-category-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 88vw; /* Set a specific width for each column */
	}.plans-column-area .horizontalrow > div {
	  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
	  width: 75vw; /* Set a specific width for each column */
	}
.plans-column-area{
		padding:1vw 0vw;
		margin:10vw 0vw;
	}.filters-area{
		padding:2vw 0vw;
		margin-top:4vw;
	}.plan-column{
		padding:2.5vw;
		background:rgba(255,255, 255);
		display: flex;
		flex-direction: column;
		border-radius:1vw;
		border: 0.5vw solid rgba(240, 240, 240);
	}.plan-column-heading{
		font-size:4vw;
		font-weight:600;
		display:block;
	}.plan-column-description{
		font-size:3vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(125, 125, 125);
	}.credits-area{
		margin:2vw 0;
		border-top:0.2vw solid rgba(235, 235, 235);
		border-bottom:0.2vw dashed rgba(200, 200, 200);
		padding: 2.5vw 0;
	}.credits-area .left h6{
		color:rgba(125, 125, 125);
		font-weight:400;
		font-size:3.5vw;
		line-height:4.5vw;
		text-align:left;
	}.credits-area .right h6{
		text-align:right;
		color:rgba(2,96,105);
		font-weight:600;
		line-height:4.5vw;
		font-size:3.5vw;
	}.feature-list{
		font-size:3.5vw;
		display:block;
		line-height:4.5vw;
	}.feature-list .bi{
		margin-right:1.5vw;
	}.bi-check-circle-fill{
		color:rgba(2,96,105);
	}
    .primary-outline-button {
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }.plan-column .button-area {
		margin-top: auto; /* Pushes the button container to the bottom */
	}.plan-column .button-area button{
		margin-top:1vw;
	}.popular .plan-column{
		background: linear-gradient(to bottom, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 40%, rgba(240, 240, 240) 100%);
	}.popular button, .primary-button{
      width: 100%;
      margin-top: 1vw;
      border-radius: 1vw;
      font-size: 4vw;
      font-weight: 500;
      padding: 1vw 1.2vw;
      color: rgba(255, 255, 255);
      border: 0.2vw solid rgba(2, 96, 105);
      background: rgba(2, 96, 105);
	}.plan-column-heading span{
	  color:rgba(255, 255, 255);
	  font-size:3vw;
	  border-radius:10vw;
	  float:right;
	  padding:1vw 1.5vw;
      background-color:rgba(2, 96, 105);
	}.whatsapp-label{
	  color:rgba(255, 255, 255);
	  font-size:0.8vw;
	  border-radius:10vw;
	  float:right;
	  padding:0.3vw 0.6vw;
      background-color:rgba(243, 156, 18);
	}.product-type-tab{
		background:rgba(240, 240, 240);
		display:block;
		border-radius:1vw;
		padding:1.5vw;
	}.product-type-tab a{
		text-align:center;
		display:block;
		color:rgba(10, 10, 10);
		padding:1.2vw;
		font-size:4.2vw;
		text-decoration:none;
		cursor:pointer;
	}.product-type-tab .active{
		color:rgba(255, 255, 255);
		background-color:rgba(2, 96, 105);
		padding:1.2vw;
		border-radius:1vw;
	}.product-type-tab a:hover{
		opacity:0.5;
	}.product-type-tab .active:hover{
		opacity:1;
	}.currency-select {
	  background-color: rgba(255,255,255);
	  border-radius: 1vw;
	  width:100%;
	}

	.currency-select .MuiSelect-select {
	  padding: 2vw;
	  display: flex;
	  border:none;
	  align-items: center;
	}.slider-container {
		width: 100%;
		text-align: center;
		margin-bottom:4vw;
	}

	.labels {
		display: flex;
		justify-content: space-between;
		margin-top: 2vw;
		font-size:1.1vw;
	}

	.current-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.max-value {
		font-weight: 500;
		font-size:4vw;
		color: rgba(100,100,100);
	}

	.range-slider {
		width: 100%;
		-webkit-appearance: none;
		appearance: none;
		height: 2.5vw;
		border-radius:1vw;
		background: linear-gradient(to right, rgba(2, 96, 105) 0%, rgba(2, 96, 105) var(--value), #ddd var(--value), #ddd 100%);
		outline: none;
		opacity: 0.8;
		transition: opacity 0.2s, background 0.2s;
	}

	.range-slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 5vw;
		height: 5vw;
		padding:0.5vw;
		background: rgba(255, 255,255);
		border:1vw solid rgba(2, 96, 105);
		cursor: pointer;
		border-radius: 1vw;
	}.feature-comparision-area{
		padding:2vw 0vw;
		margin:5vw 0vw;
		border-radius:1vw;
	}.feature-comparision-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:3vw;
		font-weight:500;
	}.feature-comparision-area .description{
		text-align:center;
		font-size:2.5vw;
		line-height:4vw;
		font-weight:400;
		color:rgba(50,50,50);
	}.table-container {
	  width: 100%;
	  overflow-x: auto;
	  margin:2.5vw 0;
	  margin-bottom:0vw;
	}

	table {
	  width: 100%;
	  border-collapse: collapse;
	  border: 1vw solid rgba(240, 240, 240);
	  border-radius:5vw;
	}

	th, td {
	  padding: 1.5vw;
	  font-size:2.5vw;
	  text-align: left;
	}

	th:first-child, td:first-child {
	  width: 30%; /* Adjust this value as needed */
	  font-size:2.5vw;
	}

	th, td:not(:first-child) {
	  width: 17.5%; /* Adjust this value as needed */
	}

	/* Alternate row colors */
	tbody tr:nth-child(odd) {
	  background-color: rgba(250, 250, 250); /* Light grey color */
	}
	tbody tr:nth-child(even) {
	  background-color: #ffffff; /* White color */
	}.feature-heading{
	  font-size:2.2vw;
	  font-weight:500;
	}td .bi-check-circle-fill{
		color: rgba(39, 174, 96);
		font-size:3vw;
		margin-left:2vw;
	}td .bi-x-circle-fill{
		color: rgba(231, 76, 60);
		font-size:3vw;
		margin-left:2vw;
	}.faqss-area{
		padding:1vw 0vw;
	}.faqss-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:5vw;
		font-weight:500;
	}.faqss-area .description{
		text-align:center;
		font-size:3vw;
		font-weight:500;
		line-height:4vw;
		color:rgba(50,50,50);
	}.accordion {
	  max-width: 100%;
	  margin: 4vw auto;
	}

	.faq-item {
	  border-bottom: 0.5vw solid rgba(240, 240, 240);
	  padding: 0.8vw 0;
	  margin:2.5vw 0;
	}

	.faq-question {
	  cursor: pointer;
	  padding: 2vw;
	  border-radius:1vw;
	  background-color: rgba(245, 245, 245);
	  font-weight: 500;
	  display: flex;
	  font-size:3.5vw;
	  justify-content: space-between;
	  align-items: center;
	}

	.faq-question.active {
	  background-color: rgba(240, 240, 240);
	}

	.faq-answer {
	  max-height: 0;
	  overflow: hidden;
	  transition: max-height 0.3s ease;
	  padding: 0.5vw 1vw;
	  font-size:3vw;
	  font-weight:500;
	}

	.faq-answer.open {
	  max-height: 50vw;
	  padding: 2.5vw 1vw;
	}

	.arrow {
	  transition: transform 0.3s ease;
	}

	.arrow.rotate {
	  transform: rotate(180deg);
	}.whatsapp-plans-body{
		margin-bottom:10vw;
	}.whatsapp-plans-body img{
		width:100%;
		height:75vw;
		display:block;
		object-fit:contain;
	}.whatsapp-plans-body .heading{
		text-align:center;
		font-size:5vw;
		font-weight:600;
		line-height:4vw;
	}.whatsapp-plans-body .subheading{
		text-align:center;
		font-size:3.5vw;
		line-height:4.5vw;
		font-weight:400;
		color:rgba(90, 90, 90);
	}

	.range-slider::-moz-range-thumb {
		width: 1vw;
		height: 1vw;
		background: rgba(255, 255,255);
		cursor: pointer;
		border-radius: 0.5vw;
	}
	}

}

