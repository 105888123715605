.blogs-area {
  font-family: 'Poppins', sans-serif;
  padding: clamp(2rem, 3vw, 5rem) clamp(2rem, 3vw, 5rem);
  background-color: rgba(250, 250, 250);
}

.blogs-area .featured-blog-poster {
  width: 100%;
  height: auto;
  border-radius: clamp(0.5vw, 1vw, 1.5vw);
}

.blogs-area .poster-container {
  padding: clamp(0.1vw, 0.3vw, 1vw) clamp(0.5vw, 1vw, 2vw);
}

.blogs-area .featured-post-area {
  background-color: rgba(255, 255, 255);
  border-radius: clamp(0.5vw, 1vw, 1.5vw);
}

.blogs-area .blog-category span {
  font-size: clamp(0.8rem, 1vw, 1.2rem);
  font-weight: 600;
  padding: clamp(0.3vw, 0.6vw, 1.2vw) clamp(0.8vw, 1vw, 1.5vw);
  border-radius: 8vw;
  background-color: rgba(2, 73, 80);
  margin-right: clamp(0.5vw, 1vw, 1.5vw);
  cursor: pointer;
  color: rgba(255, 255, 255);
}

.blogs-area .featured-blog-content-area {
  padding: clamp(1vw, 2vw, 3vw) clamp(0.5vw, 1vw, 2vw);
}

.blogs-area .featured-blog-title {
  font-weight: 600;
  font-size: clamp(1.5rem, 2vw, 3rem);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: clamp(1rem, 2vw, 3rem);
  color: rgba(50, 50, 50);
  line-height: clamp(2rem, 2.5vw, 3.5rem);
  letter-spacing: 0.03em;
}

.blogs-area .featured-blog-preview {
  font-weight: 400;
  font-size: clamp(0.8rem, 1vw, 1.2rem);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(104, 104, 104);
  letter-spacing: 0.03em;
  line-height: clamp(1.2rem, 1.5vw, 2rem);
}

.blogs-area .relative-blog-date {
  font-size: clamp(0.8rem, 0.8vw, 1.2rem);
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.blogs-area .relative-blog-author {
  font-size: clamp(0.8rem, 0.8vw, 1.2rem);
  color: rgba(2, 73, 80);
  font-weight: 400;
  text-align: right;
}

.blogs-area .relative-blog-date i {
  width: clamp(1vw, 1.2vw, 1.5vw);
  height: clamp(1vw, 1.2vw, 1.5vw);
  color: rgba(75, 75, 75);
  margin-bottom: clamp(0.5vw, 1vw, 1rem);
  margin-right: clamp(0.5vw, 0.6vw, 1vw);
}

.blogs-area .all-blogs-area {
  margin: clamp(1rem, 2vw, 3rem) 0;
}

.blogs-area .blog-search {
  width: 100%;
  background-color: rgba(255, 255, 255);
  border: clamp(0.1vw, 0.2vw, 0.3vw) solid rgba(255, 255, 255);
  padding: clamp(0.5vw, 0.8vw, 1.5vw) clamp(1vw, 1.2vw, 2vw);
  border-radius: clamp(0.3vw, 0.5vw, 0.7vw);
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.5rem);
  outline: none;
  background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat clamp(0.5vw, 1vw, 2vw) rgba(255, 255, 255);
  background-size: clamp(1vw, 1vw, 2vw);
  padding-left: clamp(2vw, 3vw, 4vw);
}

.blogs-area .blog-search::placeholder {
  color: rgba(196, 196, 196);
  font-weight: 400;
  opacity: 1;
}

.blogs-area .blog-search:focus {
  border: clamp(0.1vw, 0.2vw, 0.3vw) solid rgba(20, 20, 20);
}

.blogs-area .blog-search:-ms-input-placeholder {
  color: rgba(196, 196, 196);
}

.blogs-area .blog-search::-ms-input-placeholder {
  color: rgba(196, 196, 196);
}

.blogs-area .related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: clamp(0.8vw, 1.2vw, 1.5vw) clamp(0.8vw, 1.2vw, 1.5vw) 0 0;
}

.blogs-area .related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: clamp(1rem, 1vw, 1.5rem);
  margin: clamp(0.5vw, 1vw, 1rem) 0;
  color: rgba(50, 50, 50);
}

.blogs-area .related-blog-subheading {
  font-size: clamp(0.8rem, 0.8vw, 1.2rem);
  line-height: clamp(1.2rem, 1.2vw, 2rem);
  color: rgba(128, 128, 128);
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs-area .related-blog {
  margin: clamp(0.5vw, 1vw, 1.2vw) 0;
  background: #ffffff;
  border-radius: clamp(0.5vw, 1vw, 1.5vw);
  cursor: pointer;
}

.blogs-area .related-blog:hover {
  opacity: 0.7;
}

.blogs-area .related-blog-content-area {
  padding: clamp(1vw, 1.5vw, 2vw) clamp(1vw, 1.5vw, 2vw);
}

.blogs-area .search-area {
  margin: clamp(0.5vw, 1vw, 1.5vw) 0;
}

.blogs-area .featured-post-area:hover {
  opacity: 0.8;
  cursor: pointer;
}

.blogs-area .search-area h3 {
  font-size: clamp(1.5rem, 1.2vw, 2.5rem);
}


/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.blogs-area {
  font-family: 'Poppins', sans-serif;
  padding: 2.5vw 5vw;
  background-color: rgba(250, 250, 250);
}

.blogs-area .featured-post-area {
  display: none;
}

.blogs-area .featured-blog-poster {
  width: 100%;
  height: auto;
  border-radius: 1vw;
}

.blogs-area .poster-container {
  padding: 0.1vw 1vw;
}

.blogs-area .featured-post-area {
  background-color: rgba(255, 255, 255);
  border-radius: 1vw;
}

.blogs-area .blog-category span {
  font-size: 1vw;
  font-weight: 600;
  padding: 0.5vw 1.2vw;
  border-radius: 8vw;
  background-color: rgba(2,73,80);
  margin-right: 1vw;
  cursor: pointer;
  color: rgba(255, 255, 255);
}

.blogs-area .featured-blog-content-area {
  padding: 2vw 1vw;
}

.blogs-area .featured-blog-title {
  font-weight: 600;
  font-size: 2vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2vw;
  color: rgba(50, 50, 50);
  line-height: 2.5vw;
  letter-spacing: 0.03em;
}

.blogs-area .featured-blog-preview {
  font-weight: 400;
  font-size: 1vw;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(104, 104, 104);
  letter-spacing: 0.03em;
  line-height: 1.5vw;
}

.blogs-area .relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.blogs-area .relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.blogs-area .relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.blogs-area .all-blogs-area {
  margin: 3vw 0;
}

.blogs-area .blog-search {
  width: 100%;
  background-color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(255, 255, 255);
  padding: 1.5vw 2.4vw;
  border-radius: 1vw;
  font-weight: 500;
  margin: 2vw 0;
  margin-bottom: 4vw;
  font-size: 3.2vw;
  outline: none;
  background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 10px rgba(255, 255, 255);
  background-size: 3vw;
  padding-left: 8vw;
}

.blogs-area .blog-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(196, 196, 196);
  font-weight: 400;
  opacity: 1; /* Firefox */
}

.blogs-area .blog-search:focus {
  border: 0.2vw solid rgba(20, 20, 20);
}

.blogs-area .blog-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(196, 196, 196);
}

.blogs-area .blog-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(196, 196, 196);
}

.blogs-area .related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.blogs-area .related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.blogs-area .related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs-area .related-blog {
  margin: 4vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.blogs-area .related-blog:hover {
  opacity: 0.7;
}

.blogs-area .related-blog-content-area {
  padding: 4.5vw 4.5vw;
}

.blogs-area .search-area {
  margin: 1vw 0;
}

.blogs-area .featured-post-area:hover {
  opacity: 0.8;
  cursor: pointer;
}

.blogs-area .search-area h3 {
  font-size: 5vw;
}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.blogs-area {
  font-family: 'Poppins', sans-serif;
  padding: 2.5vw 5vw;
  background-color: rgba(250, 250, 250);
}

.blogs-area .featured-post-area {
  display: none;
}

.blogs-area .featured-blog-poster {
  width: 100%;
  height: auto;
  border-radius: 1vw;
}

.blogs-area .poster-container {
  padding: 0.1vw 1vw;
}

.blogs-area .featured-post-area {
  background-color: rgba(255, 255, 255);
  border-radius: 1vw;
}

.blogs-area .blog-category span {
  font-size: 1vw;
  font-weight: 600;
  padding: 0.5vw 1.2vw;
  border-radius: 8vw;
  background-color: rgba(2,73,80);
  margin-right: 1vw;
  cursor: pointer;
  color: rgba(255, 255, 255);
}

.blogs-area .featured-blog-content-area {
  padding: 2vw 1vw;
}

.blogs-area .featured-blog-title {
  font-weight: 600;
  font-size: 2vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2vw;
  color: rgba(50, 50, 50);
  line-height: 2.5vw;
  letter-spacing: 0.03em;
}

.blogs-area .featured-blog-preview {
  font-weight: 400;
  font-size: 1vw;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(104, 104, 104);
  letter-spacing: 0.03em;
  line-height: 1.5vw;
}

.blogs-area .relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.blogs-area .relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.blogs-area .relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.blogs-area .all-blogs-area {
  margin: 3vw 0;
}

.blogs-area .blog-search {
  width: 100%;
  background-color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(255, 255, 255);
  padding: 1.5vw 2.4vw;
  border-radius: 1vw;
  font-weight: 500;
  margin: 2vw 0;
  margin-bottom: 4vw;
  font-size: 3.2vw;
  outline: none;
  background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 10px rgba(255, 255, 255);
  background-size: 3vw;
  padding-left: 8vw;
}

.blogs-area .blog-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(196, 196, 196);
  font-weight: 400;
  opacity: 1; /* Firefox */
}

.blogs-area .blog-search:focus {
  border: 0.2vw solid rgba(20, 20, 20);
}

.blogs-area .blog-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(196, 196, 196);
}

.blogs-area .blog-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(196, 196, 196);
}

.blogs-area .related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.blogs-area .related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.blogs-area .related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs-area .related-blog {
  margin: 4vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.blogs-area .related-blog:hover {
  opacity: 0.7;
}

.blogs-area .related-blog-content-area {
  padding: 4.5vw 4.5vw;
}

.blogs-area .search-area {
  margin: 1vw 0;
}

.blogs-area .featured-post-area:hover {
  opacity: 0.8;
  cursor: pointer;
}

.blogs-area .search-area h3 {
  font-size: 5vw;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.blogs-area {
  font-family: 'Poppins', sans-serif;
  padding: 2.5vw 5vw;
  background-color: rgba(250, 250, 250);
}

.blogs-area .featured-post-area {
  display: none;
}

.blogs-area .featured-blog-poster {
  width: 100%;
  height: auto;
  border-radius: 1vw;
}

.blogs-area .poster-container {
  padding: 0.1vw 1vw;
}

.blogs-area .featured-post-area {
  background-color: rgba(255, 255, 255);
  border-radius: 1vw;
}

.blogs-area .blog-category span {
  font-size: 1vw;
  font-weight: 600;
  padding: 0.5vw 1.2vw;
  border-radius: 8vw;
  background-color: rgba(2,73,80);
  margin-right: 1vw;
  cursor: pointer;
  color: rgba(255, 255, 255);
}

.blogs-area .featured-blog-content-area {
  padding: 2vw 1vw;
}

.blogs-area .featured-blog-title {
  font-weight: 600;
  font-size: 2vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2vw;
  color: rgba(50, 50, 50);
  line-height: 2.5vw;
  letter-spacing: 0.03em;
}

.blogs-area .featured-blog-preview {
  font-weight: 400;
  font-size: 1vw;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(104, 104, 104);
  letter-spacing: 0.03em;
  line-height: 1.5vw;
}

.blogs-area .relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.blogs-area .relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.blogs-area .relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.blogs-area .all-blogs-area {
  margin: 3vw 0;
}

.blogs-area .blog-search {
  width: 100%;
  background-color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(255, 255, 255);
  padding: 1.5vw 2.4vw;
  border-radius: 1vw;
  font-weight: 500;
  margin: 2vw 0;
  margin-bottom: 4vw;
  font-size: 3.2vw;
  outline: none;
  background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 10px rgba(255, 255, 255);
  background-size: 3vw;
  padding-left: 8vw;
}

.blogs-area .blog-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(196, 196, 196);
  font-weight: 400;
  opacity: 1; /* Firefox */
}

.blogs-area .blog-search:focus {
  border: 0.2vw solid rgba(20, 20, 20);
}

.blogs-area .blog-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(196, 196, 196);
}

.blogs-area .blog-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(196, 196, 196);
}

.blogs-area .related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.blogs-area .related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.blogs-area .related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs-area .related-blog {
  margin: 4vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.blogs-area .related-blog:hover {
  opacity: 0.7;
}

.blogs-area .related-blog-content-area {
  padding: 4.5vw 4.5vw;
}

.blogs-area .search-area {
  margin: 1vw 0;
}

.blogs-area .featured-post-area:hover {
  opacity: 0.8;
  cursor: pointer;
}

.blogs-area .search-area h3 {
  font-size: 5vw;
}

}
