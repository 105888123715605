
			.create-code-editor-template-body{
				  font-family: 'Poppins', sans-serif;
				  background:rgba(245, 245, 245);
				  min-height:100vh;
				  overflow-x:hidden;
				  
				  .header-area{
					  padding:1.2vw 1.5vw;
					  background:rgba(255, 255, 255);
					  border-bottom:0.2vw solid rgba(240, 240, 240);
				  }.breadcrumb .breadcrumb-item{
							font-size:1vw;
				  }.breadcrumb-area{
						padding:0;
					}.breadcrumb .breadcrumb-item a{
						font-size:1vw;
						text-decoration:none;
						font-weight:400;
						color:rgba(100, 100, 100);
					}.breadcrumb .active{
						font-weight:500;
						color:rgba(10, 10, 10);
					}.breadcrumb .breadcrumb-item a:hover{
						text-decoration:underline;
						color:rgba(20, 20, 20);
					}.primary-button {
					  border-radius: 0.4vw;
					  font-size: 0.8vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(255, 255, 255);
					  border: 0.2vw solid rgba(2, 96, 105);
					  background: rgba(2, 96, 105);
					}.secondary-outline-button{
					  border-radius: 0.4vw;
					  font-size: 0.8vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(10, 10, 10);
					  border-radius:0.5vw;
					  border: 0.15vw solid rgba(235, 235, 235);
					  background: rgba(255, 255, 255);
				  }.template-title{
					  text-align:center;
				  }.template-title{
					  background:rgba(255, 255, 255);
					  border:none;
					  width:100%;
					  outline:none;
					  font-size:1vw;
					  border-radius:0.4vw;
					  padding: 0.5vw 1.2vw;
				  }.template-title:focus, .template-title:hover{
					  background:rgba(245, 245, 245);
				  }.live-editor {
				  height: 89vh;
				  background-color: rgba(1, 22, 39);
				  font-family: 'Fira Code', monospace;
				  font-size: 1.2vw;
				  border: 0.1vw solid #ccc;
				  border-radius: 4px;
				  overflow: auto;
					padding:0.5vw;
				}.live-preview{
					height:89vh;
					padding:0.5vw;
					background:rgba(255, 255, 255);
				}
				 
			}.input-forms-modal{
					width: 100%;
					font-size: 0.8vw;
					border:0.14vw solid rgba(150, 150, 150);
					border-radius: 0.4vw;
					padding: 0.6vw 0.8vw;
				}.label-modal-form{
					font-size: 0.8vw;
				}
			
