/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.job-area{
	font-family: 'Poppins', sans-serif;
.job-category{
background-color: rgba(184,232,248);
display:inline-block;
color:rgba(0,0,255);
margin:2vw 0;
	}.job-needed{
background-color: rgba(255,202,174);
color:rgba(203,96,0);
display:inline-block;
margin:2vw 0;
	}.job-icon{
	width:20vw;
	height:20vw;
	background-color:rgba(255,255,255);
	object-fit:contain;
	margin-bottom:5vw;
	padding:0.5vw;
}.apply-button{
	border:none;
	padding:1.6vw 2vw;
	background-image: linear-gradient(to right, rgba(50,50,50) , rgba(0,0,0));
	border:none;
	color:rgba(255,255,255);
	width:100%;
	display:block;
	font-size:4.5vw;
}.description{
font-size:1.4vw;
line-height:3vw;
font-weight:normal;
}li{
font-size:1.4vw;
line-height:3vw;
color:rgba(128,128,128);
font-weight:normal;
margin-bottom:2vw;
} .facebook{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;;
	background-color:rgba(0, 119, 181);
}.job-info-bulletes{
	font-size:1.6vw;
	
	line-height:3vw;
}	.job-textarea{
	width:100%;
	border:none;
	background-color:rgba(255,255,255);
	border:0.1vw solid rgba(241, 241, 241);
	outline:none;
	border-radius:0.5vw;
	padding:1vw 2vw;
	}.right-title{
		font-size:6vw;
	}.right-subtitle{
		font-size:4vw;
	}.poster-icon{
		width:15vw;
		height:15vw;
		padding:0.5vw;
		object-fit:contain;
		background-color:rgba(255,255,255);
		border-radius:50%;
	}.main-content{
		padding:4vw 8vw;
	}.extra-job-info-area{
		padding:4.5vw;
		border-radius:4vw;
		background:rgba(250, 250, 250);
	}.job-title{
		font-size:5vw;
	}.job-category{
		background-color: rgba(184,232,248);
		color:rgba(0,0,255);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		display:inline-block;
			}.job-needed{
		background-color: rgba(255,202,174);
		color:rgba(203,96,0);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		margin-right:1vw;
			}.job-description{
				font-size:4vw;
			}.share-title{
				font-size:4.2vw;
			}.facebook{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(0, 119, 181);
}.extra-job-info-area-heading{
	font-size:4.5vw;
}
}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.job-area{
	font-family: 'Poppins', sans-serif;
.job-category{
background-color: rgba(184,232,248);
display:inline-block;
color:rgba(0,0,255);
margin:2vw 0;
	}.job-needed{
background-color: rgba(255,202,174);
color:rgba(203,96,0);
display:inline-block;
margin:2vw 0;
	}.job-icon{
	width:20vw;
	height:20vw;
	background-color:rgba(255,255,255);
	object-fit:contain;
	margin-bottom:5vw;
	padding:0.5vw;
}.apply-button{
	border:none;
	padding:1.6vw 2vw;
	background-image: linear-gradient(to right, rgba(50,50,50) , rgba(0,0,0));
	border:none;
	color:rgba(255,255,255);
	width:100%;
	display:block;
	font-size:4.5vw;
}.description{
font-size:1.4vw;
line-height:3vw;
font-weight:normal;
}li{
font-size:1.4vw;
line-height:3vw;
color:rgba(128,128,128);
font-weight:normal;
margin-bottom:2vw;
} .facebook{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;;
	background-color:rgba(0, 119, 181);
}.job-info-bulletes{
	font-size:1.6vw;
	
	line-height:3vw;
}	.job-textarea{
	width:100%;
	border:none;
	background-color:rgba(255,255,255);
	border:0.1vw solid rgba(241, 241, 241);
	outline:none;
	border-radius:0.5vw;
	padding:1vw 2vw;
	}.right-title{
		font-size:6vw;
	}.right-subtitle{
		font-size:4vw;
	}.poster-icon{
		width:15vw;
		height:15vw;
		padding:0.5vw;
		object-fit:contain;
		background-color:rgba(255,255,255);
		border-radius:50%;
	}.main-content{
		padding:4vw 8vw;
	}.extra-job-info-area{
		padding:4.5vw;
		border-radius:4vw;
		background:rgba(250, 250, 250);
	}.job-title{
		font-size:5vw;
	}.job-category{
		background-color: rgba(184,232,248);
		color:rgba(0,0,255);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		display:inline-block;
			}.job-needed{
		background-color: rgba(255,202,174);
		color:rgba(203,96,0);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		margin-right:1vw;
			}.job-description{
				font-size:4vw;
			}.share-title{
				font-size:4.2vw;
			}.facebook{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(0, 119, 181);
}.extra-job-info-area-heading{
	font-size:4.5vw;
}
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.job-area{
	font-family: 'Poppins', sans-serif;
.job-category{
background-color: rgba(184,232,248);
display:inline-block;
color:rgba(0,0,255);
margin:2vw 0;
	}.job-needed{
background-color: rgba(255,202,174);
color:rgba(203,96,0);
display:inline-block;
margin:2vw 0;
	}.job-icon{
	width:20vw;
	height:20vw;
	background-color:rgba(255,255,255);
	object-fit:contain;
	margin-bottom:5vw;
	padding:0.5vw;
}.apply-button{
	border:none;
	padding:1.6vw 2vw;
	background-image: linear-gradient(to right, rgba(50,50,50) , rgba(0,0,0));
	border:none;
	color:rgba(255,255,255);
	width:100%;
	display:block;
	font-size:4.5vw;
}.description{
font-size:1.4vw;
line-height:3vw;
font-weight:normal;
}li{
font-size:1.4vw;
line-height:3vw;
color:rgba(128,128,128);
font-weight:normal;
margin-bottom:2vw;
} .facebook{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:2vw;
	margin-bottom:2vw;
	margin-top:2vw;
	display:inline-block;;
	background-color:rgba(0, 119, 181);
}.job-info-bulletes{
	font-size:1.6vw;
	
	line-height:3vw;
}	.job-textarea{
	width:100%;
	border:none;
	background-color:rgba(255,255,255);
	border:0.1vw solid rgba(241, 241, 241);
	outline:none;
	border-radius:0.5vw;
	padding:1vw 2vw;
	}.right-title{
		font-size:6vw;
	}.right-subtitle{
		font-size:4vw;
	}.poster-icon{
		width:15vw;
		height:15vw;
		padding:0.5vw;
		object-fit:contain;
		background-color:rgba(255,255,255);
		border-radius:50%;
	}.main-content{
		padding:4vw 8vw;
	}.extra-job-info-area{
		padding:4.5vw;
		border-radius:4vw;
		background:rgba(250, 250, 250);
	}.job-title{
		font-size:5vw;
	}.job-category{
		background-color: rgba(184,232,248);
		color:rgba(0,0,255);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		display:inline-block;
			}.job-needed{
		background-color: rgba(255,202,174);
		color:rgba(203,96,0);
		padding:1.5vw 3.5vw;
		margin-top:2vw;
		margin-right:1vw;
		font-size:4vw;
		margin-right:1vw;
			}.job-description{
				font-size:4vw;
			}.share-title{
				font-size:4.2vw;
			}.facebook{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	color:rgba(255,255,255);
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 8vw;
	color:rgba(255,255,255);
	border-radius:1vw;
	margin-right:1vw;
	font-size:4vw;
	background-color:rgba(0, 119, 181);
}.extra-job-info-area-heading{
	font-size:4.5vw;
}
}

}

/* Laptops and desktops */
@media only screen and (min-width: 1024px) {
.job-area{
	font-family: 'Poppins', sans-serif;
	.job-icon{
	width:10vw;
	height:10vw;
	background-color:rgba(255,255,255);
	object-fit:contain;
	padding:0.5vw;
}.apply-button{
		border:none;
	padding:0.6vw 2vw;
	background-image: linear-gradient(to right, rgba(50,50,50) , rgba(0,0,0));
	border:none;
	color:rgba(255,255,255);
	width:auto;
	font-size:1.5vw;
}.description{
font-size:1.4vw;
line-height:3vw;
font-weight:normal;
}li{
font-size:1.4vw;
line-height:3vw;
color:rgba(128,128,128);
font-weight:normal;
margin-bottom:2vw;
} .job-info-bulletes{
	font-size:1.6vw;
	
	line-height:3vw;
}	.job-textarea{
	width:100%;
	border:none;
	background-color:rgba(255,255,255);
	border:0.1vw solid rgba(241, 241, 241);
	outline:none;
	border-radius:0.5vw;
	padding:1vw 2vw;
	}.right-title{
		font-size:1.4vw;
	}.right-subtitle{
		font-size:1vw;
	}.poster-icon{
		width:5vw;
		height:5vw;
		padding:0.5vw;
		object-fit:contain;
		background-color:rgba(255,255,255);
		border-radius:50%;
	}.main-content{
		padding:4vw 8vw;
	}.extra-job-info-area{
		padding:1vw;
		border-radius:1vw;
		margin-left:2vw;
		background:rgba(250, 250, 250);
	}.job-title{
		font-size:2vw;
	}.job-category{
		background-color: rgba(184,232,248);
		width:100%;
		color:rgba(0,0,255);
		padding:0.5vw 1.5vw;
		margin-top:1vw;
		margin-right:1vw;
		font-size:1vw;
			}.job-needed{
		background-color: rgba(255,202,174);
		color:rgba(203,96,0);
		font-size:1vw;
		margin-top:1vw;
		padding:0.5vw 1.5vw;
		margin-right:1vw;
		width:100%;
			}.job-description{
				font-size:1vw;
			}.share-title{
				font-size:1.2vw;
			}.facebook{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	color:rgba(255,255,255);
	border-radius:0.5vw;
	margin-right:1vw;
	font-size:1vw;
	background-color:rgba(60,88,152);
} .twitter{
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:0.5vw;
	color:rgba(255,255,255);
	margin-right:1vw;
	font-size:1vw;
	background-color:rgba(10, 10, 10);
} .linkedin{
	color:rgba(255,255,255);
	padding:0.6vw 1vw;
	color:rgba(255,255,255);
	border-radius:0.5vw;
	margin-right:1vw;
	font-size:1vw;
	background-color:rgba(0, 119, 181);
}.extra-job-info-area-heading{
	font-size:1.5vw;
}
}
}