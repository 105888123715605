@media only screen and (max-width: 767px) {
	
	/* Style the dropdown MenuItem options */
	.custom-select .MuiPaper-root {
	  font-size:10vw;
	  background-color: #fff; /* Background color of the dropdown */
	  border-radius: 0.5vw; /* Rounded corners for dropdown */
	  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
	}

	.custom-select .MuiMenuItem-root {
	  font-size: 2vw; /* Font size of menu items */
	  padding: 12px 16px; /* Padding for each menu item */
	  color: #555; /* Text color */
	  background-color: #fff; /* Background color */
	}
	
	/* Style the image/icon inside the select box and dropdown */
	.custom-select-icon {
	  margin-right: 8px; /* Add spacing between icon and text */
	  width: 6vw; /* Set width of the image/icon */
	  height: 6vw; /* Set height of the image/icon */
	  object-fit: cover; /* Ensure the image scales properly */
	}
	
	.currency-select .custom-select-typography {
	  font-size: 10vw; /* Change this to your desired font size */
	}
	
		.country-select {
	  padding: 0 0.01vw;
	  font-size:10vw;
	  height:10vw;
	}

	.country-select .MuiOutlinedInput-notchedOutline {
	  border-color: rgba(220, 220, 220);
	  font-size:10vw;
	}


	.MuiMenuItem-root {
	  font-weight: bold;
	  color: blue;
	  padding:0;
	  font-size:10vw;
	}
	.country-select .custom-select-typography {
		  padding:0.5vw;
		  font-size: 4vw; /* Change this to your desired font size */
		}
		

	
	
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
	
	.custom-select .MuiPaper-root {
	  font-size:10vw;
	  background-color: #fff; /* Background color of the dropdown */
	  border-radius: 0.5vw; /* Rounded corners for dropdown */
	  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
	}

	.custom-select .MuiMenuItem-root {
	  font-size: 2vw; /* Font size of menu items */
	  padding: 12px 16px; /* Padding for each menu item */
	  color: #555; /* Text color */
	  background-color: #fff; /* Background color */
	}
	
	/* Style the image/icon inside the select box and dropdown */
	.custom-select-icon {
	  margin-right: 8px; /* Add spacing between icon and text */
	  width: 6vw; /* Set width of the image/icon */
	  height: 6vw; /* Set height of the image/icon */
	  object-fit: cover; /* Ensure the image scales properly */
	}
	
	.currency-select .custom-select-typography {
	  font-size: 4.2vw; /* Change this to your desired font size */
	}
	
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.custom-select .MuiPaper-root {
	  font-size:10vw;
	  background-color: #fff; /* Background color of the dropdown */
	  border-radius: 0.5vw; /* Rounded corners for dropdown */
	  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
	}

	.custom-select .MuiMenuItem-root {
	  font-size: 2vw; /* Font size of menu items */
	  padding: 12px 16px; /* Padding for each menu item */
	  color: #555; /* Text color */
	  background-color: #fff; /* Background color */
	}
	
	/* Style the image/icon inside the select box and dropdown */
	.custom-select-icon {
	  margin-right: 8px; /* Add spacing between icon and text */
	  width: 6vw; /* Set width of the image/icon */
	  height: 6vw; /* Set height of the image/icon */
	  object-fit: cover; /* Ensure the image scales properly */
	}
	
	.currency-select .custom-select-typography {
	  font-size: 4.2vw; /* Change this to your desired font size */
	}
	
}

@media only screen and (min-width: 1024px) {
	/* Style the dropdown MenuItem options */
	.custom-select .MuiPaper-root {
	  font-size:10vw;
	  background-color: #fff; /* Background color of the dropdown */
	  border-radius: 0.5vw; /* Rounded corners for dropdown */
	  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
	}

	.custom-select .MuiMenuItem-root {
	  font-size: 2vw; /* Font size of menu items */
	  padding: 12px 16px; /* Padding for each menu item */
	  color: #555; /* Text color */
	  background-color: #fff; /* Background color */
	}
	
	/* Style the image/icon inside the select box and dropdown */
	.custom-select-icon {
	  margin-right: 8px; /* Add spacing between icon and text */
	  
	  width: 1.6vw; /* Set width of the image/icon */
	  height: 1.6vw; /* Set height of the image/icon */
	  object-fit: cover; /* Ensure the image scales properly */
	}
	
	.currency-select .custom-select-typography {
	  font-size: 1.2vw; /* Change this to your desired font size */
	}
	
	/* In your CSS file */
	.country-select {
	  padding: 0 0.01vw;
	  font-size:4.2vw;
	  margin:0;
	  height:3vw;
	}

	.country-select .MuiOutlinedInput-notchedOutline {
	  border-color: rgba(220, 220, 220);
	  font-size:4.2vw;
	}


	.MuiMenuItem-root {
	  font-weight: bold;
	  color: blue;
	  padding:0;
	  font-size:4.2vw;
	}
	.country-select .custom-select-typography {
		  padding:0.5vw;
		  font-size: 1.2vw; /* Change this to your desired font size */
		}
		
	
}