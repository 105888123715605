.empty-error-area{
	padding:2vw 15vw;
}
.empty-error-image{
	width:30vw;
	height:auto;
}.empty-error-heading{
	font-size:2.2vw;
	color:rgba(10, 10, 10);
}.empty-error-subheading{
	font-size:1.2vw;
	color:rgba(150, 150, 150);
	font-weight:400;
	line-height:1.8vw;
}
.sidebar-body{
	background:rgba(250, 250, 250);
	.main-container {
	display: flex;
	height: 100vh; /* Full viewport height */
}.small-side-bar {
	flex: 0 0 4.5%; /* Adjusted width of the sidebar */
	background-color: rgba(243,244,246); /* Example background color */
	display: flex;
	flex-direction: column; /* Stack items vertically */
	justify-content: space-between;
	border-right:0.2vw solid rgba(240, 240, 240);
	.logo{
		width:100%;
		height:2vw;
		object-fit:contain;
		margin-bottom:1vw;
	}
	.nav-link{
		text-align:center;
		font-size:1.2vw;
		padding:0.3vw 0.4vw;
		border-radius:0.4vw;
		margin:0.6vw 0;
		-webkit-text-stroke: 0.02vw;
	}.nav-link:hover{
		background:rgba(220, 220, 220);
	}.active, .active:hover{
		border-radius:0.4vw;
		background:rgba(2,96,105);
		color:rgba(255, 255, 255);
	}.profile-image{
		width:100%;
		cursor:pointer;
		height:2.5vw;
		border:0.2vw solid rgba(255,255,255);
		object-fit:cover;
		border-radius:100%;
	}.profile{
		border-top:0.1vw solid rgba(210, 210, 210);
		padding:1vw 0;
	}.top-area{
		padding:1vw;
	}.mid-area-sidebar{
		padding: 0.5vw 1vw;
	}
	
	
	
	
	.profile-menu-container {
	  position: relative;
	  display: inline-block;
	}


	.profile-menu {
	  position: absolute;
	  bottom: 0.1vw; /* Adjust according to your design */
	  left: 5vw;
	  background-color: white;
	  border: 0.1vw solid rgba(240, 240, 240);
	  box-shadow: 0 0.2vw 1vw rgba(0, 0, 0, 0.1);
	  border-radius: 0.8vw;
	  z-index: 1000;
	  min-width:18vw;
	  width: auto; /* Adjust width as needed */
	}.person-image{
		width:3vw;
		cursor:pointer;
		height:3vw;
		border:0.2vw solid rgba(235,235,235);
		object-fit:cover;
		border-radius:100%;
	}.person-name{
		padding-top:0.5vw;
		font-size:1vw;
		display:block;
		text-align:left;
		line-height:1.2vw;
		padding-left:0.6vw;
	}.person-email{
		font-size:0.8vw;
		display:block;
		line-height:1.2vw;
		color:rgba(100, 100, 100);
		padding-left:0.6vw;
		text-align:left;
	}.profile-menu .top-area{
		border-bottom:0.2vw solid rgba(240, 240, 240);
	}.mid-area-menu .profile-menu-link, .bottom-area-menu .profile-menu-link{
		display:block;
		text-decoration:none;
		font-size:0.9vw;
		color:rgba(45, 45, 45);
		margin:0.4vw 0;
		Padding:0.4vw 0.8vw;
		font-weight:500;
		border-radius:0.5vw;
	}.mid-area-menu .profile-menu-link .bi, .bottom-area-menu .profile-menu-link .bi{
		margin-right:0.4vw;
		-webkit-text-stroke: 0.03vw;
	}.mid-area-menu{
		border-bottom:0.1vw solid rgba(220, 220, 220);
		Padding:0vw 0.8vw;
	}.bottom-area-menu{
		Padding:0 0.8vw;
	}.profile-menu .profile-menu-link:hover{
		background:rgba(240, 240, 240);
	}

	
	
	
	
}.side-bar {
		
		flex: 0 0 16%; /* 35% of the page */
		padding:1vw 1vw;
		overflow-y:auto;
		border-right:0.2vw solid rgba(240, 240, 240);
		background-color: rgba(254,254,254); /* Example background color */
		.nav-title{
			font-size:1vw;
			font-weight: bold;
			margin:0.7vw 0;
		}.logo-area{
			border-bottom:0.2vw solid rgba(245, 245, 245);
		}
	.nav-link{
		font-size:0.95vw;
		padding:0.5vw 0.8vw;
		font-weight:500;
		margin:0.6vw 0;
		border-radius:0.4vw;
	}.nav-link:hover{
		background:rgba(240, 240, 240);
	}.active, .active:hover{
		border-radius:0.4vw;
		font-weight:500;
		background:rgba(2,96,105);
		color:rgba(255, 255, 255);
	}.nav-link .bi{
		margin-right:0.5vw;
		-webkit-text-stroke: 0.02vw;
	}.accordion-menu {
	  margin-left: 1.5vw;
	  display: flex;
	  flex-direction: column;
	  border-left: 0.2vw solid rgba(240, 240, 240); /* Vertical line */
	  padding-left: 0.5vw; /* Padding between the line and the links */
	}.accordion-menu .nav-link{
		font-size:0.9vw;
		margin:0.1vw 0;
		color:rgba(100, 100, 100);
		padding:0.4vw 1vw;
	}

	.nav-link.active {
	  font-weight: bold;
	}
		.accordion-menu .active{
			color:rgba(1,1,1);
			background:none;
		}
}.notification-sidenav {
	  height: 100%;
	  width: 0;
	  position: fixed;
	  top: 0;
	  right: 0;
	  background-color: rgba(255, 255, 255);
	  overflow-x: hidden;
	  transition: 0.5s;
	  padding-top:1vw;
	  z-index: 3;
	  border-radius:1vw 0 0 1vw;
	}.notification-sidenav.open {
	  width: 25vw; /* Adjust the width as needed */
	}

	.notification-overlay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	  background-color: rgba(0,0,0,0.1);
	  visibility: hidden;
	  opacity: 0;
	  transition: visibility 0s, opacity 0.5s ease;
	  z-index: 2;
	}

	.notification-overlay.show {
	  visibility: visible;
	  opacity: 1;
	}.notification-header-area{
		position: fixed;
		top: 0;
		display:block;
		width:31vw;
		background:rgba(255, 255, 255);
		border-radius:1vw 0 0 0;
		padding: 0.5vw 2vw;
		border-bottom:0.1vw solid rgba(240, 240, 240);
	}.notification-header-area-heading{
		font-size:1.1vw;
		padding-top:0.7vw;
		padding-bottom: 0.5vw;
	}.close-notification-bar{
		font-size:2vw;
		text-align:right;
		display:block;
		color:rgba(150, 150, 150);
	}.notification-list{
		padding: 1.2vw 1.5vw;
		border-bottom:0.1vw solid rgba(240, 240, 240);
	}.notification-list-icon{
		width:4vw;
		height:4vw;
		border:0.1vw solid rgba(240, 240, 240);
		object-fit:cover;
		border-radius:100%;
	}.notification-list-heading{
		font-size:1vw;
		font-weight:500;
		padding-top:0.5vw;
		color:rgba(50, 50, 50);
	}.notification-list-subheading{
		font-size:1vw;
		font-weight:400;
		color:rgba(100, 100, 100);
	}.notification-list-heading b{
		color:rgba(1, 1, 1);
		font-weight:600;
	}.notification-list-area{
		margin-top:3vw;
	}.no-notification-icon{
		width:8vw;
		margin:1vw;
		opacity:0.5;
		height:auto;
	}.no-notification-heading{
		font-size:1.5vw;
		margin:1vw 0;
	}.no-notification-subheading{
		font-size:0.9vw;
		margin:1vw 0;
		font-weight:400;
		color:rgba(150, 150, 150);
		line-height:1.5vw;
	}.no-notification-area{
		padding:4vw 2vw;
	}


}.main-body{
flex: 1;	
overflow-y:auto;
}