/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
}

/* Laptops and desktops */
@media only screen and (min-width: 1024px) {
			.sidebar-body{
				  background:rgba(255, 255, 255);
			}.sms-api-body{
				.table-container{
					padding:2vw;
					padding-top:1vw;
				}.your-api-heading{
					font-size:1.5vw;
					margin-bottom:2vw;
				}
				table {
					width: 100%;
					border:0.1vw solid #ddd;
					border-collapse: separate; /* Allows border-radius to work */
					border-spacing: 0; /* Removes spacing between cells */
					border-radius: 10px; /* Rounds the corners of the table */
					overflow: hidden; /* Ensures the border-radius applies to the table content */
				}

				th, td {
					padding: 1.2vw;
					text-align: left;
					border-top: 0.1vw solid rgba(240, 240, 240);
					border-radius: 0; /* No border-radius on cells */
				}

				thead {
					background-color: rgba(150, 150, 150);
				}td h5{
					font-size:1.2vw;
				}

				th {
					background-color: rgba(240, 240, 240);
					color: rgba(50, 50, 50);
					font-size:1vw;
					font-weight:500;
				}table .member-name{
					padding-top:1vw;
					font-size:1.1vw;
				}table .memeber-email{
					font-size:1vw;
					color:rgba(125, 125, 125);
				}table td{
					font-size:1vw;
				}table .member-icon{
					width:4.2vw;
					height:4.2vw;
					border-radius:50vw;
					border:0.1vw solid rgba(240, 240, 240);
					object-fit:cover;
				}table td .bi{
					font-size:1.5vw;
					margin:0 0.5vw;
					color:rgba(100, 100, 100);
				}.documentation-area{
					padding:1vw 2vw;
				}.documentation-heading{
					font-size:1.5vw;
					font-weight:600;
				}.documentation-description{
					font-size:1.2vw;
					color:rgba(75, 75, 75);
					font-weight:400;
					line-height:1.8vw;
					margin:2vw 0;
				}.code-box{
					border:0.2vw solid rgba(235, 235, 235);
					border-radius:0.4vw;
					margin-bottom:5vw;
				}.code-tab{
					text-decoration:none;
					color: rgba(100, 100, 100);
					padding: 0.5vw 1vw;
					margin:0 1vw;
					cursor:pointer;
				}.code-tabs-area{
					border-bottom:0.2vw solid rgba(240, 240, 240);
					margin:1vw 0;
					font-size:1vw;
					padding: 0.5vw 0.5vw;
				}.code-tabs-area .active{
					color:rgba(2, 96, 105);
					font-weight:500;
					border-bottom:0.2vw solid rgba(2, 96, 105);
				}.documentation-link{
					font-size:1.2vw;
					color:rgba(2, 96, 105);
				}.tab-content-area{
					padding:0vw 2vw;
					font-size:1.2vw;
				}
					.page-content {
						  font-family: 'Poppins', sans-serif;
						  background-color: rgba(255, 255, 255);
						  background:rgba(255, 255, 255);
					.header-area{
						padding:2vw;
						padding-top:1vw;
					}.breadcrumb .breadcrumb-item{
							font-size:1vw;
				}.primary-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(255, 255, 255);
				  border: 0.2vw solid rgba(2, 96, 105);
				  background: rgba(2, 96, 105);
				}
				.primary-outline-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(2, 96, 105);
				  border: 0.2vw solid rgba(2, 96, 105);
				  background: rgba(255, 255, 255);
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}

				}label{
					font-size:1vw;
					font-weight:500;
					display:block;
					color:rgb(75, 75, 75);
				}.input-form{
						display:block;
						width:100%;
						color: rgb(50, 50, 50);
						margin-top:0.5vw;
						border: none;
						background:rgb(255, 255, 255);
						border: 0.14vw solid rgb(220, 220, 220);
						border-radius: 0.4vw;
						padding: 0.5vw 1vw;
						font-size: 1vw;
						font-weight:500;
						outline: none;
					}.secondary-button {
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
					color:rgba(50, 50, 50);
					border:0.2vw solid rgba(230,230,230);
					background:rgba(230,230,230);
				}.api-disclaimer{
					font-size:1.1vw;
				}.input-form:focus{
					border:0.14vw solid rgba(2,96,105);
				}
			}
			
}
