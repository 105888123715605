.sidebar-body{
	background:rgba(255, 255, 255);
	  .email-templates-body{
		  .my-template-area{
			  padding:0vw 2vw;
		  }.template-label{
			  font-size:1.2vw;
			  display:block;
			  margin-bottom:0.1vw;
		  }.template-col{
			  border:0.1vw solid rgba(220, 220, 220);
			  border-radius:0.5vw;
			  margin:1.5vw 0;
		  }.template-col .image-area{
			  background:rgba(240, 240, 240);
			  padding: 2vw 0vw 0vw 0vw;
			  overflow: hidden;
		  }.email-template-name{
			  font-size:1vw;
			  line-height:1.5vw;
			  font-weight:500;
		  }.email-template-name a{
			  text-decoration:none;
			  color:rgba(2, 96, 105);
			  cursor:pointer;
		  }.template-col .bottom-area{
			  padding:1.5vw 1.5vw;
		  }.email-template-description{
			  font-size:0.8vw;
			  line-height:1.2vw;
			  color:rgba(100, 100, 100);
			  font-weight:400;
		  }.image-area {
			position: relative;
			width: 100%; /* Adjust as needed */
			border-bottom:0.1vw solid rgba(220, 220, 220);
		  }

		  .template-image {
			display: block;
			width: auto;
			max-width: auto;
			border:0.1vw solid rgba(220, 220, 220);
			border-bottom:0;
			height: 150px;
			object-fit:cover;
			overflow: hidden;
			border-radius: 0.5vw 0.5vw 0 0;
			object-position:top;
		  }

		  .overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0; /* Initially hidden */
			transition: opacity 0.3s ease; /* Smooth transition */
		  }

		  .image-area:hover .overlay {
			opacity: 1; /* Show overlay on hover */
		  }
		  .center-button {
			border: none;
			padding: 0.8vw 1.5vw;
			margin:0.5vw;
			font-size: 1.2vw;;
			cursor: pointer;
			border-radius:0.4vw;
			opacity: 0; /* Initially hidden */
			transition: opacity 0.3s ease; /* Smooth transition */
		  }.edit-button{
			  background:rgba(2, 96, 105);
			  color:rgba(255, 255, 255);
			  font-weight:500;
		  }.delete-button{
			  background:rgba(231, 76, 60);
			  color:rgba(255, 255, 255);
			  font-weight:500;
		  }

		  .image-area:hover .center-button {
			opacity: 1; /* Show button on hover */
		  }
	  .page-content {
			font-family: 'Poppins', sans-serif;
			background-color: rgba(255, 255, 255);
			background:rgba(255, 255, 255);
	  .header-area{
		  padding:2vw;
		  padding-top:1vw;
	  }
}.breadcrumb-area{
  padding:2vw 2vw 0vw 2vw;
}.breadcrumb .breadcrumb-item a{
  text-decoration:none;
  font-weight:400;
  color:rgba(100, 100, 100);
}.breadcrumb .active{
  font-weight:500;
  color:rgba(10, 10, 10);
}.breadcrumb .breadcrumb-item a:hover{
  text-decoration:underline;
  color:rgba(20, 20, 20);
}.main-heading{
  font-size: 1.2vw;
  line-height: 1.5vw;
}.main-subheading{
  font-size: 0.8vw;
}.top-area{
  background-color: rgb(241, 241, 241);
  padding: 1.5vw;
}.primary-button {
  margin-top: 1vw;
  border-radius: 0.4vw;
  font-size: 0.9vw;
  font-weight: 500;
  padding: 0.5vw 1.2vw;
  color: rgba(255, 255, 255);
  border: 0.2vw solid rgba(2, 96, 105);
  background: rgba(2, 96, 105);
}
.primary-outline-button {
  margin-top: 1vw;
  border-radius: 0.4vw;
  font-size: 0.9vw;
  font-weight: 500;
  padding: 0.5vw 1.2vw;
  color: rgba(2, 96, 105);
  border: 0.2vw solid rgba(2, 96, 105);
  background: rgba(255, 255, 255);
}

}
}
