
.create-drag-drop-template-body{
	font-family: 'Poppins', sans-serif;
	background:rgba(245, 245, 245);
	min-height:100vh;
	overflow-x:hidden;
	header{
		background:rgba(10, 10, 10);
		padding:1vw;
	}.draggable-area-of-page div{

	  border: 1px dashed rgb(218, 218, 218);
	}.draggable-area-of-page div div{
	  border:none;
	}.EditLayoutAreaButton{
	  font-size: 12px;
	  border: 1px solid #e7e7e7;
	  padding: 3px 6px;
	  margin: 0 5px;
	  border-radius: 5px;
	  cursor: pointer;
	}.EditLayoutAreaButton:hover{
	  opacity: 0.5;
	}.EditLayoutAreaButton:disabled{
	  opacity: 0.1;
	  cursor: not-allowed;
	}
	.primary-button {
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(255, 255, 255);
		border: 0.2vw solid rgba(2, 96, 105);
		background: rgba(2, 96, 105);
	  }.secondary-button {
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(50, 50, 50);
		border: 0.2vw solid rgba(240, 240, 240);
		background: rgba(240, 240, 240);
	  }.secondary-outline-button {
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(50, 50, 50);
		border: 0.2vw solid rgba(240, 240, 240);
		background: rgba(255, 255, 255);
	  }.undo-redo{
		  opacity:0.6;
		  cursor:not-allowed;
		  font-size:0.8vw;
		  padding:0.5vw 1vw;
	  }.undo-redo .bi{
		  -webkit-text-stroke: 0.02vw;
	  }.active-undo-redo{
		  opacity:1;
		  cursor:pointer;
	  }.template-title{
		background:rgba(10, 10, 10);
		border:none;
		text-align:center;
		width:100%;
		margin-left:1vw;
		color:rgba(255, 255, 255);
		outline:none;
		font-size:1vw;
		border-radius:0.4vw;
		font-weight:500;
		padding: 0.5vw 1.2vw;
	}.template-title:focus, .template-title:hover{
		background:rgba(75, 75, 75);
		color:rgba(255, 255, 255);
		font-weight:400;
	}.editor-area {
	  position: relative;
	  display: flex;
	  flex-direction: row;  /* Ensure the editor area takes a row layout */
	}
	
	.editor-container {
	  display: flex;
	  flex: 1;  /* Allow the container to expand to available width */
	  position: relative;
	}

  .left-section {
	  
	   position: sticky;
		top: 0;
	width: 22%;
	height:81vh;
	padding:1vw;
	overflow-y:hidden;
	position: sticky;
	top: 0;
	background-color: rgba(255, 255, 255);
	border-right:0.14vw solid rgba(240, 240, 240);
	transition: overflow-y 0.3s ease;
  }.left-section:hover {
	overflow-y: auto; /* Show scrollbar on hover */
  }.left-section::-webkit-scrollbar {
	width: 0.5vw; /* Set width of the scrollbar */
  }.left-section::-webkit-scrollbar-thumb {
	background-color: rgba(100, 100, 100, 0.7); /* Scrollbar thumb color */
	border-radius: 0.1vw; /* Round edges */
	border: 0.3vw solid transparent; /* Space between scrollbar and edge */
  }

  .left-section::-webkit-scrollbar-track {
	background-color: rgba(200, 200, 200, 0.5); /* Scrollbar track color */
	border-radius: 0.1vw;
  }


  .right-section {
	width: 78%;
	padding:1vw 5vw;
	height:79vh;
	overflow-y:scroll;
	background-color: rgba(245, 245, 245);
  }.semi-header{
	  background:rgba(255, 255, 255);
	  border-bottom:0.14vw solid rgba(240, 240, 240);
  }.back-button {
	  background:rgba(75, 75, 75);
	  border: 0.2vw solid rgba(75, 75, 75);
	  color:rgba(255, 255, 255);
  }.semi-header .tab{
	  margin-right:1vw;
	  color:rgba(10, 10, 10);
	  text-decoration:none;
	  font-size:0.8vw;
	  padding:2.4vw 1vw;
	  color:rgba(150, 150, 150);
	  padding-bottom:1vw;
	  display:inline-block;
	  border-bottom:0.15vw solid rgba(255, 255, 255);
  }.active-tab{
	  margin-right:1vw;
	  color:rgba(10, 10, 10);
	  text-decoration:none;
	  padding:0.5vw 1.5vw;
	  padding-bottom:1vw;
	  font-weight: 600;
	  font-size:0.8vw;
	  display:inline-block;
	  border-bottom:0.15vw solid rgba(10, 10, 10);
  }.semi-header button{
	  margin:1vw 0;
  }.element{
	  padding:1vw;
	  margin-top:1vw;
	  margin-bottom:1vw;
	  padding-bottom:0;
	  border:0.1vw solid rgba(240, 240, 240);
	  text-align:center;
	  border-radius:0.4vw;
  }.element-icon{
	  width:1.2vw;
	  height:1.2vw;
	  opacity:0.5;
	  margin-bottom:1vw;
	  object-fit:contain;
  }.layout-icon{
	  width: 100%;
	  height: 50px;
	  opacity: 0.5;
  }.element-title{
	  font-size:0.8vw;
	  font-weight: 600;
	  text-transform:uppercase;
	  margin-bottom:0;
  }.element .bi{
	  font-size:1.2vw;
	  color:rgba(150, 150, 150);
  }.canva-area{
	  background:rgba(255, 255, 255);
	  padding:10px;
	  margin:1vw 5vw;
	  width:600px;
	  min-height:300px;
  }.content-area .heading-area{
	  padding:0.2vw;
	  padding-bottom:1vw;
	  border-bottom:0.14vw solid rgba(240, 240, 240);
  }.content-heading{
	  font-size:1.2vw;
	  padding-top:1vw;
  }.content-button{
	  padding:0.2vw 0.5vw;
	  background:none;
	  font-size:1.1vw;
	  border-radius:0.3vw;
	  border:0.14vw solid rgba(240, 240, 240);
	  margin-left:0.8vw;
  }.delete-button{
	  background:rgba(231, 76, 60);
	  color:rgba(255, 255, 255);
	  border:0.14vw solid rgba(231, 76, 60);
  }.styling-area {
	  position: absolute;  /* Positioning it absolutely within the right-section */
	  top: 0;
	  overflow-y: auto;
	  right: 0;
	  width: 20vw;  /* Adjust the width according to your needs */
	  border-left: 0.2vw solid rgba(240, 240, 240);
	  height: 100%;  /* Match the height of the right-section */
	  background-color: rgba(255, 255, 255);  /* Optional: Add a transparent background */
	  z-index: 10;  /* Ensure it's on top of other content */
	  padding: 1vw 0;  /* Optional: Add padding inside the styling area */
	  box-sizing: border-box;  /* Ensure padding doesn't affect the layout */
	}.style-editor-heading{
	  font-size: 1vw;
	  border-bottom: 0.1vw solid rgba(230, 230, 230);
	  padding: 0.5vw 1vw;
	  padding-top: 20px;
	}.styling-area label{
	  font-size: 0.8vw;
	  display: block;
	  margin-top: 1vw;
	}.ediging-input-area{
	  padding: 0 2vw;
	}.styling-area input, .styling-area select{
	  font-size: 0.8vw;
	  width: 100%;
	  margin-bottom: 0.5vw;
	  padding: 0.2vw 0.5vw;
	  outline:none;
	  border: 0.1vw solid rgba(200, 200, 200);
	  border-radius: 0.2vw;
	}.styling-area button{
	  background-color: rgba(2, 96, 105);
	  border:0.1vw solid rgba(2, 96, 105);
	  color:rgba(255, 255, 255);
	  padding: 0.4vw 0.8vw;
	  margin: 1vw 0;
	  border-radius: 0.2vw;
	}.dropped-element {
	  transition: border 0.2s ease-in-out;
	}
	
	.element-controls button {
	  background: rgba(255, 255, 255, 0.9);
	  border: 1px solid #ccc;
	  border-radius: 5px;
	  padding: 3px 6px;
	  cursor: pointer;
	}
	
	.element-controls button:hover {
	  background: #f0f0f0;
	}
	
	.element-controls button:disabled {
	  cursor: not-allowed;
	  opacity: 0.5;
	}[contenteditable]:focus {
	  outline: none;
	}
	
	

}

