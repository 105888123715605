.sidebar-body{
	background:rgba(254, 254, 254);
}.dashboard-body{
	font-family: 'Poppins', sans-serif;
	.greeting{
		margin:1.2vw 1.5vw;
		font-size:1.2vw;
	}
	.top-area{
		margin:1.5vw 1.5vw;
		background: linear-gradient(to right, rgba(2,96,105), rgba(2,96,105, 0.8));
		padding:1.2vw;
		border-radius:1vw;
	}.total-balance-label{
		color:rgba(235, 235, 235, 0.8);
		font-size:1vw;
		margin-bottom:1vw;
	}.total-balance{
		font-size:1.2vw;
		color:rgba(255, 255, 255);
	}.total-balance span{
		font-size:1vw;
		color:rgba(91, 254, 161);
		margin-left:0.5vw;
	}.primary-button {
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(255, 255, 255);
		border: 0.2vw solid rgba(2, 96, 105);
		background: rgba(2, 96, 105);
	  }.secondary-button {
		border-radius: 0.4vw;
		font-size: 0.8vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(50, 50, 50);
		border: 0.2vw solid rgba(240, 240, 240);
		background: rgba(240, 240, 240);
	  }.secondary-outline-button {
		border-radius: 0.4vw;
		font-size: 1vw;
		font-weight: 500;
		padding: 0.5vw 1.2vw;
		color: rgba(50, 50, 50);
		border: 0.2vw solid rgba(240, 240, 240);
		background: rgba(255, 255, 255);
	  }.metric-col-area{
		  padding:0 1.5vw;
	  }.metric-col{
		  border:0.1vw solid rgba(240, 240, 240);
		  padding:1vw;
		  background:rgba(255, 255, 255);
		  border-radius:1vw;
	  }.metric-col-top-label{
		  font-size:1vw;
		  font-weight:400;
	  }.metric-col-top-label .bi{
		  margin-right:0.5vw;
		  color:rgba(2, 96, 105);
		 -webkit-text-stroke: 0.01vw;
	  }.metric-col-duration-label{
		  font-size:0.8vw;
		  text-align:right;
		  display:block;
		  color:rgba(150, 150, 150);
		  font-weight:400;
	  }.total-metric{
		  font-size:1.5vw;
		  margin:1vw 0;
		  display:block;
	  }.total-metric-bottom-label{
		  font-size:0.8vw;
		  color:rgba(150, 150, 150);
	  }.total-metric span{
		  font-size:0.8vw;
		  margin-left: 0.5vw;
		  font-weight:500;
	  }.table-area{
		  padding:2vw;
	  }table {
	  width: 100%;
	  border:0.1vw solid #ddd;
	  border-collapse: separate; /* Allows border-radius to work */
	  border-spacing: 0; /* Removes spacing between cells */
	  border-radius: 0.4vw; /* Rounds the corners of the table */
	  overflow: hidden; /* Ensures the border-radius applies to the table content */
  }

  th, td {
	  padding: 1.2vw;
	  text-align: left;
	  border-top: 0.1vw solid rgba(240, 240, 240);
	  border-radius: 0; /* No border-radius on cells */
  }

  thead {
	  background-color: rgba(150, 150, 150);
  }

  th {
	  background-color: rgba(240, 240, 240);
	  color: rgba(50, 50, 50);
	  font-size:1vw;
	  font-weight:500;
  }table .domain-email-name{
	  font-size:1vw;
  }table .memeber-email{
	  font-size:1vw;
	  color:rgba(125, 125, 125);
  }table td, table th{
	  font-size:0.9vw;
  }table .member-icon{
	  width:4.2vw;
	  height:4.2vw;
	  border-radius:50vw;
	  border:0.1vw solid rgba(240, 240, 240);
	  object-fit:cover;
  }.table-area-heading{
	  font-size:1.2vw;
	  margin-bottom:1.2vw;
  }.td-first , .td-first a{
	  font-size:0.8vw;
	  color:rgba(100, 100, 100);
	  font-weight:500;
  }.td-first a{
	  color:rgba(10, 10, 10);
  }.campaign-status{
	  background:rgba(240, 240, 240);
	  font-size:0.8vw;
	  padding:0.4vw 0.1vw;
	  border-radius:50vw;
	  font-weight:600;
	  display:block;
	  text-align:center;
	  color:rgba(50, 50, 50);
  }.scheduled-status{
	  background:rgba(254, 245, 231);
	  color:rgba(243, 156, 18);
  }.sent-status{
	  background:rgba(233, 247, 239);
	  color:rgba(30, 132, 73);
  }.archived-status{
	  background:rgba(253, 237, 236);
	  color:rgba(231, 76, 60);
  }.download-invoice{
	  font-size:1vw;
	  cursor:pointer;
  }.empty-error-image{
	width: 15vw;
	height: auto;
  }.empty-error-heading{
	font-size: 1.5vw;
	line-height: 2vw;
  }.empty-error-subheading{
	font-size: 1vw;
	line-height: 1.5vw;
  }
	
}.dashboard-steps-area{
	padding: 2vw 10vw;
	margin-top: 3vw;
  }.dashboard-steps-area-heading{
	font-weight: 500;
	text-align: center;
	font-size: 1.4vw;
	margin-bottom: 1vw;
  }.dashboard-steps-area-subheading{
	font-weight: 400;
	text-align: center;
	font-size: 1vw;
	line-height: 1.8vw;
	color:rgba(100, 100, 100);
  }.dashboard-steps-col{
	margin: 2vw 0;
	padding: 1.5vw 1vw;
	border-radius: 0.5vw;
	border:0.15vw solid rgba(235, 235, 235);
  }.dashboard-steps-col-icon{
	font-size: 2.8vw;
	color:rgb(233, 159, 0);
	padding: 1vw;
  }.dashboard-steps-col .col-7{
	padding-left:1vw;
  }.dashboard-steps-col-heading{
	font-size: 1.1vw;
	font-weight: 500;
  }.dashboard-steps-col-subheading{
	font-size: 0.85vw;
	line-height: 1.4vw;
	font-weight: 400;
	color:rgba(150, 150, 150);
  }.active-steps-right-not{
	background-color: rgba(255, 255, 255);
  }.pending{
	background-color: rgba(250, 250, 250);
	opacity: 0.8;
	cursor: not-allowed;
  }.pending .dashboard-steps-col-icon{
	color: rgba(150, 150, 150);
  }.completed .dashboard-steps-col-icon{
	color: rgb(2, 105, 45);
	opacity: 0.9;
  }.completed .dashboard-steps-col-heading{
	text-decoration: line-through;
	opacity: 0.6;
  }.primary-button-dashboard-steps {
	margin-top: 1vw;
	border-radius: 0.4vw;
	font-size: 0.8vw;
	font-weight: 500;
	padding: 0.5vw 1.2vw;
	color: rgba(255, 255, 255);
	border: 0.14vw solid rgba(2, 96, 105);
	background: rgba(2, 96, 105);
}