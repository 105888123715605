.sidebar-body{
	background:rgba(254, 254, 254);
}.create-whatsapp-template-body{
	font-family: 'Poppins', sans-serif;
	.breadcrumb-area{
        padding:30px 30px 0 30px;
    }.breadcrumb .breadcrumb-item a{
        text-decoration:none;
        font-weight:400;
        color:rgba(100, 100, 100);
    }.breadcrumb .active{
        font-weight:500;
        color:rgba(10, 10, 10);
    }.breadcrumb .breadcrumb-item a:hover{
        text-decoration:underline;
        color:rgba(20, 20, 20);
    }.header-area{
        background-color: rgba(254, 254, 254);
        box-shadow: 0 2px 4px rgba(216, 216, 216, 0.425);
        padding: 12px 20px;
        border-radius: 5px;
        margin-bottom: 25px;
    }.main-content-area{
        padding:30px 30px 0 30px;
        
    }.header-area .heading{
        font-size: 16px;
        padding-top: 5px;
        line-height: 30px;
        font-weight: 600;
        display: block;
        letter-spacing: 0.01rem;
    }.header-area i{
        background-color: rgb(0, 148, 86);
        color: rgba(255, 255, 255);
        text-align: center;
        padding: 15px 20px;
        font-size: 24px;
        border-radius: 5px;
    }.header-area .subheading{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(125, 125, 125, 1);
    }.header-area span, .subheading span, .input-label span{
        margin: 0 5px;
    }.header-area .input-label{
        font-size: 15px;
        font-weight: 400;
        color: rgb(27, 27, 27);
    }.form-input{
        width: 100%;
        padding: 6px 12px;
        font-size: 15px;
        margin: 10px 0;
        border-radius: 5px;
        outline: none;
        color: rgba(10, 10, 10);
        border: 1px solid rgb(223, 223, 223);
    }::placeholder {
        color: rgba(150, 150, 150);
        opacity: 1; /* Firefox */
      }
      
      ::-ms-input-placeholder { /* Edge 12 -18 */
        color: rgba(150, 150, 150);
      }.form-input:focus{
        color: rgba(10, 10, 10);
        border:1px solid rgb(2, 96, 105)
      }.chat-container {
        width: 360px;
        height: 600px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }
    .chat-header {
        background-color: #075e54;
        color: white;
        padding: 15px;
        font-size: 18px;
        text-align: center;
    }
    .chat-box {
        flex: 1;
        z-index:2;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-height: 400px;
        overflow-y: auto;
        background-image: url('https://www.aadow.com/images/whatsapp-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover
    }
    .chat-bubble {
        max-width: 78%;
        padding: 10px;
        border-radius: 10px;
        font-size: 14px;
        position: relative;
    }
    .sent {
        align-self: flex-end;
        background-color: #dcf8c6;
    }
    .received {
        align-self: flex-start;
        background-color: #fff;
    }
    .timestamp {
        font-size: 10px;
        color: gray;
        text-align: right;
        margin-top: 10px;
    }.preview-area .header-area{
        border-radius: 10px 10px 0 0;
    }.preview-area .footer-area{
        border-radius: 0px 0px 10px 10px;
    }.button-secondary-outline{
        font-size: 15px;
        padding: 8px 16px;
        color: rgba(100, 100, 100);
        border-radius: 5px;
        border:1px solid rgba(220, 220, 220);
        background-color: rgba(254, 254, 254);
        margin-right: 10px;
    }.button-primary{
        font-size: 15px;
        padding: 8px 16px;
        color: rgba(255, 255, 255);
        border-radius: 5px;
        border:1px solid rgba(2, 96, 105);
        background-color: rgba(2, 96, 105);
        margin-left: 10px;
    }.button-secondary-outline:hover{
        background-color: rgba(240, 240, 240, 1);
    }.button-primary:hover{
        opacity: 0.8;
    }.button-primary:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }.step-area{
        padding:30px 30px 0 30px;
    }.step-heading i{
        margin-right: 10px;
        font-size: 20px;
    }.step-heading{
        font-weight: 600;
        border-radius: 50px; 
        font-size: 18px;
    }.step-heading span{
        padding: 5px 12px;
        border-radius: 4px;
    }.active-step{
        color: rgb(223, 108, 0);
    }.category-tab{
        text-align: center;
        border:1px solid rgb(231, 231, 231);
        font-size: 16px;
        padding: 8px 16px;
    }.category-tab .bi{
        margin-right: 10px;
    }.category-tab:hover{
        cursor: pointer;
        background-color: rgba(240, 240, 240);
    }.active-tab, .active-tab:hover{
        color: rgba(254, 254, 254);
        cursor: default;
        background-color: rgba(2, 96, 108);
        border:1px solid rgb(2, 96, 108);
    }.subcategory-area .bi{
        font-size: 24px;
        padding-left: 5px;
        color: rgba(200, 200, 200);
    }.subcategory-area .heading{
        line-height: 20px;
    }.subcategory-area:hover{
        background-color: rgba(240, 240, 240);
        cursor: pointer;
    }.subcategory-area{
        padding: 8px 16px;
        border-radius: 5px;
        margin: 10px 0px;
    }.active-subcategory-area, .active-subcategory-area:hover{
        cursor: default;
        color: rgba(254, 254, 254);
        background-color: rgb(2, 96, 108);
    }/* Container */
.btn-container {
    padding: 16px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

/* Headings */
.btn-heading {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
}

.btn-subheading {
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
}

/* Add Button */
.add-btn-primary {
    background-color: #fff;
    color: #111;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background 0.3s;
}.form-input:disabled{
    background-color: #ebebeb;
}

.add-btn-primary:hover {
    background-color: #d1d1d1;
}

/* Dropdown Menu */
.btn-dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-dropdown-item:hover {
    background-color: #f0f2f5;
}

/* Button Row */
.btn-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
}

.btn-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Delete Button */
.btn-danger {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-danger:hover {
    background-color: #e63939;
}/* Disabled Item */
.btn-dropdown-item.disabled {
    color: #aaa;
    pointer-events: none; /* Prevent clicks */
    background-color: #f0f0f0;
    text-decoration: line-through;
}/* Container to hold the input and image preview */
.image-upload-container {
    position: relative;
    width: 200px;  /* Adjust the size according to your layout */
    height: 200px;
  }
  
  /* The placeholder image */
  .image-placeholder {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .placeholder-img {
    max-width: 80%;
    max-height: 80%;
  }
  
  /* Style for the uploaded image preview */
  .image-preview {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .remove-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .remove-image-btn:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .remove-icon {
    font-size: 20px;
  }.template-status{
    background-color: #ffc400;
    padding: 8px 20px;
    color: #111;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
  }.REJECTED{
    background-color: #e63939;
    color: #fff;
  }.APPROVED{
    background-color: #009238;
    color: #fff;
  }button:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
    
}