.account-recovery-body{
	margin: 0;
	padding: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding:1vw 0.5vw;

  .container-fluid {
	  
	font-family: 'Poppins', sans-serif;
	width: 100%;
	height: 100%;
	display: flex;
  }

  .row {
	width: 100%;
	height: 100%;
	display: flex;
  }


  .left-area {
	background-size: cover;
	background-position: center;
	height: 100vh;
	display: flex;
	background-image: linear-gradient(to right, rgba(0,49,53), rgba(2,73,80));
	background-image: url('https://www.aadow.com/images/auth-hero.jpg');
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding:3vw 3vw;
	border-radius:1vw;
  }
  .right-area {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding:3vw 6vw;
	padding-right:10vw;
	border-radius:1vw;
  }

  .top-section {
	flex: 0;
	display: flex;
	align-items: center;
	height: 20%;
  }

  .middle-section {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align:left;
  }

  .right-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align:left;
  }

  .bottom-section {
	flex: 0;
	display: flex;
	align-items: center;
	height: 20%;
	margin-top: auto;
  }

  .logo {
	width: 120px;
	height: auto;
  }.main-heading{
	  color:rgb(255, 255, 255);
	  font-size: 30px;
  }.main-description{
	  color:rgb(230, 230, 230);
	  padding-right: 10vw;
	  font-size: 22px; /* Min 1rem, scales with 1.2vw, max 1.5rem */
      line-height: 30px;
	  font-weight:400;
	  letter-spacing: 0.01vw;
  }.footer-description{
	  font-size: 16px;
      line-height: 24px;
	  font-weight:500;
	  letter-spacing: 0.02vw;
	  color:rgb(200, 200, 200);
  }.main-link {
    background: rgb(255, 255, 255);
    padding: 0.4rem 1rem; /* Use rem for consistency */
    border-radius: 0.4rem; /* More consistent scaling */
    color: rgba(0, 49, 53);
    font-size: clamp(0.8rem, 1.2vw, 1.5rem); /* Scales with viewport */
    text-decoration: none;
    margin-top: 1rem; /* Matches the spacing scale */
    display: inline-block;
  }.secondary-heading {
    font-size: 24px; /* Responsive scaling */
    font-weight: 500;
    line-height: 36px; /* Proportional to font size */
  }.secondary-description {
    font-size: 18px; /* Smaller scale for description */
    color: rgb(100, 100, 100);
    font-weight: 400;
    line-height: 24px;
  }a{
	  cursor:pointer;
  }.social-login {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  color: rgb(50, 50, 50);
	  margin-top:1vw;
	  border: none;
	  background:rgb(255, 255, 255);
	  border: 0.1vw solid rgb(220, 220, 220);
	  border-radius: 0.4vw;
	  padding: 0.5vw 1.2vw;
	  font-size: 1.1vw;
	  font-weight:500;
	  cursor: pointer;
	  outline: none;
  }

  .social-login-icon {
	  width: 1.2vw;
	  height: 1.2vw;
	  margin-right: 0.8vw;
	  vertical-align: middle;
  }.text-with-lines {
    margin: clamp(0.4rem, 0.5vw, 1rem) 0; /* Maintain vertical spacing */
    display: flex;
    align-items: center;
    text-align: center;
  }.text-with-lines::before,
  .text-with-lines::after {
    content: "";
    flex: 1;
    border-top: 0.1rem solid rgb(220, 220, 220); /* Use rem for more precise control */
  }.text-with-lines span {
    font-size: 18px; /* Limit text scaling */
    color: rgb(100, 100, 100);
    padding: 0 clamp(0.5rem, 1vw, 2rem);
  }label{
	  font-size: 16px;
	  font-weight:500;
	  display:block;
	  letter-spacing: 0.03rem;
	  color:rgb(75, 75, 75);
  }.input-form {
    display: block;
    width: 100%;
    color: rgb(50, 50, 50);
    margin-top: clamp(0.4rem, 0.5vw, 1rem);
    border: none;
    background: rgb(255, 255, 255);
    border: 0.14rem solid rgb(220, 220, 220);
    border-radius: 0.4rem;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
  }.primary-button {
	
    margin-top: clamp(0.4rem, 0.5vw, 1rem);
    border-radius: clamp(0.3rem, 0.4vw, 0.6rem);
    font-size: 16px;
    font-weight: 500;
    padding: 8px 8px;
    color: rgba(255, 255, 255);
    border: clamp(0.1rem, 0.2vw, 0.3em) solid rgba(2, 96, 105); /* Smaller border width */
    background: rgba(2, 96, 105);
  }.secondary-button {
    width: 100%;
    margin-top: clamp(0.4rem, 0.5vw, 1rem);
    border-radius: clamp(0.3rem, 0.4vw, 0.6rem);
    font-size: 16px;
    font-weight: 500;
    padding: 8px 8px;
    color: rgba(50, 50, 50);
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(230, 230, 230);
    background: rgba(230, 230, 230);
}form .col-12{
	  margin-bottom:1vw;
  }.input-form:focus{
	  border:0.14rem solid rgba(2,96,105);
  }::-ms-input-placeholder { /* Edge 12-18 */
	color: rgba(200, 200, 200);
  }

  ::placeholder {
	color: rgba(200, 200, 200);
  }.login-signup-link {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgb(100, 100, 100);
  }.login-signup-link a {
    color: rgba(2, 96, 105);
    font-weight: 500;
  }.forgot-password-link a {
    text-align: right;
    display: block;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: rgba(2, 96, 105);
  }.password-container {
	position: relative;
  }

  .password-icon {
    position: absolute;
    top: 50%;
    right: clamp(0.8rem, 1vw, 1.5rem);
    color: rgba(100, 100, 100);
    font-size: 18px;
    transform: translateY(-50%);
    cursor: pointer;
  }button:hover, a:hover{
	  opacity:0.8;
  }.otp-container {
	display: flex;
	margin-top:1vw;
	justify-content: space-between;
	gap: 0.5rem;
	margin-bottom: 1rem;
  }

  .otp-input {
    width: 100%;
    text-align: center;
    padding: 15px 10px;
    font-size: 20px; /* Min, scalable, max */
    font-weight: 600;
    outline: none;
    background: rgb(255, 255, 255);
    border: 0.14rem solid rgb(220, 220, 220);
    border-radius: 0.4rem;
	}

	.otp-input:focus {
		border: 0.14rem solid rgba(245, 245, 245);
		background: rgb(245, 245, 245);
	}.secondary-button:disabled {
		cursor: not-allowed;
		opacity: 0.8;
		color: rgba(50, 50, 50);
		border: 0.14rem solid rgba(200, 200, 200);
		background: rgba(255, 255, 255);
	}
	.success-icon {
		width: 100%;
		height: 100px; /* Min, scalable, max */
		margin-bottom: 25px;
		object-fit: contain;
	}.input-error,
	.input-error:focus {
		border: 0.14rem solid rgb(231, 76, 60);
		color: rgba(231, 76, 60);
	}
	
	.otp-error,
	.otp-error:focus {
		background: rgba(253, 237, 236);
		border: 0.14rem solid rgb(253, 237, 236);
		color: rgba(231, 76, 60);
	}
	.error-message {
		font-size: 16px; /* Min, scalable, max */
		font-weight: 500;
		margin-top: clamp(0.4rem, 0.5vw, 1rem);
		margin-bottom: clamp(0.8rem, 1.5vw, 2rem);
		color: rgba(231, 76, 60);
	}.primary-button:disabled{
	  opacity:0.6;
	  cursor:not-allowed;
  }.change-email {
    color: rgba(2, 96, 105);
	font-size:16px;
	margin-left:5px;
}.otp-error, .otp-error:focus{
	  background:rgba(253, 237, 236);
	  border: 0.2vw solid rgb(253, 237, 236);
	  color:rgba(231, 76, 60);
  }.terms-policy{
    font-size: 16px;
	font-weight:400;
	line-height:24px;
	letter-spacing:0.01vw;
}.terms-policy a{
	color:rgba(2,96,105);
	text-decoration:none;
	font-weight:500;
	
}
  
}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.account-recovery-body{
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  padding:1vw 0.5vw;
	  margin-top:10vw;


	.left-area {
	  display: none;
	}
	.right-area {
	  display: block;
	  width: 100%;
	  padding:1vw 5vw;
	}


	.right-content {
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  align-items: left;
	  text-align:left;
	}


	.secondary-heading{
		font-size:5vw;
		font-weight:500;
		line-height:7vw;
	}.secondary-description{
		font-size:3.5vw;
		color: rgb(100, 100, 100);
		font-weight:400;
		line-height:5vw;
	}a{
		cursor:pointer;
	}.social-login {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgb(50, 50, 50);
		margin-top:2.5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 2vw 1.2vw;
		font-size: 3.5vw;
		font-weight:500;
		cursor: pointer;
		outline: none;
	}

	.social-login-icon {
		width: 4.5vw;
		height: 4.5vw;
		margin-right: 2vw;
		vertical-align: middle;
	}.text-with-lines {
		margin:2vw 0;
		display: flex;
		align-items: center;
		text-align: center;
	}

	.text-with-lines::before,
	.text-with-lines::after {
		content: "";
		flex: 1;
		border-top: 0.1vw solid rgb(220, 220, 220);
	}

	.text-with-lines span {
		font-size: 3vw;
		color: rgb(100, 100, 100);
		padding:0 2vw;
	}label{
		font-size:3.5vw;
		font-weight:500;
		display:block;
		color:rgb(75, 75, 75);
	}.input-form{
		display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
	}.primary-button{
		margin-top:1vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
	}form .col-12{
		margin-bottom:1vw;
	}.input-form:focus{
		border:0.4vw solid rgba(2,96,105);
	}::-ms-input-placeholder { /* Edge 12-18 */
	  color: rgba(200, 200, 200);
	}

	::placeholder {
	  color: rgba(200, 200, 200);
	}.login-signup-link{
		text-align:center;
		font-size:4vw;
		font-weight:400;
		margin-top:4vw;
		color:rgb(100, 100, 100);
	}.login-signup-link a{
		color:rgba(2,96,105);
		font-weight:500;
	}.forgot-password-link a{
		text-align:right;
		display:block;
		cursor:pointer;
		text-decoration:none;
		font-weight:500;
		font-size:4vw;
		color:rgba(2,96,105);
	}.password-container {
	  position: relative;
	}

	.password-icon {
	  position: absolute;
	  top: 54%;
	  right: 2.5vw;
	  color:rgba(100, 100, 100);
	  font-size:5vw;
	  transform: translateY(-50%);
	  cursor: pointer;
	}button:hover, a:hover{
		opacity:0.8;
	}.secondary-button{
		width:100%;
		margin-top:4vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(230,230,230);
		background:rgba(230,230,230);
	}.otp-container {
	  display: flex;
	  margin-top:1vw;
	  justify-content: space-between;
	  gap: 0.5rem;
	  padding-bottom: 5vw;
	}

	.otp-input {
	  width:100%;
	  text-align: center;
	  padding:0.8vw 0;
	  font-size: 8vw;
	  font-weight: 600;
	  outline:none;
	  background:rgb(255, 255, 255);
	  border: 0.4vw solid rgb(220, 220, 220);
	  border-radius: 0.8vw;
	}.otp-input:focus{
		border:0.2vw solid rgba(245,245,245);
		background: rgb(245, 245, 245);
	}.secondary-button:disabled{
		cursor:not-allowed;
		opacity:0.8;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(200,200,200);
		background:rgba(255,255,255);
	}.success-icon{
		width:100%;
		height:25vw;
		margin-bottom:5vw;
		object-fit:contain;
	}.terms-policy{
		font-size:3vw;
		font-weight:400;
		line-height:5vw;
		letter-spacing:0.01vw;
	}.terms-policy a{
		color:rgba(2,96,105);
		text-decoration:none;
		font-weight:500;
		
	}.secondary-description a{
		color:rgba(2,96,105);
		font-weight:500;
	}.input-error, .input-error:focus{
		border: 0.4vw solid rgb(231, 76, 60);
		color: rgba(231, 76, 60);
	}.error-message{
		font-size:4vw;
		font-weight:500;
		margin-top:-3vw;
		margin-bottom:3vw;
		color: rgba(231, 76, 60);
	}.primary-button:disabled{
		opacity:0.6;
		cursor:not-allowed;
	}.change-email{
		color: rgba(2,96,105);
		font-size:3.5vw;
		margin-left:0.2vw;
	}.otp-error, .otp-error:focus{
		background:rgba(253, 237, 236);
	    border: 0.4vw solid rgb(253, 237, 236);
		color:rgba(231, 76, 60);
	}
	}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    .account-recovery-body{
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  padding:1vw 0.5vw;
	  margin-top:10vw;


	.left-area {
	  display: none;
	}
	.right-area {
	  display: block;
	  width: 100%;
	  padding:1vw 5vw;
	}


	.right-content {
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  align-items: left;
	  text-align:left;
	}


	.secondary-heading{
		font-size:5vw;
		font-weight:500;
		line-height:7vw;
	}.secondary-description{
		font-size:3.5vw;
		color: rgb(100, 100, 100);
		font-weight:400;
		line-height:5vw;
	}a{
		cursor:pointer;
	}.social-login {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgb(50, 50, 50);
		margin-top:2.5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 2vw 1.2vw;
		font-size: 3.5vw;
		font-weight:500;
		cursor: pointer;
		outline: none;
	}

	.social-login-icon {
		width: 4.5vw;
		height: 4.5vw;
		margin-right: 2vw;
		vertical-align: middle;
	}.text-with-lines {
		margin:2vw 0;
		display: flex;
		align-items: center;
		text-align: center;
	}

	.text-with-lines::before,
	.text-with-lines::after {
		content: "";
		flex: 1;
		border-top: 0.1vw solid rgb(220, 220, 220);
	}

	.text-with-lines span {
		font-size: 3vw;
		color: rgb(100, 100, 100);
		padding:0 2vw;
	}label{
		font-size:3.5vw;
		font-weight:500;
		display:block;
		color:rgb(75, 75, 75);
	}.input-form{
		display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
	}.primary-button{
		margin-top:1vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
	}form .col-12{
		margin-bottom:1vw;
	}.input-form:focus{
		border:0.4vw solid rgba(2,96,105);
	}::-ms-input-placeholder { /* Edge 12-18 */
	  color: rgba(200, 200, 200);
	}

	::placeholder {
	  color: rgba(200, 200, 200);
	}.login-signup-link{
		text-align:center;
		font-size:4vw;
		font-weight:400;
		margin-top:4vw;
		color:rgb(100, 100, 100);
	}.login-signup-link a{
		color:rgba(2,96,105);
		font-weight:500;
	}.forgot-password-link a{
		text-align:right;
		display:block;
		cursor:pointer;
		text-decoration:none;
		font-weight:500;
		font-size:4vw;
		color:rgba(2,96,105);
	}.password-container {
	  position: relative;
	}

	.password-icon {
	  position: absolute;
	  top: 54%;
	  right: 2.5vw;
	  color:rgba(100, 100, 100);
	  font-size:5vw;
	  transform: translateY(-50%);
	  cursor: pointer;
	}button:hover, a:hover{
		opacity:0.8;
	}.secondary-button{
		width:100%;
		margin-top:4vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(230,230,230);
		background:rgba(230,230,230);
	}.otp-container {
	  display: flex;
	  margin-top:1vw;
	  justify-content: space-between;
	  gap: 0.5rem;
	  padding-bottom: 5vw;
	}

	.otp-input {
	  width:100%;
	  text-align: center;
	  padding:0.8vw 0;
	  font-size: 8vw;
	  font-weight: 600;
	  outline:none;
	  background:rgb(255, 255, 255);
	  border: 0.4vw solid rgb(220, 220, 220);
	  border-radius: 0.8vw;
	}.otp-input:focus{
		border:0.2vw solid rgba(245,245,245);
		background: rgb(245, 245, 245);
	}.secondary-button:disabled{
		cursor:not-allowed;
		opacity:0.8;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(200,200,200);
		background:rgba(255,255,255);
	}.success-icon{
		width:100%;
		height:25vw;
		margin-bottom:5vw;
		object-fit:contain;
	}.terms-policy{
		font-size:3vw;
		font-weight:400;
		line-height:5vw;
		letter-spacing:0.01vw;
	}.terms-policy a{
		color:rgba(2,96,105);
		text-decoration:none;
		font-weight:500;
		
	}.secondary-description a{
		color:rgba(2,96,105);
		font-weight:500;
	}.input-error, .input-error:focus{
		border: 0.4vw solid rgb(231, 76, 60);
		color: rgba(231, 76, 60);
	}.error-message{
		font-size:4vw;
		font-weight:500;
		margin-top:-3vw;
		margin-bottom:3vw;
		color: rgba(231, 76, 60);
	}.primary-button:disabled{
		opacity:0.6;
		cursor:not-allowed;
	}.change-email{
		color: rgba(2,96,105);
		font-size:3.5vw;
		margin-left:0.2vw;
	}.otp-error, .otp-error:focus{
		background:rgba(253, 237, 236);
	    border: 0.4vw solid rgb(253, 237, 236);
		color:rgba(231, 76, 60);
	}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.account-recovery-body{
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  padding:1vw 0.5vw;
	  margin-top:10vw;


	.left-area {
	  display: none;
	}
	.right-area {
	  display: block;
	  width: 100%;
	  padding:1vw 5vw;
	}


	.right-content {
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  align-items: left;
	  text-align:left;
	}


	.secondary-heading{
		font-size:5vw;
		font-weight:500;
		line-height:7vw;
	}.secondary-description{
		font-size:3.5vw;
		color: rgb(100, 100, 100);
		font-weight:400;
		line-height:5vw;
	}a{
		cursor:pointer;
	}.social-login {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgb(50, 50, 50);
		margin-top:2.5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 2vw 1.2vw;
		font-size: 3.5vw;
		font-weight:500;
		cursor: pointer;
		outline: none;
	}

	.social-login-icon {
		width: 4.5vw;
		height: 4.5vw;
		margin-right: 2vw;
		vertical-align: middle;
	}.text-with-lines {
		margin:2vw 0;
		display: flex;
		align-items: center;
		text-align: center;
	}

	.text-with-lines::before,
	.text-with-lines::after {
		content: "";
		flex: 1;
		border-top: 0.1vw solid rgb(220, 220, 220);
	}

	.text-with-lines span {
		font-size: 3vw;
		color: rgb(100, 100, 100);
		padding:0 2vw;
	}label{
		font-size:3.5vw;
		font-weight:500;
		display:block;
		color:rgb(75, 75, 75);
	}.input-form{
		display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
	}.primary-button{
		margin-top:1vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
	}form .col-12{
		margin-bottom:1vw;
	}.input-form:focus{
		border:0.4vw solid rgba(2,96,105);
	}::-ms-input-placeholder { /* Edge 12-18 */
	  color: rgba(200, 200, 200);
	}

	::placeholder {
	  color: rgba(200, 200, 200);
	}.login-signup-link{
		text-align:center;
		font-size:4vw;
		font-weight:400;
		margin-top:4vw;
		color:rgb(100, 100, 100);
	}.login-signup-link a{
		color:rgba(2,96,105);
		font-weight:500;
	}.forgot-password-link a{
		text-align:right;
		display:block;
		cursor:pointer;
		text-decoration:none;
		font-weight:500;
		font-size:4vw;
		color:rgba(2,96,105);
	}.password-container {
	  position: relative;
	}

	.password-icon {
	  position: absolute;
	  top: 54%;
	  right: 2.5vw;
	  color:rgba(100, 100, 100);
	  font-size:5vw;
	  transform: translateY(-50%);
	  cursor: pointer;
	}button:hover, a:hover{
		opacity:0.8;
	}.secondary-button{
		width:100%;
		margin-top:4vw;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(230,230,230);
		background:rgba(230,230,230);
	}.otp-container {
	  display: flex;
	  margin-top:1vw;
	  justify-content: space-between;
	  gap: 0.5rem;
	  padding-bottom: 5vw;
	}

	.otp-input {
	  width:100%;
	  text-align: center;
	  padding:0.8vw 0;
	  font-size: 8vw;
	  font-weight: 600;
	  outline:none;
	  background:rgb(255, 255, 255);
	  border: 0.4vw solid rgb(220, 220, 220);
	  border-radius: 0.8vw;
	}.otp-input:focus{
		border:0.2vw solid rgba(245,245,245);
		background: rgb(245, 245, 245);
	}.secondary-button:disabled{
		cursor:not-allowed;
		opacity:0.8;
		color:rgba(50, 50, 50);
		border:0.4vw solid rgba(200,200,200);
		background:rgba(255,255,255);
	}.success-icon{
		width:100%;
		height:25vw;
		margin-bottom:5vw;
		object-fit:contain;
	}.terms-policy{
		font-size:3vw;
		font-weight:400;
		line-height:5vw;
		letter-spacing:0.01vw;
	}.terms-policy a{
		color:rgba(2,96,105);
		text-decoration:none;
		font-weight:500;
		
	}.secondary-description a{
		color:rgba(2,96,105);
		font-weight:500;
	}.input-error, .input-error:focus{
		border: 0.4vw solid rgb(231, 76, 60);
		color: rgba(231, 76, 60);
	}.error-message{
		font-size:4vw;
		font-weight:500;
		margin-top:-3vw;
		margin-bottom:3vw;
		color: rgba(231, 76, 60);
	}.primary-button:disabled{
		opacity:0.6;
		cursor:not-allowed;
	}.change-email{
		color: rgba(2,96,105);
		font-size:3.5vw;
		margin-left:0.2vw;
	}.otp-error, .otp-error:focus{
		background:rgba(253, 237, 236);
	    border: 0.4vw solid rgb(253, 237, 236);
		color:rgba(231, 76, 60);
	}
	}
}
