
body{
	background:rgba(240, 240, 240);
}.large-nav{
	display:block;
}.sm-nav{
	display:none;
}
.navbar-body{
	overflow-x:hidden;
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255);
  border-bottom:0.1vw solid rgba(230, 230, 230);
  .top-body{
	  padding:1.5vw 5vw;
  }.top-body .logo{
	  width:6vw;
	  height:auto;
  }.top-body .nav-link{
	  font-size:1vw;
	  display:inline-block;
	  margin:0 1vw;
	  color:rgba(1,1,1);
	  padding:0 0.1vw;
	  font-weight:500;
	  border-bottom:0.2vw solid rgba(255, 255, 255);
  }.nav-link:hover{
	  color: rgba(2,96,105);
	  border-bottom:0.2vw solid rgba(2,96,105);
  }.nav-link .bi{
		padding-left:0.2vw;
		font-size:0.8vw;
		-webkit-text-stroke: 0.04vw;
		color:rgba(100, 100, 100);
	}.nav-link-signup{
	  font-size:1vw;
	  display:inline-block;
	  margin:0 1vw;
	  font-weight:500;
	  text-decoration:none;
	  color:rgba(1,1,1);
	  text-decoration:none;
	  background:rgba(2, 98, 105);
	  color:rgba(255, 255, 255);
	  padding:0.5vw 1.5vw;
	  border-radius:0.5vw;
  }.nav-link-signup .bi{
	  color:rgba(255, 255, 255);
  }
}.navbar-accordion-body{
	background:rgba(254, 254, 254);
	padding:2vw 4vw;
	border-bottom:0.1vw solid rgba(230, 230, 230);
	label{
		font-size:1.25vw;
		display:block;
		margin-bottom:0.1vw;
		padding:0.1vw 1vw;
		font-weight:500;
	}.bullet-links-icon{
		font-size:1.2vw;
		background:rgba(255, 255, 255);
		border:0.15vw solid rgba(240, 240, 240);
		border-radius:0.5vw;
		padding:0.4vw 0.8vw;
	}.bullet-links-heading{
		font-size:1vw;
		padding-left:0.5vw;
	}.bullet-links-subheading{
		font-size:0.8vw;
		padding-left:0.5vw;
		font-weight:500;
		color:rgba(150, 150, 150);
	}.bullet-links{
		margin:0.8vw 0;
		padding:0.1vw 1vw;
		padding-top:1.2vw;
		border-radius:0.5vw;
		border:0.1vw solid rgba(255, 255, 255);
	}.bullet-links:hover{
		cursor:pointer;
		border:0.1vw solid rgba(200, 200, 200);
		background:rgba(250, 250, 250);
	}.schedule-call-area{
		margin:0 2vw;
		padding:2vw 1.5vw;
		border-radius:1vw;
		background:rgba(245, 245, 245);
	}.schedule-call-area-heading{
		font-size:1vw;
		font-weight:600;
	}.schedule-call-area-subheading{
		font-size:0.8vw;
		color:rgba(125, 125, 125);
	}.Schedule-call-bullet .bi{
		margin-right:0.5vw;
		color:rgba(2,96,105);
		-webkit-text-stroke: 0.04vw;
	}.Schedule-call-bullet{
		font-size:0.8vw;
		font-weight:500;
		margin:1vw 0;
		color:rgba(100, 100, 100);
	}.schedule-call-bullets{
		margin-top:2vw;
	}.book-call{
		background:rgba(2,96,105);
		color:rgba(255, 255, 255);
		border:None;
		font-weight:500;
		margin-top:0.8vw;
		border-radius:0.5vw;
		padding:0.5vw 1vw;
	}.schedule-image{
		width:3vw;
		height:3vw;
		object-fit:cover;
		border-radius:50%;
		margin-right:-0.5vw;
		border:0.2vw solid rgba(200, 200, 200);
	}.schedule-image-area{
		padding-bottom:1vw;
	}
}


/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
body{
		background:rgba(240, 240, 240);
	}.large-nav{
		display:none;
	}.sm-nav{
		display:block;
	}
	.navbar-body{
		overflow-x:hidden;
	  font-family: "Poppins", sans-serif;
	  background-color: rgba(255, 255, 255);
	  border-bottom:0.1vw solid rgba(230, 230, 230);
	  .top-body{
		  padding:5.5vw 5vw;
	  }.top-body .logo{
		  width:35vw;
		  height:auto;
	  }.top-body .nav-link{
		  font-size:4vw;
		  display:inline-block;
		  margin:0 1vw;
		  color:rgba(1,1,1);
		  padding:0 0.1vw;
		  font-weight:500;
		  border-bottom:0.2vw solid rgba(255, 255, 255);
	  }.nav-link:hover{
		  color: rgba(2,96,105);
		  border-bottom:0.2vw solid rgba(2,96,105);
	  }.nav-link .bi{
			padding-left:0.2vw;
			font-size:1vw;
			-webkit-text-stroke: 0.04vw;
			color:rgba(100, 100, 100);
		}.nav-link-signup{
		  font-size:5.2vw;
		  display:inline-block;
		  margin:0 1vw;
		  margin-left:2vw;
		  font-weight:500;
		  text-decoration:none;
		  color:rgba(1,1,1);
		  text-decoration:none;
		  background:rgba(2, 98, 105);
		  color:rgba(255, 255, 255);
		  padding:0.5vw 2.5vw;
		  border-radius:0.5vw;
	  }.nav-link-signup .bi{
		  color:rgba(255, 255, 255);
	  }
	}.navbar-accordion-body{
		background:rgba(254, 254, 254);
		padding:2vw 4vw;
		label{
			font-size:4.25vw;
			display:block;
			margin-bottom:5vw;
			padding:0.1vw 0vw;
			font-weight:500;
		}.bullet-links-icon{
			font-size:5vw;
			background:rgba(255, 255, 255);
			border:0.15vw solid rgba(240, 240, 240);
			border-radius:0.5vw;
			padding:1vw 2vw;
		}.bullet-links-heading{
			font-size:4.2vw;
			padding-left:0.5vw;
		}.bullet-links-subheading{
			font-size:3.5vw;
			padding-left:0.5vw;
			font-weight:500;
			color:rgba(150, 150, 150);
		}.bullet-links{
			margin:0.8vw 0;
			padding:0.1vw 1vw;
			padding-top:5vw;
			border-radius:1vw;
			border:0.1vw solid rgba(255, 255, 255);
		}.bullet-links:hover{
			cursor:pointer;
			border:0.1vw solid rgba(200, 200, 200);
			background:rgba(250, 250, 250);
		}.schedule-call-area{
			margin:5vw 0vw;
			padding:4vw 6vw;
			border-radius:5vw;
			background:rgba(245, 245, 245);
		}.schedule-call-area-heading{
			font-size:4.25vw;
			margin-top:2vw;
			font-weight:600;
		}.schedule-call-area-subheading{
			font-size:3vw;
			color:rgba(125, 125, 125);
		}.Schedule-call-bullet .bi{
			margin-right:0.5vw;
			color:rgba(2,96,105);
			-webkit-text-stroke: 0.04vw;
		}.Schedule-call-bullet{
			font-size:3.5vw;
			font-weight:500;
			margin:4vw 0;
			color:rgba(100, 100, 100);
		}.schedule-call-bullets{
			margin-top:4vw;
		}.book-call{
			background:rgba(2,96,105);
			color:rgba(255, 255, 255);
			border:None;
			font-weight:500;
			margin:2vw 0;
			border-radius:1vw;
			padding:1.5vw 3vw;
		}.schedule-image{
			width:10vw;
			height:10vw;
			object-fit:cover;
			border-radius:50%;
			margin-right:-0.5vw;
			border:0.2vw solid rgba(200, 200, 200);
		}.schedule-image-area{
			padding:2vw 0;
		}.accordion-nav-link{
			font-size:5vw;
			padding:2vw 1vw;
			display:block;
			font-weight:500;
		}.accordion-nav-link .bi{
			float:right;
		}.accordion-content{
			padding:2vw 1vw;
		}
	}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
	body{
		background:rgba(240, 240, 240);
	}.large-nav{
		display:none;
	}.sm-nav{
		display:block;
	}
	.navbar-body{
		overflow-x:hidden;
	  font-family: "Poppins", sans-serif;
	  background-color: rgba(255, 255, 255);
	  border-bottom:0.1vw solid rgba(230, 230, 230);
	  .top-body{
		  padding:5.5vw 5vw;
	  }.top-body .logo{
		  width:35vw;
		  height:auto;
	  }.top-body .nav-link{
		  font-size:4vw;
		  display:inline-block;
		  margin:0 1vw;
		  color:rgba(1,1,1);
		  padding:0 0.1vw;
		  font-weight:500;
		  border-bottom:0.2vw solid rgba(255, 255, 255);
	  }.nav-link:hover{
		  color: rgba(2,96,105);
		  border-bottom:0.2vw solid rgba(2,96,105);
	  }.nav-link .bi{
			padding-left:0.2vw;
			font-size:1vw;
			-webkit-text-stroke: 0.04vw;
			color:rgba(100, 100, 100);
		}.nav-link-signup{
		  font-size:5.2vw;
		  display:inline-block;
		  margin:0 1vw;
		  margin-left:2vw;
		  font-weight:500;
		  text-decoration:none;
		  color:rgba(1,1,1);
		  text-decoration:none;
		  background:rgba(2, 98, 105);
		  color:rgba(255, 255, 255);
		  padding:0.5vw 2.5vw;
		  border-radius:0.5vw;
	  }.nav-link-signup .bi{
		  color:rgba(255, 255, 255);
	  }
	}.navbar-accordion-body{
		background:rgba(254, 254, 254);
		padding:2vw 4vw;
		label{
			font-size:4.25vw;
			display:block;
			margin-bottom:5vw;
			padding:0.1vw 0vw;
			font-weight:500;
		}.bullet-links-icon{
			font-size:5vw;
			background:rgba(255, 255, 255);
			border:0.15vw solid rgba(240, 240, 240);
			border-radius:0.5vw;
			padding:1vw 2vw;
		}.bullet-links-heading{
			font-size:4.2vw;
			padding-left:0.5vw;
		}.bullet-links-subheading{
			font-size:3.5vw;
			padding-left:0.5vw;
			font-weight:500;
			color:rgba(150, 150, 150);
		}.bullet-links{
			margin:0.8vw 0;
			padding:0.1vw 1vw;
			padding-top:5vw;
			border-radius:1vw;
			border:0.1vw solid rgba(255, 255, 255);
		}.bullet-links:hover{
			cursor:pointer;
			border:0.1vw solid rgba(200, 200, 200);
			background:rgba(250, 250, 250);
		}.schedule-call-area{
			margin:5vw 0vw;
			padding:4vw 6vw;
			border-radius:5vw;
			background:rgba(245, 245, 245);
		}.schedule-call-area-heading{
			font-size:4.25vw;
			margin-top:2vw;
			font-weight:600;
		}.schedule-call-area-subheading{
			font-size:3vw;
			color:rgba(125, 125, 125);
		}.Schedule-call-bullet .bi{
			margin-right:0.5vw;
			color:rgba(2,96,105);
			-webkit-text-stroke: 0.04vw;
		}.Schedule-call-bullet{
			font-size:3.5vw;
			font-weight:500;
			margin:4vw 0;
			color:rgba(100, 100, 100);
		}.schedule-call-bullets{
			margin-top:4vw;
		}.book-call{
			background:rgba(2,96,105);
			color:rgba(255, 255, 255);
			border:None;
			font-weight:500;
			margin:2vw 0;
			border-radius:1vw;
			padding:1.5vw 3vw;
		}.schedule-image{
			width:10vw;
			height:10vw;
			object-fit:cover;
			border-radius:50%;
			margin-right:-0.5vw;
			border:0.2vw solid rgba(200, 200, 200);
		}.schedule-image-area{
			padding:2vw 0;
		}.accordion-nav-link{
			font-size:5vw;
			padding:2vw 1vw;
			font-weight:500;
			display:block;
		}.accordion-nav-link .bi{
			float:right;
		}.accordion-content{
			padding:2vw 1vw;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	
	body{
		background:rgba(240, 240, 240);
	}.large-nav{
		display:none;
	}.sm-nav{
		display:block;
	}
	.navbar-body{
		overflow-x:hidden;
	  font-family: "Poppins", sans-serif;
	  background-color: rgba(255, 255, 255);
	  border-bottom:0.1vw solid rgba(230, 230, 230);
	  .top-body{
		  padding:5.5vw 5vw;
	  }.top-body .logo{
		  width:35vw;
		  height:auto;
	  }.top-body .nav-link{
		  font-size:4vw;
		  display:inline-block;
		  margin:0 1vw;
		  color:rgba(1,1,1);
		  padding:0 0.1vw;
		  font-weight:500;
		  border-bottom:0.2vw solid rgba(255, 255, 255);
	  }.nav-link:hover{
		  color: rgba(2,96,105);
		  border-bottom:0.2vw solid rgba(2,96,105);
	  }.nav-link .bi{
			padding-left:0.2vw;
			font-size:1vw;
			-webkit-text-stroke: 0.04vw;
			color:rgba(100, 100, 100);
		}.nav-link-signup{
		  font-size:5.2vw;
		  display:inline-block;
		  margin:0 1vw;
		  margin-left:2vw;
		  font-weight:500;
		  text-decoration:none;
		  color:rgba(1,1,1);
		  text-decoration:none;
		  background:rgba(2, 98, 105);
		  color:rgba(255, 255, 255);
		  padding:0.5vw 2.5vw;
		  border-radius:0.5vw;
	  }.nav-link-signup .bi{
		  color:rgba(255, 255, 255);
	  }
	}.navbar-accordion-body{
		background:rgba(254, 254, 254);
		padding:2vw 4vw;
		label{
			font-size:4.25vw;
			display:block;
			margin-bottom:5vw;
			padding:0.1vw 0vw;
			font-weight:500;
		}.bullet-links-icon{
			font-size:5vw;
			background:rgba(255, 255, 255);
			border:0.15vw solid rgba(240, 240, 240);
			border-radius:0.5vw;
			padding:1vw 2vw;
		}.bullet-links-heading{
			font-size:4.2vw;
			padding-left:0.5vw;
		}.bullet-links-subheading{
			font-size:3.5vw;
			padding-left:0.5vw;
			font-weight:500;
			color:rgba(150, 150, 150);
		}.bullet-links{
			margin:0.8vw 0;
			padding:0.1vw 1vw;
			padding-top:5vw;
			border-radius:1vw;
			border:0.1vw solid rgba(255, 255, 255);
		}.bullet-links:hover{
			cursor:pointer;
			border:0.1vw solid rgba(200, 200, 200);
			background:rgba(250, 250, 250);
		}.schedule-call-area{
			margin:5vw 0vw;
			padding:4vw 6vw;
			border-radius:5vw;
			background:rgba(245, 245, 245);
		}.schedule-call-area-heading{
			font-size:4.25vw;
			margin-top:2vw;
			font-weight:600;
		}.schedule-call-area-subheading{
			font-size:3vw;
			color:rgba(125, 125, 125);
		}.Schedule-call-bullet .bi{
			margin-right:0.5vw;
			color:rgba(2,96,105);
			-webkit-text-stroke: 0.04vw;
		}.Schedule-call-bullet{
			font-size:3.5vw;
			font-weight:500;
			margin:4vw 0;
			color:rgba(100, 100, 100);
		}.schedule-call-bullets{
			margin-top:4vw;
		}.book-call{
			background:rgba(2,96,105);
			color:rgba(255, 255, 255);
			border:None;
			font-weight:500;
			margin:2vw 0;
			border-radius:1vw;
			padding:1.5vw 3vw;
		}.schedule-image{
			width:10vw;
			height:10vw;
			object-fit:cover;
			border-radius:50%;
			margin-right:-0.5vw;
			border:0.2vw solid rgba(200, 200, 200);
		}.schedule-image-area{
			padding:2vw 0;
		}.accordion-nav-link{
			font-size:5vw;
			padding:2vw 1vw;
			font-weight:500;
			display:block;
		}.accordion-nav-link .bi{
			float:right;
		}.accordion-content{
			padding:2vw 1vw;
		}
	}
}
