
			.create-campaign-body{
				  font-family: 'Poppins', sans-serif;
				  background:rgb(250, 250, 250);
				  min-height:100vh;
				  overflow-x:hidden;
				  
				  .top-bar-navigation{
					background:white;
					padding:1.4vw;
					margin:0  2vw;
					margin-top:1vw;
					border:0.1vw solid rgba(220, 220, 220);
					border-radius:0.5vw;
					padding-top:1.5vw;
					 display: flex;
				}.steps h5{
					line-height: 2vw;
					font-size:0.8vw;
					font-weight:500;
					color: black;
					vertical-align:middle;
				}.steps i{
					padding-top:0.3vw;
					font-size:1.4vw;
					color: black;
					display: inline-flex;
					vertical-align: top;
					margin:0 0.5vw;
				}.steps{
				opacity:0.5;
				cursor:not-allowed;
				display:inline;
				}.steps_active{
				opacity:1;
				cursor:text;
				}.left-side{
					margin:2vw;
					border:0.1vw solid rgba(220, 220, 220);
					border-radius:0.5vw;
					background:rgba(255, 255, 255);
				}.secondary-heading-area{
					padding:1vw 1vw;
					margin-bottom:1vw;
					padding-bottom:0.5vw;
					border-bottom:0.1vw solid rgba(230, 230, 230);
				}.secondary-heading{
					font-size:1vw;
					font-weight:600;
				}.secondary-heading .bi{
					-webkit-text-stroke: 0.05vw;
					
				}.form-area{
					padding-bottom:2vw;
					margin-bottom:2vw;
					padding:1vw 2vw;
					padding-bottom:2vw;
					border-bottom:0.1vw solid rgba(240, 240, 240);
				}.form-area:last-child{
					border:none;
					padding-bottom:0;
				}.input-form{
					padding:0.5vw 0.8vw;
					border-radius:0.4vw;
					outline:none;
					font-size:0.9vw;
					border:0.14vw solid rgba(220, 220, 220);
				}.secondary-subheading{
					font-size:0.8vw;
					font-weight:400;
					line-height:1.5vw;
					color:rgba(120, 120, 120);
				}.secondary-button {
					  border-radius: 0.4vw;
					  font-size: 1vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(50, 50, 50);
					  border: 0.2vw solid rgba(240, 240, 240);
					  background: rgba(240, 240, 240);
					}.link-button{
						color:rgba(2, 96, 105);
						text-decoration:none;
						font-size:0.9vw;
						font-weight:500;
						text-align:right;
						display:block;
					}.recipient-meter-left-heading{
			font-size:1.2vw;
			font-weight:500;
		}.recipient-meter-right-subheading{
			font-size:1vw;
			font-weight:400;
			text-align:right;
		}.recipient-email-tracker{
			background:rgba(245, 245, 245);
			margin-top:0.5vw;
			width:100%;
			padding:0.3vw;
			border-radius:0.5vw;
		}.recipient-email-tracker-fill{
			width:0%;
			padding:0.25vw;
			border-radius:0.5vw;
		}.recipient-email-label{
			font-size:0.9vw;
			margin:1vw 0;
			opacity:0.7;
			font-weight:400;
		}.email-preview-area{
		background:rgba(245, 245, 245);
		padding:2vw;
		border-radius:0.5vw;
		margin-top:1.5vw;
		}.email-preview{
			width:auto;
			height:15vw;
			max-height:15vw;
			border-radius:0.6vw;
			border:0.1vw solid rgba(245, 245, 245);
			object-fit:contain;
		}.email-review-blocks{
			border:0.1vw solid rgba(245, 245, 245);
			border-radius:0.5vw;
			padding:1vw;
			padding-top:1.6vw;
			margin:2vw 1.5vw;
			
		}.email-review-blocks .icon-area{
			font-size:2vw;
		}.email-review-blocks .center-area h3{
			font-size:1vw;
			font-weight:600;
		}.email-review-blocks .center-area h6{
			font-size:0.8vw;
			opacity:0.8;
			color:rgba(10, 10, 10);
			font-weight:400;
		}.email-review-blocks .center-area b{
			font-weight:600;
			opacity:1;
		}.email-review-blocks .bi-check-circle-fill{
			color:rgba(34, 153, 84);
		}.email-review-blocks .bi-exclamation-circle-fill{
			color:rgba(243, 156, 18);
		}
		
		.email-review-blocks .end-area button{
			background:none;
			border:0.15vw solid rgba(245, 245, 245);
			padding:0.5vw 1.5vw;
			border-radius:0.4vw;
			font-size:0.8vw;
			font-weight:500;
		}.error{
			color:rgba(231, 76, 60);
			font-size:0.8vw;
			display:none;
		}.steps-area, .preview-area{
	  background-color:rgba(255, 255, 255);
	  border:0.1vw solid rgba(220, 220, 220);
	  border-radius:0.5vw;
	  margin-right:2vw;
	  margin-left:0vw;
	  margin-top:2.1vw;
	  }.steps-area .header, .preview-area .header{
		  font-size:1.2vw;
		  font-weight:600;
		  
		  border-radius:0.5vw 0.5vw 0 0;
		  background:rgba(250, 250, 250);
		  padding: 1vw 1.5vw;
		  padding-bottom:0.4vw;
	  }.steps-area .content, .preview-area .content{
		  padding:1.5vw;
	  }.preview-device-area{
					height:25vw;
					overflow:hidden;
				}.email-preview-disclaimer{
			padding:1.5vw 0;
			font-size:0.8vw;
			text-align:center;
			opacity:0.8;
		}.mobile-phone {
		  padding: 1vw 1vw 1vw;
		  width: 100%;
		  height: 65vw;
		  border-radius: 3vw;
		  border:0.2vw solid rgba(245, 245, 245);
		}

		.screen {
		  width: 100%;
		  height: 100%;
		  background: white;
		  border:0.2vw solid rgba(245, 245, 245);
		  border-radius: 3vw;
		  overflow-y: auto;
		}

		.brove {
		  width: 12vw;
		  height: 20px;
		  display:none;
		  background: white;
		  border:2px solid rgba(245, 245, 245);
		  border-top:none;
		  position: absolute;
		  margin-left:8%;
		  margin-right:8%;
		  border-radius: 0 0 20px 20px;
		}

		.speaker {
		  width: 60px;
		  height: 5px;
		  background: #d2d2d2;
		  display: block;
		  margin: auto;
		  margin-top: 5px;
		  border-radius: 20px;
		}.mobile-top-nav{
			padding:5px 14px;
			opacity:0.5;
			margin-top: 1vw;
		}.mobile-top-nav{
			font-size:1vw;
		}.mobile-top-nav-menu{
					padding:0 1vw;
					font-size:1vw;
				}.mobile-top-nav-menu h6{
				    font-size:1vw;
				}.email-preview-list-area{
					padding:0px 1vw;
					margin-top:2vw;
					padding-right:2vw;
				}.email-preview-list-area .icon_avatar{
						padding:0.3vw 0.6vw;
						border-radius:15vw;
						text-align:center;
						font-size:1.6vw;
				}.preview_sender_name{
					font-size:1vw;
					font-weight:600;
					margin:0.2vw 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}.preview_sender_subject{
					font-size:0.8vw;
					font-weight:600;
					margin:0.3vw 0px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}.preview_sender_preview{
					font-size:0.7vw;
					font-size:300;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}.bg_blue_avatar{
					background:rgba(66, 133, 244);
					color:rgba(255, 255, 255);
				}.bg_red_avatar{
					background:rgba(187, 0, 27);
					color:rgba(255, 255, 255);
				}.preview-device-area{
					height:20vw;
					overflow:hidden;
					
				}.preview-time{
					font-size:1vw;
				}.campaign-name-input{
					background:none;
					border:none;
					margin:2vw;
					margin-bottom:0;
					outline:none;
					width:auto;
					max-width:100%;
					font-size:1.2vw;
					padding:0.5vw 1vw;
					border-radius:0.5vw;
					font-weight:600;
				}.attach-files-button{
					font-size:1vw;
					border-radius:0.5vw;
					display:block;
					width:100%;
					text-align:left;
					cursor: pointer;
					background:rgba(245, 245, 245);
					padding:0.6vw 1.2vw;
					border:0.1vw solid rgba(205, 205, 205);
				}.selected-lists{
					padding:1vw 0;
				}.selected-lists span{
					font-size:0.8vw;
					color:rgba(255, 255, 255);
					padding:0.3vw 0.6vw;
					margin:0.4vw 0.2vw;
					border-radius:0.4vw;
					cursor:pointer;
					font-weight:500;
					display:inline-block;
					border: 0.2vw solid rgba(2, 96, 105);
				    background: rgba(2, 96, 105);
				}.selected-lists span i{
					margin-left:0.5vw;
				}.error-message{
					font-size:0.8vw;
					font-weight:500;
					margin-top:0.5vw;
					color: rgba(231, 76, 60);
				}.input-error, .input-error:focus{
					color:rgb(231, 76, 60);
					border:0.2vw solid rgb(231, 76, 60);
				}
				 
			}.sidenav_contact {
	
				  font-family: 'Poppins', sans-serif;
	  height: 100%;
	  width: 0;
	  position: fixed;
	  z-index: 1001;
	  top: 0;
	  right: 0;
	  background-color: #ffffff;
	  overflow-x: hidden;
	  transition: 0.5s;
	}.openedsidenav{
		width:25%;
	}

	.sidenav_contact a {
	  padding: 8px 8px 8px 32px;
	  text-decoration: none;
	  font-size: 25px;
	  color: #818181;
	  display: block;
	  transition: 0.3s;
	}

	.sidenav_contact a:hover {
	  color: #f1f1f1;
	}


	@media screen and (max-height: 450px) {
	  .sidenav_contact {padding-top: 15px;}
	  .sidenav_contact a {font-size: 18px;}
	}.sidenav_top_area{
		background:#fafafa;
		padding:10px 30px;
		padding-bottom:5px;
	}.closebtn {
	  top: 0;
	  right: 0px;
	  font-size: 36px;
	  text-align:right;
	}.close{
		top: 0;
	  right: 0px;
	  text-align:right;
	  background:none;
	  border:none;
	}.close span{
	  font-size:24px;
	}
	
	.sidenav_top_area .col-8{
		font-weight:500;
		font-size:20px;
	}.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 998; /* Sit on top */
  left: 0;
  top: 0;
  display:;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.3); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}.sidenav_main_content{

		padding:10px 30px;	
}.schedule-option{
	  margin:10px 0px;
	  margin-bottom:15px;
	  padding:10px 5px;border-radius:6px;cursor:pointer;border:2px solid #f1f1f1;
  }.schedule-option img{
	  width:50px;height:50px;object-fit:contain;border-radius:6px;background-color:#f1f1f1;padding:15px;border-radius:0.5vw;
  }.schedule-option h3{
	  font-size:16px;
	  padding-top:10px;
	  font-weight:600;
  }.schedule-option h6{
	  font-size:12px;
	  font-weight:400;
  }.active-option{
	  background-color:black;
	  color:white;
	  border:1px solid black;
  }.schedule-option:hover{
	  background:#fafafa;
  }.form_conrol{
	align-items: center;
  justify-content: center;
  padding: 6px 14px;
  border: 1px solid #f1f1f1;
  width:100%;
  border-radius: 4px;
  background-color: #fff;
  font-weight:600;
  color: #111111;
  font-size: 14px;
  outline:none;
  margin-bottom:10px;
  
}.form_conrol:focus{
	
  border: 1px solid #111111;
}.submit-button-full{
  align-items: center;
  justify-content: center;
  padding: 6px 14px;
  border: 2px solid #111111;
  border-radius: 6px;
  background-color: #111111;
  font-weight:600;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin:12px 0px;
}.saving-button-full, .saving-button-full:hover{
  align-items: center;
  justify-content: center;
  padding: 6px 14px;
  border: 2px solid #111111;
  border-radius: 6px;
  display:none;
  background-color: #111111;
  font-weight:600;
  color: white;
  font-size: 14px;
  cursor: not-allowed;
  opacity:0.6;
  margin:12px 0px;
}.schedule-option-active{
	border:2px solid #111111;
	background:#fafafa;
}.selected_button_two{
							background-color:#111111;
							color:#fcfcfc;
							border:1px solid #111111;
							border-radius:150px;
							padding:5px 10px;
							font-weight:500;

							letter-spacing:0.03em;
							font-size:14px;
							margin:5px 0px;
							margin-right:7px;
		}.selected_button_two:hover{
			opacity:1;
		}.success-icon{
					width:100%;
					height:8vw;
					margin-top:2vw;
					object-fit:contain;
				}.secondary-heading-modal{
					font-size:1.5vw;
					font-weight:600;
				  font-family: 'Poppins', sans-serif;
					margin-top:1vw;
				}.secondary-description-modal{
					font-size:0.8vw;
					line-height:1.5vw;
				  font-family: 'Poppins', sans-serif;
					font-weight:400;
					margin-bottom:1vw;
					margin-top:0.5vw;
				}.uploaded-file {
					display: flex;
					align-items: center;
					background-color: #f9f9f9;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 10px 15px;
					margin-bottom: 10px;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
				  }
				  
				  .file-name {
					flex: 1;
					font-size: 1vw;
					color: #333;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				  }
				  
				  .remove-btn {
					background-color: #ff4d4f;
					border: none;
					color: #fff;
					font-size: 16px;
					cursor: pointer;
					padding: 5px 10px;
					border-radius: 3px;
					transition: background-color 0.3s ease;
				  }
				  
				  .remove-btn:hover {
					background-color: #d9363e;
				  }
				  
				  .remove-btn:focus {
					outline: none;
					box-shadow: 0 0 3px 2px rgba(255, 77, 79, 0.5);
				  }.final-schedule-button {
					border-radius: 0.4vw;
					font-size: 0.8vw;
					font-weight: 500;
					padding: 0.5vw 1.2vw;
					color: rgba(255, 255, 255);
					margin-bottom: 1vw;
					outline:none;
					border:none;
					margin-left: 0;
					background: rgba(2, 96, 105);
				  }.input-form{
					display: block;
					width: 100%;
					color: rgb(50, 50, 50);
					margin-top: clamp(0.4rem, 0.5vw, 1rem);
					border: none;
					background: rgb(255, 255, 255);
					border: 0.14rem solid rgb(220, 220, 220);
					border-radius: 0.4rem;
					padding: clamp(0.4rem, 0.5vw, 1rem) clamp(0.8rem, 1vw, 1.5rem);
					font-size: clamp(0.9rem, 1vw, 1.2rem);
					font-weight: 500;
					outline: none;
				}.select-box {
					font-family: 'Arial', sans-serif;
					font-size: 1rem;
					height: 3.1rem;
					color: #333;
					background-color: #fff;
					border-radius: 0.4rem;
					border: 0.1rem solid rgb(220, 220, 220);
					padding: 0rem;
				}
				
				/* Menu item styles */
				.select-box-item {
					font-size: 0.9rem;
					padding: 0.8rem 1rem;
					cursor: pointer;
					transition: background-color 0.3s ease;
				}
				
				.select-box-item:hover {
					background-color: #f5f5f5;
				}
				
				/* Typography within menu items */
				.select-box-typography {
					font-weight: 500;
					color: #555;
				}
				
				/* Box styling inside menu items */
				.select-box-box {
					display: flex;
					align-items: center;
				}  .primary-button{
					font-size: 0.8vw;
				}.line-breaker{
					background-color: rgba(240, 240, 240);
				}.no-enough-creadit-area{
					margin: 1vw;
					padding: 0.8vw;
					background-color: #d6285317;
					border:0.2vw solid #d62853a1;
					border-radius: 0.5vw;
				}.no-enough-creadit-area h3{
					color:#d62853;
					font-size: 1vw;
					
					line-height: 1.5vw;
				}.no-enough-creadit-area p{
					color:#111111;
					font-size: 0.8vw;
					line-height: 1.5vw;
				}.no-enough-creadit-area a{
					font-size: 1vw;
					color:rgba(255, 255, 255);
					margin-left:0;
					padding:0.5vw 1vw;
					border-radius: 0.5vw;
					font-size:0.8vw;
					text-decoration: none;
					background-color: rgba(2, 96, 105);
					display: inline-block;
				}