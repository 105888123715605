.preview-mail-area{
/* PreviewPagePro.css */
.preview-pro-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  /* Tabs */
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .tabs button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    background: #e0e0e0;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .tabs button.active {
    background: #007bff;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .tab-icon {
    font-size: 18px;
  }
  
  /* Desktop Frame */
  .desktop-frame {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .browser-bar {
    background: #e5e5e5;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .browser-bar .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .browser-bar .dot.red {
    background: #ff605c;
  }
  
  .browser-bar .dot.yellow {
    background: #ffbd44;
  }
  
  .browser-bar .dot.green {
    background: #00ca4e;
  }
  
  .url-placeholder {
    flex-grow: 1;
    padding: 5px 10px;
    background: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    color: #555;
    cursor: default;
    text-align: left;
  }
  
  /* Desktop Preview */
  .desktop-preview {
    width: 100%;
    height: 600px;
    border: none;
  }
  
  /* Mobile Frame */
.mobile-frame {
    width: 390px;
    margin: 20px auto; /* Add margin for spacing */
    position: relative;
    padding: 20px 15px;
    background: linear-gradient(135deg, #e0e0e0, #c0c0c0); /* Metallic gray body */
    border-radius: 30px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* iPhone Design */
  .phone-mockup {
    position: relative;
    width: 100%;
    height: 702px; /* Adjusted to a realistic iPhone height */
    background: white;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
  }
  
  /* Notch */
  .phone-mockup::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 20px;
    margin-top: 10px;
    background: #333; /* Dark gray for the notch */
    border-radius: 15px;
    z-index: 1;
  }
  
  /* Padding Below Notch */
  .phone-mockup::after {
    content: "";
    width: 100%;
    height: 20px;
    position: absolute;
    top: 70px; /* Adds padding below the notch */
    left: 0;
    z-index: 2;
  }
  
  /* Mobile Preview */
  .mobile-preview {
    width: 100%;
    height: calc(100% - 50px); /* Adjust height to account for the notch and padding */
    border: none;
    position: absolute;
    padding: 10px;
    top: 50px; /* Padding below notch */
    left: 0;
  }
    
}