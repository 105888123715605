/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
}


/* Laptops and desktops */
@media only screen and (min-width: 1024px) {
				.checkout-body{
					  font-family: "Poppins", sans-serif;
					  height:100%;
				.breadcrumb .breadcrumb-item{
							font-size:1vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.checkout-area{
					padding:1vw 2vw;
				}.main-plan-heading{
					font-size:1.2vw;
				}.main-plan-subheading{
					font-size:1vw;
					font-weight:400;
					color:rgba(150, 150, 150);
				}.left-area{
					background:rgba(255, 255, 255);
					padding:0vw 0vw;
					padding-right:1vw;
					border-radius:0.5vw;
				}.right-area{
					background:rgba(255, 255, 255);
					padding:2vw 1.5vw;
					border-radius:0.5vw;
					border:0.1vw solid rgba(240, 240, 240);
				}.left-header-area{
					padding-bottom:0.8vw;
					border-bottom:0.15vw solid rgba(245, 245, 245);
				}.right-header-area{
					padding-bottom:0.8vw;
					border-bottom:0.15vw solid rgba(245, 245, 245);
				}.duration-plan-area{
				}.duration-plan-col{
					width:25.7vw;
					display:inline-block;
					border-radius:0.8vw;
					padding:1vw;
					margin:1vw 0;
					cursor:pointer;
					margin-right:1vw;
					border:0.2vw solid rgba(240, 240, 240);
				}.duration-plan-col:last-child{
					margin-right:0;
				}.duration-plan-bottom-area{
					margin-top:1vw;
				}.duration-plan-heading{
					font-size:1.2vw;
					padding-bottom:1vw;
					border-bottom:0.2vw solid rgba(240, 240, 240);
				}.duration-plan-bottom-heading{
					font-size:1.1vw;
				}.duration-plan-bottom-subheading{
					font-size:0.9vw;
					color:rgba(125, 125, 125);
					font-weight:400;
				}.duration-plan-area .bi{
					margin-right:0.25vw;
				}.active-duration-plan{
					border:0.2vw solid rgba(2, 96, 105);
					.bi{
					 color:rgba(2, 96, 105);
					}
				}.saving-label{
					background:rgba(2, 96, 105);
					color:rgba(255, 255, 255);
					padding:0.3vw 0.8vw;
					display:inline-block;
					font-size:1vw;
					border-radius:1vw;
				}.features-plan-area{
					margin:1vw 0;
					margin-bottom:2vw;
				}.features-plan-heading{
					margin:1vw 0;
					font-size:1.2vw;
				}.features-box{
					border:0.14vw solid rgba(238, 238, 238);
					border-radius:0.4vw;
					padding:1vw;
				}.feature-list{
					font-size:1vw;
					margin:0.5vw 0;
				}.feature-list .bi{
					margin-right:0.5vw;
					color:rgba(82, 190, 128);
					-webkit-text-stroke: 0.05vw;
				}.billing-total-area{
					margin-top:1vw;
				}.billing-summary-left {
					font-size:1vw;
					display:block;
					margin:0.4vw 0;
					color:rgba(150, 150, 150);
				}.billing-summary-right {
					font-size:1vw;
					display:block;
					margin:0.4vw 0;
					text-align:right;
					color:rgba(50, 50, 50);
				}.final-total-area{
					border-top:0.2vw dashed rgba(245, 245, 245);
					border-bottom:0.2vw solid rgba(245, 245, 245);
					margin:1vw 0;
					padding:1vw 0;
					.billing-summary-left {
					font-size:1.1vw;
					display:block;
					margin:0.4vw 0;
					color:rgba(150, 150, 150);
				}.billing-summary-right {
					font-size:1.1vw;
					display:block;
					margin:0.4vw 0;
					text-align:right;
					color:rgba(50, 50, 50);
				}
				}.primary-button{
					border-radius: 0.4vw;
					font-size:1vw;
					font-weight:500;
					padding: 0.5vw 1.2vw;
					color:rgba(255, 255, 255);
					border:0.2vw solid rgba(2,96,105);
					background:rgba(2,96,105);
				}.edit-link{
					color:rgba(2,96,105);
					font-size:1.1vw;
				}.billing-info-list{
					color:rgba(50, 50, 50);
					font-weight:400;
					font-size:1vw;
					display:block;
				}.billing-info-list:first-child{
					color:rgba(10, 10, 10);
					font-weight:500;
					margin-bottom:0.5vw;
					font-size:1.1vw;
				}.change-plan{
					color:rgba(2, 96, 105);
					font-size:0.9vw;
					margin-left:0.5vw;
				}
				}
}