.sidebar-body{
	background:rgba(254, 254, 254);
}.inbox-area{
    overflow: hidden;
	font-family: 'Poppins', sans-serif;
    
    width: 100%;
    height: 100vh; /* Full height */
    display: flex;

    .chat-container {
        display: flex;
        width: 100%;
        height: 100%;
      }
      
      .people-area {
        padding: 15px;
        width: 30%; /* Sidebar for contacts */
        background: rgb(255, 255, 255);
        border-right: 2px solid rgba(230, 230, 230);
      }

      /* Chat List */
.chat-list {
    display: flex;
    flex-direction: column;
  }
  .people-list-area{
    overflow-x: hidden;
    overflow-y: auto;
    height: 90vh;
  }
  /* Single Chat Item */
  .chat-item {
    display: flex;
    align-items: center;
    padding: 20px 5px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .chat-item:hover {
    background: rgba(240, 240, 240) ;
  }

  
  
  /* User Avatar */
  .chat-avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .chat-img {
    width: 100%;  /* Adjust size as needed */
    height: 100%;
    object-fit: contain;
}
  
  /* Chat Details (Name + Message Preview) */
  .chat-details {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* Name and Time */
  .chat-header {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    font-weight: 600;
  }
  
  /* Message Preview */
  .chat-preview {
    margin: 3px 0 0;
    font-size: 14px;
    color: #555;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }.chat-time{
    font-weight: 500;
    font-size: 14px;
  }
      
      .chat-area {
        width: 70%; /* Main chat section */
        background: #fff;
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100vh;
      }

      .search-box{
        width: 100%;
        background-color: rgba(255, 255, 255);
        border: 2px solid rgb(233, 233, 233);
        padding: 8px 16px;
        border-radius: clamp(0.3vw, 0.5vw, 0.7vw);
        font-weight: 500;
        font-size: 16px;
        outline: none;
        background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 15px rgba(255, 255, 255);
        background-size: 16px;
        padding-left: 45px;
      }.people-area .title{
        font-size: 20px;
        margin-top: 20px;
      }     
      .chat-area{
        .chat-header {
            display: flex;
            align-items: center;
            padding: 10px;
            background: #fff;
            border-bottom: 1px solid #ddd;
          }
          
          .chat-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
          }
          
          .chat-user-info {
            flex: 1;
            display: flex;
            flex-direction: column;
          }
          
          .chat-user-name {
            font-weight: bold;
          }
          
          .chat-user-status {
            font-size: 12px;
            color: #666;
          }
          
          .chat-menu {
            cursor: pointer;
            font-size: 20px;
          }
          
          /* Chat Body */
          .chat-body {
            flex: 1;
            overflow-y: auto;
            padding: 10px;
            display: flex;
            flex-direction: column;
          }
          
          /* Chat Bubbles */
          .chat-bubble {
            max-width: 60%;
            padding: 8px 10px;
            margin: 5px 0;
            border-radius: 10px;
            font-size: 14px;
            word-wrap: break-word;
          }
          
          .chat-bubble.me {
            align-self: flex-end;
            background: #dcf8c6;
          }
          
          .message-time{
            font-size: 12px;
            display: block;
            color: gray;
            text-align: right;
            font-weight: 400;
            margin-top: 10px;
            margin-left: 50px;
          }
          
          .chat-bubble.other {
            align-self: flex-start;
            background: #fff;
            border: 1px solid #ddd;
          }
          
          /* Chat Footer */
          .chat-footer {
            display: flex;
            align-items: center;
            padding: 10px;
            background: #fff;
            border-top: 1px solid #ddd;
          }
          
          .chat-footer input {
            flex: 1;
            padding: 8px;
            border: none;
            background-color: rgba(240, 240, 240);
            border-radius: 5px;
            outline: none;
          }
          
          .chat-footer button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 20px;
            margin-left: 10px;
          }.no-recipient {
            display: flex;
            height: 100vh; /* Full viewport height */
            align-items: center; /* Vertical Center */
            justify-content: center; /* Horizontal Center */
            text-align: center; /* Center text content */
            color: #6c757d;
        }

        .spinner {
          width: 18px;
          height: 18px;
          border: 2px solid #34B7F1;
          border-top: 2px solid transparent;
          border-radius: 50%;
          animation: spin 0.8s linear infinite;
        }
        @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
        
        .error-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px; /* Add spacing between elements */
        }
        
        .error-icon {
            font-size: 60px;
            color: #6c757d;
        }
        
        .error-heading {
            font-size: 26px;
            font-weight: bold;
            margin: 0;
        }
        
        .error-subheading {
            font-size: 16px;
            opacity: 0.8;
        }
        
          
      }
    
}