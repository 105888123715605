
			.template-library-body {
				font-family: 'Poppins', sans-serif;
				background-color: rgba(255, 255, 255);
				
				.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .breadcrumb-item{
					font-size:1vw;
				}.breadcrumb .active{
					font-weight:500;
					font-size:1vw;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.header-area{
					background: linear-gradient(to bottom, rgba(242, 242, 242, 1) 50%, rgba(242, 242, 242, 0) 50%);
					padding:5vw;
					margin-top: 2vw;
				}.search-bar{
					width:40vw;
					padding:1vw 2vw;
					border:none;
					font-size:1.2vw;
					outline:none;
					border-radius: 0.4vw;
				    background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 1.5vw rgba(255, 255, 255);
				    background-size: 1vw;
				    padding-left: 4vw;
				}::placeholder {
				  color: rgba(180, 180, 180);
				}.search-result-category-area{
				
				}.main-heading{
					font-size:1.5vw;
					text-align:center;
				}.main-subheading{
					font-size:1vw;
					text-align:center;
					color:rgba(150, 150, 150);
					font-weight:400;
					margin-top:1vw;
				}.category-tabs{
					margin-top:1vw;
				}.category-tabs a{
					display:inline-block;
					margin:1vw 0.5vw;
					font-weight:400;
					text-decoration:none;
					color:rgba(0, 0, 0);
					cursor:pointer;
					font-size:0.9vw;
					padding:0.5vw 1vw;
					border-radius:10vw;
				}.category-tabs .active, .category-tabs .active:hover {
					font-weight:500;
					background:rgba(245, 245, 245);
				}.category-tabs a:hover{
					color:rgba(100, 100, 100);
				}.template-result-area{
					padding:2vw;
				}.template-preview{
					width:100%;
					height:12vw;
					display:block;
					object-fit:cover;
					object-position: top;
					border-radius:0.5vw 0.5vw 0vw 0vw;
				}.template-preview-area {
					position: relative; /* Establishes a positioning context for the overlay and button */
					display: inline-block;
					margin:1vw 0;
					width: 100%;
					border-radius: 0.8vw;
					padding: 1vw 6vw 0 6vw;
					background-color: rgba(240, 240, 240);
					
				}

				/* Overlay effect */
				.overlay {
					position: absolute; /* Allows precise positioning within the relative parent */
					top: 0;
					left: 0;
					width: 100%;
					border-radius:0.5vw;
					height: 100%;
					background: rgba(255, 255, 255, 0.6); /* Semi-transparent black background */
					opacity: 0; /* Hidden by default */
					display: flex;
					align-items: center;
					justify-content: center;
					transition: opacity 0.3s ease; /* Smooth transition for the overlay effect */
				}

				.center-button {
					padding: 0.8vw 1.6vw;
					background-color: rgba(2, 96, 105); /* Example background color */
					color: rgba(255, 255, 255);
					font-weight:500;
					font-size:1.2vw;
					border: none;
					border-radius: 0.5vw;
					cursor: pointer;
				}

				/* Show overlay and button on hover */
				.template-preview-area:hover .overlay {
					opacity: 1; /* Shows the overlay on hover */
				}


				
			}
