.footer-body{
	font-family: 'Poppins', sans-serif;
	  background-color:rgba(1,32,35);
	  padding:0vw 5vw;
	  padding-top:5vw;
	  padding-bottom:2vw;
	  .footer-logo{
		  width:8vw;
		  height:auto;
		  margin:1.5vw 0;
	  }.description-area{
		  padding-right:4vw;
	  }.footer-description{
		  color:rgba(245, 245, 245);
		  font-weight:500;
		  font-size:0.8vw;
		  line-height:1.8vw;
	  }.link-label{
		  font-size:0.8vw;
		  display:block;
		  font-weight:600;
		  line-height:2vw;
		  color:rgba(200, 200, 200);
	  }.footer-link{
		  font-size:0.8vw;
		  display:block;
		  line-height:2.2vw;
		  font-weight:500;
		  text-decoration:none;
		  color:rgba(250, 250, 250);
	  }.bottom-footer{
		  margin:2vw 0;
		  padding-top:2vw;
		  border-top:0.1vw solid rgba(200, 200, 200);
	  }.copyright{
		  color:rgba(220, 220, 220);
		  font-size:0.8vw;
	  }.social-media-links a{
		  color:rgba(240, 240, 240);
		  text-align:right;
		  margin:0 0.8vw;
		  font-size:1.2vw;
	  }.social-media-links a:last-child{
		  margin-right:0;
	  }.social-media-links{
		  float:right;
	  }.hide-phone{
		  display:block;
	  }
  }

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.footer-body{
	  font-family: 'Poppins', sans-serif;
		background-color:rgba(1,32,35);
		padding:0vw 5vw;
		padding-top:5vw;
		padding-bottom:2vw;
		.footer-logo{
			width:25vw;
			height:auto;
			margin-top:4vw;
			margin-bottom:4vw;
		}.description-area{
			padding-right:4vw;
		}.footer-description{
			color:rgba(245, 245, 245);
			font-weight:500;
			font-size:4vw;
			line-height:5.5vw;
			margin-bottom:2.5vw;
		}.link-label{
			font-size:3.5vw;
			display:block;
			font-weight:600;
			line-height:6vw;
			margin:3vw 0vw;
			color:rgba(200, 200, 200);
		}.footer-link{
			font-size:3.5vw;
			display:block;
			line-height:5vw;
			font-weight:500;
			margin:1.2vw 0;
			text-decoration:none;
			color:rgba(250, 250, 250);
		}.bottom-footer{
			margin:5vw 0;
			padding-top:2vw;
			border-top:0.1vw solid rgba(200, 200, 200);
		}.copyright{
			color:rgba(220, 220, 220);
			font-size:3.5vw;
			text-align:center;
		}.social-media-links a{
			color:rgba(240, 240, 240);
			text-align:center;
			margin:0 2vw;
			font-size:5vw;
		}.social-media-links a:last-child{
			margin-right:0;
		}.social-media-links{
			text-align:center;
			padding:5vw 0;
		}.hide-phone{
			display:none;
		}
	}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.footer-body{
	  font-family: 'Poppins', sans-serif;
		background-color:rgba(1,32,35);
		padding:0vw 5vw;
		padding-top:5vw;
		padding-bottom:2vw;
		.footer-logo{
			width:25vw;
			height:auto;
			margin-top:4vw;
			margin-bottom:4vw;
		}.description-area{
			padding-right:4vw;
		}.footer-description{
			color:rgba(245, 245, 245);
			font-weight:500;
			font-size:4vw;
			line-height:5.5vw;
			margin-bottom:2.5vw;
		}.link-label{
			font-size:3.5vw;
			display:block;
			font-weight:600;
			line-height:6vw;
			margin:3vw 0vw;
			color:rgba(200, 200, 200);
		}.footer-link{
			font-size:3.5vw;
			display:block;
			line-height:5vw;
			font-weight:500;
			margin:1.2vw 0;
			text-decoration:none;
			color:rgba(250, 250, 250);
		}.bottom-footer{
			margin:5vw 0;
			padding-top:2vw;
			border-top:0.1vw solid rgba(200, 200, 200);
		}.copyright{
			color:rgba(220, 220, 220);
			font-size:3.5vw;
			text-align:center;
		}.social-media-links a{
			color:rgba(240, 240, 240);
			text-align:center;
			margin:0 2vw;
			font-size:5vw;
		}.social-media-links a:last-child{
			margin-right:0;
		}.social-media-links{
			text-align:center;
			padding:5vw 0;
		}.hide-phone{
			display:none;
		}
	}

}


@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.footer-body{
	  font-family: 'Poppins', sans-serif;
		background-color:rgba(1,32,35);
		padding:0vw 5vw;
		padding-top:5vw;
		padding-bottom:2vw;
		.footer-logo{
			width:25vw;
			height:auto;
			margin-top:4vw;
			margin-bottom:4vw;
		}.description-area{
			padding-right:4vw;
		}.footer-description{
			color:rgba(245, 245, 245);
			font-weight:500;
			font-size:4vw;
			line-height:5.5vw;
			margin-bottom:2.5vw;
		}.link-label{
			font-size:3.5vw;
			display:block;
			font-weight:600;
			line-height:6vw;
			margin:3vw 0vw;
			color:rgba(200, 200, 200);
		}.footer-link{
			font-size:3.5vw;
			display:block;
			line-height:5vw;
			font-weight:500;
			margin:1.2vw 0;
			text-decoration:none;
			color:rgba(250, 250, 250);
		}.bottom-footer{
			margin:5vw 0;
			padding-top:2vw;
			border-top:0.1vw solid rgba(200, 200, 200);
		}.copyright{
			color:rgba(220, 220, 220);
			font-size:3.5vw;
			text-align:center;
		}.social-media-links a{
			color:rgba(240, 240, 240);
			text-align:center;
			margin:0 2vw;
			font-size:5vw;
		}.social-media-links a:last-child{
			margin-right:0;
		}.social-media-links{
			text-align:center;
			padding:5vw 0;
		}.hide-phone{
			display:none;
		}
	}

}




