.domain-auth-content{
		
				font-family: 'Poppins', sans-serif;
			.settings-body {
				font-family: 'Poppins', sans-serif;
				background-color: rgba(255, 255, 255);
				
				.header-area{
					padding:1vw 2vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.main-heading{
					 font-size:1.2vw;
					 font-weight:600;
				}.settings-tabs-area .settings-tab{
					font-size:1.1vw;
					margin:0.5vw 0.5vw;
					display:inline-block;
					color:rgba(120,120, 120);
					font-weight:500;
					letter-spacing:0.01rem;
					text-decoration:none;
					border-radius:0.4vw;
					padding:0.5vw 1vw;
					
				}.settings-tabs-area .settings-tab:first-child{
					margin:0.5vw 0;
					margin-right:0.6vw;
				}.settings-tabs-area .active{
					color:rgba(1,1,1);
					padding:0.5vw 1vw;
					background:rgba(245, 245, 245);
					font-weight:500;
				}.settings-tabs-area .settings-tab:hover{
					color:rgba(1,1,1);
				}.secondary-heading-area{
					padding:1.5vw 0;
					margin-bottom:1vw;
					border-top:0.2vw solid rgba(10, 10, 10);
					border-bottom:0.1vw solid rgba(230, 230, 230);
				}.secondary-heading{
					font-size:1vw;
					font-weight:600;
				}.secondary-subheading{
					font-size:0.8vw;
					line-height:1.5vw;
					color:rgba(120, 120, 120);
				}.main-area{
					padding:1vw 2vw;
				}.form-area{
					padding-bottom:2vw;
					margin-bottom:2vw;
					padding-top:1vw;
					border-bottom:0.1vw solid rgba(230, 230, 230);
				}.primary-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(255, 255, 255);
				  border: 0.2vw solid rgba(2, 96, 105);
				  background: rgba(2, 96, 105);
				}
				.primary-outline-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(100, 100, 100);
				  border: 0.2vw solid rgba(200, 200, 200);
				  background: rgba(255, 255, 255);
				}.input-form{
					padding:0.5vw 0.8vw;
					border-radius:0.4vw;
					outline:none;
					font-size:1.1vw;
					border:0.2vw solid rgba(200, 200, 200);
				}.input-form:focus{
				  border: 0.2vw solid rgba(2, 96, 105);
				}table {
					width: 100%;
					border:none;
					border-collapse: separate; /* Allows border-radius to work */
					border-spacing: 0; /* Removes spacing between cells */
					border-radius: 0vw; /* Rounds the corners of the table */
					overflow: hidden; /* Ensures the border-radius applies to the table content */
				}.th-one, .td-one{
					min-width:10vw;
				}.th-two, .td-two{
					min-width:10vw;
				}.th-three, .td-three, .th-four, .td-four{
					min-width:35vw;
				}.error-tr{
					background:rgba(253, 237, 236);
					border-left:0.2vw solid rgba(231, 76, 60);
					padding:2vw;
				}.error-tr-heading{
					color:rgba(231, 76, 60);
					font-size:0.9vw;
					font-weight:400;
				}.solution-content{
					background:rgba(255, 255, 255);
					padding:1vw;
					margin-top:1vw;
					border-radius:0.2vw;
					font-size:0.8vw;
				}

				th, td {
					padding: 1.2vw;
					text-align: left;
					border: 0.1vw solid rgba(240, 240, 240);
					border-radius: 0; /* No border-radius on cells */
				}

				thead {
					background-color: rgba(150, 150, 150);
				}
				.copy-button{
					display:inline-block;
					padding:0.4vw 0.8vw;
					float:right;
					text-decoration:none;
					color:rgba(10, 10, 10);
					font-weight:500;
					font-size: 0.8vw;
					border-radius:0.5vw;
					border:0.15vw solid rgba(230, 230, 230);
				}
				th {
					background-color: rgba(240, 240, 240);
					color: rgba(50, 50, 50);
					border: 0.1vw solid rgba(220, 220, 220);
					font-size:0.8vw;
					font-weight:500;
				}table .member-name{
					padding-top:1vw;
					font-size:1vw;
				}table .memeber-email{
					font-size:1vw;
					color:rgba(125, 125, 125);
				}table td{
					font-size:0.8vw;
				}table .member-icon{
					width:4.2vw;
					height:4.2vw;
					border-radius:50vw;
					border:0.1vw solid rgba(240, 240, 240);
					object-fit:cover;
				}table td .bi{
					font-size:1.5vw;
					margin:0 0.5vw;
					color:rgba(100, 100, 100);
				}.notification-radio-area{
					margin-bottom:2vw;
					margin-left:4vw;
				}.main-usage-area{
					padding:2vw;
					border-radius:1vw;
					border:0.1vw solid rgba(240, 240, 240);
				}.primary-label{
					font-size:1vw;
					font-weight:400;
					color:rgba(150, 150, 150);
				}.current-plan-label{
					font-size:1.2vw;
					color:rgba(10, 10, 10);
				}.usage-metric-area{
					padding:2vw;
					border-radius:1vw;
					margin:2vw 0;
					background:rgba(250, 250, 250);
					border:0.15vw solid rgba(240, 240, 240);
				}.plan-usage-label{
					font-size:1.1vw;
					font-weight:500;
					display:block;
					margin:0.5vw 0;
				}.secondary-plan-usage-label{
					font-size:1.1vw;
					font-weight:500;
					color:rgba(25, 25, 25);
					display:block;
					margin:0.5vw 0;
				}.credit-used-out-of{
					border-bottom:0.1vw dashed rgba(125, 125, 125);
				}.reset-date{
					font-size:1vw;
					display:block;
					color:rgba(125, 125, 125);
					font-weight:400;
				}.plan-usage-meter{
					background:rgba(235, 235, 235);
					padding:0;
					height:1.3vw;
					border-radius:1vw;
					margin:1vw 0;
				}.marketing-usage{
					width:35%;
					height:1.3vw;
					border-radius:1vw 0vw 0vw 1vw;
					display:inline-block;
					background:rgba(2, 96, 105);
				}.api-usage{
					height:1.3vw;
					width:40%;
					border-radius:0vw 1vw 1vw 0vw;
					display:inline-block;
					margin:0;
					background:rgba(2, 96, 105, 0.5);
				}.meter-examplain-label{
					display:inline-block;
					margin-right:2.5vw;
					font-size:1.1vw;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.meter-examplain-label b{
					color:rgba(0, 0, 0);
					font-weight:600;
				}.meter-examplain-label i{
					-webkit-text-stroke: 0.5vw;
				}.icon-marketing-usage{
					color:rgba(2, 96, 105);
				}.icon-api-usage{
					color:rgba(2, 96, 105, 0.5);
				}.feature-area-label{
					color:rgba(100, 100, 100);
					font-weight:400;
				}.feature-points i{
					margin-right:0.5vw;
					color:rgba(2, 96, 105);
				}.feature-points{
					margin:1vw 0;
					font-size:1.1vw;
				}.payments-area{
					border-radius:0.5vw;
					border:0.1vw solid rgba(240, 240, 240);
					padding:1vw 0;
				}.payments-heading{
					font-size:1.2vw;
				}.payments-top-area{
					padding:0.5vw 1vw;
					border-bottom:0.1vw solid rgba(240, 240, 240);
				}.payment-list-heading{
					font-size:1.1vw;
					line-height:1.5vw;
				}.payment-list-subheading{
					font-size:1vw;
					line-height:1.5vw;
					color:rgba(125, 125, 125);
				}.payment-list{
					padding:1.5vw 1vw;
					border-bottom:0.1vw solid rgba(240, 240, 240);
				}.invoice-link{
					color:rgba(2, 95, 105, 0.8);
					text-decoration:none;
					cursor:pointer;
					font-size:1vw;
				}.payment-list:last-child{
					border:none;
				}td h5{
					font-size:1.1vw;
				}td p{
					font-size:1vw;
					color:rgba(150,150,150);
				}.transaction-status{
					background:rgba(240, 240, 240);
					font-size:1vw;
					padding:0.5vw 1vw;
					border-radius:50vw;
					font-weight:600;
					color:rgba(50, 50, 50);
					border:0.1vw solid rgba(50, 50, 50);
				}.active-status{
					background:rgba(233, 247, 239);
					color:rgba(30, 132, 73);
				}.pending-status{
					background:rgba(240, 240, 240);
					color:rgba(15, 15, 15);
				}.failed-status{
					background:rgba(253, 237, 236);
					color:rgba(231, 76, 60);
				}.download-invoice{
					font-size:1vw;
					cursor:pointer;
				}.transaction-icon{
					width:100%;
					height:4vw;
					object-fit:contain;
				}

			}
	}.transaction-id{
					padding-top:1vw;
				}.input-forms{
					padding:0.5vw 0.8vw;
					border-radius:0.4vw;
					outline:none;
					font-size:1.1vw;
					border:0.2vw solid rgba(200, 200, 200);
				}.input-forms:focus{
				  border: 0.2vw solid rgba(2, 96, 105);
				}label{
					font-size:1vw;
					font-weight:500;
					display:block;
					margin-top:2vw;
					margin-bottom:0.8vw;
					color:rgb(75, 75, 75);
				}.primary-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(255, 255, 255);
				  border: 0.2vw solid rgba(2, 96, 105);
				  background: rgba(2, 96, 105);
				}
				.primary-outline-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(100, 100, 100);
				  border: 0.2vw solid rgba(200, 200, 200);
				  background: rgba(255, 255, 255);
				}.secondary-button {
				  border-radius: 0.4vw;
				  font-size: 1vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
					color:rgba(50, 50, 50);
					border:0.2vw solid rgba(230,230,230);
					background:rgba(230,230,230);
				}.error-message{
					font-size:1vw;
					font-weight:500;
					margin-top:0.5vw;
					color: rgba(231, 76, 60);
				}.success-icon{
					width:100%;
					height:10vw;
					margin-top:2vw;
					object-fit:contain;
				}.secondary-heading-modal{
					font-size:2vw;
					font-weight:600;
					margin-top:1vw;
				}.secondary-description-modal{
					font-size:1.2vw;
					line-height:2vw;
					font-weight:400;
					margin-bottom:1vw;
					margin-top:1vw;
				}.input-error, .input-error:focus{
					color:rgb(231, 76, 60);
					border:0.2vw solid rgb(231, 76, 60);
				}.empty-error-heading{
					font-size: 1.3vw;
				}.empty-error-subheading{
					font-size: 1vw;
				}.empty-error-area{
					padding:1vw 8vw;
				}
