.complete-setup-body{
	font-family: 'Poppins', sans-serif;
	margin: 0;
	padding: 0;
	height: 100%;
	overflow:hidden;
	.right-body img{
		width:100%;
		object-fit:cover;
		height:100vh;
		border-radius:1vw 0 0 1vw;
	}.left-body{
		padding:0vw 8vw;
		.main-heading{
			font-size: 24px;
			font-weight:600;
			margin:0.5vw 0;
		 }.main-subheading{
			font-size: 18px; /* Min 1rem, scales with 1.1vw, max 1.5rem */
			 color:rgba(125, 125, 125);
			margin:0.5vw 0;
		 }.top-area{
			 border-bottom:0.2vw solid rgba(240, 240, 240);
			 padding:1vw 0;
			 margin-bottom:0vw;
		 }label{
			 font-size: 16px;
			 display:block;
			 margin-top:1vw;
			 color:rgba(55, 55, 55);
			 font-weight:500;
			 margin-bottom:0.5vw;
			 margin-top:2vw;
		 }.step .block{
			 border:0.15vw solid rgba(238, 238, 238);
			 padding:1vw;
			 border-radius:0.4vw;
		 }.step .block img{
			  width:5vw;
			  height:5vw;
			  object-fit:contain;
		 }.block-heding{
			 font-size:1vw;
		 }.primary-button{
			margin-top: 30px;
			border-radius: clamp(0.3rem, 0.4vw, 0.6rem);
			font-size: 16px;
			font-weight: 500;
			padding: 8px 8px;
			color: rgba(255, 255, 255);
			border: clamp(0.1rem, 0.2vw, 0.3em) solid rgba(2, 96, 105); /* Smaller border width */
			background: rgba(2, 96, 105);
		  }.input-form{
			display: block;
			width: 100%;
			color: rgb(50, 50, 50);
			margin-top: clamp(0.4rem, 0.5vw, 1rem);
			border: none;
			background: rgb(255, 255, 255);
			border: 0.14rem solid rgb(220, 220, 220);
			border-radius: 0.4rem;
			padding: 10px 16px;
			font-size: 16px;
			font-weight: 500;
			outline: none;
		  }.country-select {
			  margin-top:0.4vw;
		  }.input-form:focus{
			  border:0.14vw solid rgba(2,96,105);
		  }.input-error, .input-error:focus{
			  border: 0.14vw solid rgb(231, 76, 60);
			  color: rgba(231, 76, 60);
		  }.error-message{
			  font-size:16px;
			  font-weight:500;
			  margin-top:1vw;
			  color: rgba(231, 76, 60);
		  }.primary-button:disabled{
			  opacity:0.6;
			  cursor:not-allowed;
		  }.change-email{
			  color: rgba(2,96,105);
			  font-size:1.1vw;
			  margin-left:0.2vw;
		  }.otp-error, .otp-error:focus{
			  background:rgba(253, 237, 236);
			  border: 0.2vw solid rgb(253, 237, 236);
			  color:rgba(231, 76, 60);
		  }.country-label{
			  margin-bottom:0.5vw;
		  }.select-box {
				font-family: 'Arial', sans-serif;
				font-size: 1rem;
				height: 3.1rem;
				color: #333;
				background-color: #fff;
				border-radius: 0.4rem;
				border: 0.1rem solid rgb(220, 220, 220);
				padding: 0rem;
			}
			
			/* Menu item styles */
			.select-box-item {
				font-size: 0.9rem;
				padding: 0.8rem 1rem;
				cursor: pointer;
				transition: background-color 0.3s ease;
			}
			
			.select-box-item:hover {
				background-color: #f5f5f5;
			}
			
			/* Typography within menu items */
			.select-box-typography {
				font-weight: 500;
				color: #555;
			}
			
			/* Box styling inside menu items */
			.select-box-box {
				display: flex;
				align-items: center;
			}  
	}
  }

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.complete-setup-body{
		.right-body{
					display:none;
				}.form-area{
					margin:5vw 0;
				}
	  font-family: 'Poppins', sans-serif;
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  overflow:hidden;
	  .right-body img{
		  width:100%;
		  object-fit:cover;
		  height:100vh;
		  border-radius:1vw 0 0 1vw;
	  }.left-body{
		  padding:0vw 4vw;
		  padding-top:5vw;
		  .main-heading{
			  font-size:5vw;
			  font-weight:600;
			  margin:2.5vw 0;
		   }.main-subheading{
			   font-size:3.5vw;
			   color:rgba(125, 125, 125);
			  margin:3.5vw 0;
		   }.top-area{
			   border-bottom:0.2vw solid rgba(240, 240, 240);
			   padding:1vw 0;
			   margin-bottom:0vw;
		   }label{
			   font-size:3.5vw;
				font-weight:500;
				display:block;
				color:rgb(75, 75, 75);
				margin:2vw 0;
		   }.step .block{
			   border:0.15vw solid rgba(238, 238, 238);
			   padding:1vw;
			   border-radius:0.4vw;
		   }.step .block img{
				width:5vw;
				height:5vw;
				object-fit:contain;
		   }.block-heding{
			   font-size:1vw;
		   }.primary-button{
				margin-top:5vw;
				border-radius: 0.8vw;
				font-size:4vw;
				font-weight:500;
				padding: 1.8vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.4vw solid rgba(2,96,105);
				background:rgba(2,96,105);
			}.input-form{
					display:block;
					width:100%;
					color: rgb(50, 50, 50);
					margin-top:1vw;
					margin-bottom:5vw;
					border: none;
					background:rgb(255, 255, 255);
					border: 0.4vw solid rgb(220, 220, 220);
					border-radius: 0.8vw;
					padding: 1.8vw 3vw;
					font-size: 4vw;
					font-weight:500;
					outline: none;
			}.country-select {
				margin-top:0.4vw;
				}.input-form:focus{
				border:0.14vw solid rgba(2,96,105);
			}.input-error, .input-error:focus{
				border: 0.4vw solid rgb(231, 76, 60);
				color: rgba(231, 76, 60);
			}.error-message{
				font-size:4vw;
				font-weight:500;
				margin-top:1vw;
				color: rgba(231, 76, 60);
			}.primary-button:disabled{
				opacity:0.6;
				cursor:not-allowed;
			}.change-email{
				color: rgba(2,96,105);
				font-size:1.1vw;
				margin-left:0.2vw;
			}.otp-error, .otp-error:focus{
				background:rgba(253, 237, 236);
				border: 0.2vw solid rgb(253, 237, 236);
				color:rgba(231, 76, 60);
			}.country-label{
				margin-bottom:0.5vw;
			}
	  
	  
	  }
	}
	
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.complete-setup-body{
		.right-body{
					display:none;
				}.form-area{
					margin:5vw 0;
				}
	  font-family: 'Poppins', sans-serif;
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  overflow:hidden;
	  .right-body img{
		  width:100%;
		  object-fit:cover;
		  height:100vh;
		  border-radius:1vw 0 0 1vw;
	  }.left-body{
		  padding:0vw 4vw;
		  padding-top:5vw;
		  .main-heading{
			  font-size:5vw;
			  font-weight:600;
			  margin:2.5vw 0;
		   }.main-subheading{
			   font-size:3.5vw;
			   color:rgba(125, 125, 125);
			  margin:3.5vw 0;
		   }.top-area{
			   border-bottom:0.2vw solid rgba(240, 240, 240);
			   padding:1vw 0;
			   margin-bottom:0vw;
		   }label{
			   font-size:3.5vw;
				font-weight:500;
				display:block;
				color:rgb(75, 75, 75);
				margin:2vw 0;
		   }.step .block{
			   border:0.15vw solid rgba(238, 238, 238);
			   padding:1vw;
			   border-radius:0.4vw;
		   }.step .block img{
				width:5vw;
				height:5vw;
				object-fit:contain;
		   }.block-heding{
			   font-size:1vw;
		   }.primary-button{
				margin-top:5vw;
				border-radius: 0.8vw;
				font-size:4vw;
				font-weight:500;
				padding: 1.8vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.4vw solid rgba(2,96,105);
				background:rgba(2,96,105);
			}.input-form{
					display:block;
					width:100%;
					color: rgb(50, 50, 50);
					margin-top:1vw;
					margin-bottom:5vw;
					border: none;
					background:rgb(255, 255, 255);
					border: 0.4vw solid rgb(220, 220, 220);
					border-radius: 0.8vw;
					padding: 1.8vw 3vw;
					font-size: 4vw;
					font-weight:500;
					outline: none;
			}.country-select {
				margin-top:0.4vw;
				}.input-form:focus{
				border:0.14vw solid rgba(2,96,105);
			}.input-error, .input-error:focus{
				border: 0.14vw solid rgb(231, 76, 60);
				color: rgba(231, 76, 60);
			}.error-message{
				font-size:1vw;
				font-weight:500;
				margin-top:1vw;
				color: rgba(231, 76, 60);
			}.primary-button:disabled{
				opacity:0.6;
				cursor:not-allowed;
			}.change-email{
				color: rgba(2,96,105);
				font-size:1.1vw;
				margin-left:0.2vw;
			}.otp-error, .otp-error:focus{
				background:rgba(253, 237, 236);
				border: 0.2vw solid rgb(253, 237, 236);
				color:rgba(231, 76, 60);
			}.country-label{
				margin-bottom:0.5vw;
			}
	  
	  
	  }
	}
	
}


@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.complete-setup-body{
		.right-body{
					display:none;
				}.form-area{
					margin:5vw 0;
				}
	  font-family: 'Poppins', sans-serif;
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  overflow:hidden;
	  .right-body img{
		  width:100%;
		  object-fit:cover;
		  height:100vh;
		  border-radius:1vw 0 0 1vw;
	  }.left-body{
		  padding:0vw 4vw;
		  padding-top:5vw;
		  .main-heading{
			  font-size:5vw;
			  font-weight:600;
			  margin:2.5vw 0;
		   }.main-subheading{
			   font-size:3.5vw;
			   color:rgba(125, 125, 125);
			  margin:3.5vw 0;
		   }.top-area{
			   border-bottom:0.2vw solid rgba(240, 240, 240);
			   padding:1vw 0;
			   margin-bottom:0vw;
		   }label{
			   font-size:3.5vw;
				font-weight:500;
				display:block;
				color:rgb(75, 75, 75);
				margin:2vw 0;
		   }.step .block{
			   border:0.15vw solid rgba(238, 238, 238);
			   padding:1vw;
			   border-radius:0.4vw;
		   }.step .block img{
				width:5vw;
				height:5vw;
				object-fit:contain;
		   }.block-heding{
			   font-size:1vw;
		   }.primary-button{
				margin-top:5vw;
				border-radius: 0.8vw;
				font-size:4vw;
				font-weight:500;
				padding: 1.8vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.4vw solid rgba(2,96,105);
				background:rgba(2,96,105);
			}.input-form{
					display:block;
					width:100%;
					color: rgb(50, 50, 50);
					margin-top:1vw;
					margin-bottom:5vw;
					border: none;
					background:rgb(255, 255, 255);
					border: 0.4vw solid rgb(220, 220, 220);
					border-radius: 0.8vw;
					padding: 1.8vw 3vw;
					font-size: 4vw;
					font-weight:500;
					outline: none;
			}.country-select {
				margin-top:0.4vw;
				}
	  
	  
	  }
	}
	
}



