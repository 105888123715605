.faq-area{
/* Global Styles */
font-family: 'Poppins', sans-serif;
background-color: rgba(250, 250, 250);
height: auto;

/* Top Area */
.top-area {
    padding: clamp(2rem, 5vw, 8rem) clamp(2rem, 5vw, 8rem);
    color: rgba(255, 255, 255);
    background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
}

.top-area h1 {
    font-weight: 500;
	font-size: 1.4vw;
    font-size: clamp(1.5rem, 2vw, 3rem);
}

.top-area h4 {
    font-weight: 400;
	margin:1vw 0;
    font-size: clamp(1rem, 1.2vw, 2rem);
    color: rgba(241, 241, 241);
}

/* Search */
.search {
    width: 100%;
    padding: clamp(0.5rem, 1vw, 1.5rem) clamp(1rem, 1.5vw, 2rem);
    border: none;
    font-size: clamp(1rem, 1vw, 1.5rem);
    outline: none;
    margin: clamp(0.5rem, 1vw, 2rem) 0;
    border-radius: clamp(0.3vw, 0.4vw, 0.5vw);
    font-weight: 600;
    color: rgba(0, 0, 0);
    background-color: rgba(255, 255, 255);
}

.search::placeholder {
    color: rgba(100, 100, 100);
    font-weight: 500;
    opacity: 0.7; /* Firefox */
}

.search:focus {
    background-color: rgba(250, 250, 250);
    color: rgba(0, 0, 0);
}

/* Container for Search */
.container-search {
    padding: clamp(1rem, 2vw, 4rem) clamp(5rem, 10vw, 15vw);
}

/* Result Area */
.result-area {
    padding: clamp(1rem, 2vw, 4rem) clamp(7rem, 14vw, 20vw);
}

.result-area-heading {
    font-size: clamp(1.2rem, 1.2vw, 2rem);
    font-weight: 500;
}

/* Result List */
.result-list {
    border: clamp(0.1rem, 0.2vw, 0.3vw) solid rgba(241, 241, 241);
    padding: clamp(1rem, 1.5vw, 2rem);
    margin: clamp(1rem, 2vw, 3rem) 0;
    background-color: rgba(255, 255, 255);
    border-radius: clamp(0.4vw, 0.5vw, 0.6vw);
}

.result-heading {
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    line-height: clamp(2rem, 3vw, 4rem);
    font-weight: 600;
    color: rgba(0, 0, 0);
}

.result-subheading {
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    line-height: clamp(1.5rem, 1.8vw, 2rem);
    font-weight: 400;
    color: rgba(150, 150, 150);
}

.result-date-author {
    margin-top: clamp(0.5rem, 1vw, 2rem);
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    color: rgba(150, 150, 150);
    font-weight: 400;
}

.result-date-author span {
    color: rgba(0, 0, 0);
}

.result-list:hover {
    border: clamp(0.1rem, 0.2vw, 0.3vw) solid rgba(0, 0, 0);
    background-color: rgba(255, 255, 255);
    cursor: pointer;
}

/* Contact Support Area */
.contact-support-area {
    background-color: rgba(251, 251, 251);
    margin: clamp(1rem, 2vw, 4rem) 0;
    padding: clamp(1rem, 2vw, 3rem);
    border-radius: clamp(0.4vw, 0.5vw, 0.6vw);
}

.contact-support-heading {
    font-weight: 500;
    font-size: clamp(1.2rem, 1.2vw, 2rem);
}

.contact-support-subheading {
    font-weight: 400;
    font-size: clamp(0.8rem, 0.8vw, 1.2rem);
    color: rgba(95, 95, 95);
}

/* Contact Support Button */
.contact-support-button {
    background: rgba(2, 96, 105);
    border: clamp(0.1rem, 0.2vw, 0.3vw) solid rgba(2, 96, 105);
    font-weight: 500;
    font-size: clamp(0.8rem, 0.8vw, 1.2rem);
    padding: clamp(0.3rem, 0.5vw, 0.8rem) clamp(0.5rem, 1vw, 1.5rem);
    color: white;
    border-radius: clamp(0.4vw, 0.5vw, 0.6vw);
}

/* Support Avatar */
.support-avatar {
    width: clamp(3rem, 4vw, 6rem);
    height: clamp(3rem, 4vw, 6rem);
    object-fit: cover;
    border-radius: 50%;
}

/* Contact Desktop and Mobile */
#contact_desktop {
    display: block;
}

#contact_mobile {
    display: none;
}

/* FAQ Container */
.faq-container {
    background: rgba(255, 255, 255);
    padding: clamp(1rem, 2.5vw, 4rem) clamp(1rem, 2.5vw, 4rem);
    border-radius: clamp(0.4vw, 0.5vw, 0.6vw);
}

/* FAQ Title */
.faq-title {
    font-weight: 600;
    font-size: clamp(1.5rem, 1.2vw, 2.5rem);
    margin: clamp(0.5rem, 1vw, 1.5rem) 0;
    line-height: clamp(2rem, 2vw, 5rem);
    display: block;
    padding-bottom: clamp(0.5rem, 1vw, 1.5rem);
    border-bottom: clamp(0.1rem, 0.14vw, 0.2vw) solid rgba(241, 241, 241);
    letter-spacing: 0.02em;
}

/* FAQ Date and Author */
.faq-date {
    color: rgba(75, 75, 75);
    font-size: clamp(0.8rem, 0.8vw, 1.2rem);
    font-weight: 500;
    margin-right: clamp(0.5rem, 1vw, 1rem);
}

.author-name {
    color: rgba(75, 75, 75);
    font-size: clamp(0.8rem, 0.8, 1.2rem);
    font-weight: 500;
    margin: 0 clamp(0.5rem, 1vw, 1rem);
}

.author-name b {
    color: rgba(61, 61, 61);
    cursor: pointer;
}

/* FAQ Content */
.faq-start-area p {
    font-size: clamp(0.5rem, 0.73vw, 1rem);
}

.faq-start-area h2, h3, h4, h5, h6 {
    font-weight: 600;
}

.faq-start-area h2 {
    font-size: clamp(0.7rem, 1.04vw, 1.5rem);
}

.faq-start-area h3 {
    font-size: clamp(0.7rem, 0.94vw, 1.2rem);
}

.faq-start-area h4 {
    font-size: clamp(0.6rem, 0.83vw, 1.1rem);
}

.faq-start-area h5 {
    font-size: clamp(0.5rem, 0.73vw, 1rem);
}

.faq-start-area h6 {
    font-size: clamp(0.4rem, 0.63vw, 0.9rem);
}

/* Links */
a {
    cursor: pointer;
}

/* Comment Section */
.comment-section {
    background-color: rgba(255, 255, 255);
    padding: clamp(1rem, 3vw, 5rem);
    width: 100%;
    border-radius: clamp(0.4vw, 0.5vw, 0.6vw);
    margin: clamp(1rem, 2.5vw, 4rem) 0;
}

/* Breadcrumb */
.breadcrumb-item {
    font-size: clamp(1rem, 1vw, 1.5rem);
}

}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.faq-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		height:auto;
	.top-area{
	  padding:5vw 2vw;
	  color:rgba(255, 255, 255);
	  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
  }.breadcrumb-item{
		font-size:4vw;  
	  }.top-area h1{
	  font-weight:500;
	  font-size:4.5vw;
  }.top-area h4{
	  font-weight:400;
	  font-size:2.5vw;
	  color:rgba(241, 241, 241);
  }.search{
	  width:100%;
	  padding:1vw 3vw;
	  border:none;
	  font-size:3vw;
	  outline:none;
	  margin:0.5vw 0;
	  border-radius:0.4vw;
	  font-weight:600;
	  color:rgba(0, 0, 0);
	  background-color:rgba(255,255,255);
  }::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(100,100,100);
  font-weight:500;
  opacity: 0.7; /* Firefox */
}.search:focus{
	background-color:rgba(250,250,250);
	color:rgba(0,0,0);
}.container-search{
	padding:2vw 5vw;
}.result-area{
	padding:2.5vw 2.5vw;
}.result-area-heading{
	font-size:3.5vw;
	margin-top:4vw;
	font-weight:500;
}.result-list{
	border:0.5vw solid rgba(241, 241, 241);
	padding:2.5vw;
	margin:2vw 0;
	background-color:rgba(255,255,255);
	border-radius:0.5vw;
}.result-heading{
	font-size:2.5vw;
	line-height:4vw;
	font-weight:600;
	color:rgba(0,0,0);
}.result-subheading{
	font-size:2.2vw;
	line-height:4vw;
	font-weight:400;
	color:rgba(150, 150, 150);
}.result-date-author{
	margin-top:1vw;
	font-size:2.2vw;
	color:rgba(150, 150, 150);
	font-weight:400;
}.result-date-author span{
	color:rgba(0, 0, 0);
}.result-list:hover{
	border:0.5vw solid rgba(0, 0, 0);
	background-color:rgba(255,255,255);
	cursor:pointer;
}.contact-support-area{
	background-color:rgba(251,251,251);
	  margin:2vw 0;
	  padding:4vw 8vw;
	  border-radius:0.5vw;
  }.contact-support-heading{
	  font-weight:500;
	  font-size:3vw;
  }.contact-support-subheading{
	  
	  font-weight:400;
	  font-size:2.5vw;
	  line-height:4.5vw;
	  color:rgba(95,95,95);
  }.contact-support-button{
	  background:rgba(2, 96, 105);
	  border:0.1vw solid rgba(2, 96, 105);
	  font-weight:500;
	  font-size:2.5vw;
	  padding:1vw 2vw;
	  color:rgba(254, 254, 254);
	  border-radius:1vw;
  }.support-avatar{
	  width:15vw;height:15vw;object-fit:cover;border-radius:50%;
  }#contact_desktop{
	  display:none;
  }#contact_mobile{
	  display:block;
  }.faq-container{
		  background:rgba(255,255,255);
		  padding:3.5vw 3.5vw;
		  border-radius:0.8vw;
	  }.faq-title{
	  font-weight:600;
	  font-size:4vw;
	  margin:5vw 0;
	  line-height:6.5vw;
	  display:block;
	  padding-bottom:1vw;
	  border-bottom:0.14vw solid rgba(241, 241, 241);
		letter-spacing: 0.02em;
  }.faq-date{
	  color:rgba(75, 75, 75);
	  font-size:3.5vw;
	  font-weight:500;
	  margin-right:2vw;
  }.author-name{
  color:rgba(75, 75, 75);
	  font-size:3.5vw;
  font-weight:500;
	  margin:0 1vw;
  }.author-name b{
	  color:rgba(61, 61, 61);
	  cursor:pointer;
  }.faq-start-area p {
    font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h2, h3, h4, h5, h6 {
		font-weight: 600;
	}

	.faq-start-area h2 {
		font-size: 1.04vw; /* equivalent to 20px */
	}

	.faq-start-area h3 {
		font-size: 0.94vw; /* equivalent to 18px */
	}

	.faq-start-area h4 {
		font-size: 0.83vw; /* equivalent to 16px */
	}

	.faq-start-area h5 {
		font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h6 {
		font-size: 0.63vw; /* equivalent to 12px */
	}a{
		  cursor:pointer;
	  }.comment-section{
		background-color:rgba(255,255,255);
		padding:3vw;
		width:100%;
		border-radius:0.5vw;
		margin:2.5vw 0;
	  }
}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.faq-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		height:auto;
	.top-area{
	  padding:5vw 2vw;
	  color:rgba(255, 255, 255);
	  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
  }.breadcrumb-item{
		font-size:4vw;  
	  }.top-area h1{
	  font-weight:500;
	  font-size:4.5vw;
  }.top-area h4{
	  font-weight:400;
	  font-size:2.5vw;
	  color:rgba(241, 241, 241);
  }.search{
	  width:100%;
	  padding:1vw 3vw;
	  border:none;
	  font-size:3vw;
	  outline:none;
	  margin:0.5vw 0;
	  border-radius:0.4vw;
	  font-weight:600;
	  color:rgba(0, 0, 0);
	  background-color:rgba(255,255,255);
  }::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(100,100,100);
  font-weight:500;
  opacity: 0.7; /* Firefox */
}.search:focus{
	background-color:rgba(250,250,250);
	color:rgba(0,0,0);
}.container-search{
	padding:2vw 5vw;
}.result-area{
	padding:2.5vw 2.5vw;
}.result-area-heading{
	font-size:3.5vw;
	margin-top:4vw;
	font-weight:500;
}.result-list{
	border:0.5vw solid rgba(241, 241, 241);
	padding:2.5vw;
	margin:2vw 0;
	background-color:rgba(255,255,255);
	border-radius:0.5vw;
}.result-heading{
	font-size:2.5vw;
	line-height:4vw;
	font-weight:600;
	color:rgba(0,0,0);
}.result-subheading{
	font-size:2.2vw;
	line-height:4vw;
	font-weight:400;
	color:rgba(150, 150, 150);
}.result-date-author{
	margin-top:1vw;
	font-size:2.2vw;
	color:rgba(150, 150, 150);
	font-weight:400;
}.result-date-author span{
	color:rgba(0, 0, 0);
}.result-list:hover{
	border:0.5vw solid rgba(0, 0, 0);
	background-color:rgba(255,255,255);
	cursor:pointer;
}.contact-support-area{
	background-color:rgba(251,251,251);
	  margin:2vw 0;
	  padding:4vw 8vw;
	  border-radius:0.5vw;
  }.contact-support-heading{
	  font-weight:500;
	  font-size:3vw;
  }.contact-support-subheading{
	  
	  font-weight:400;
	  font-size:2.5vw;
	  line-height:4.5vw;
	  color:rgba(95,95,95);
  }.contact-support-button{
	  background:rgba(2, 96, 105);
	  border:0.1vw solid rgba(2, 96, 105);
	  font-weight:500;
	  font-size:2.5vw;
	  padding:1vw 2vw;
	  color:rgba(254, 254, 254);
	  border-radius:1vw;
  }.support-avatar{
	  width:15vw;height:15vw;object-fit:cover;border-radius:50%;
  }#contact_desktop{
	  display:none;
  }#contact_mobile{
	  display:block;
  }.faq-container{
		  background:rgba(255,255,255);
		  padding:3.5vw 3.5vw;
		  border-radius:0.8vw;
	  }.faq-title{
	  font-weight:600;
	  font-size:4vw;
	  margin:5vw 0;
	  line-height:6.5vw;
	  display:block;
	  padding-bottom:1vw;
	  border-bottom:0.14vw solid rgba(241, 241, 241);
		letter-spacing: 0.02em;
  }.faq-date{
	  color:rgba(75, 75, 75);
	  font-size:3.5vw;
	  font-weight:500;
	  margin-right:2vw;
  }.author-name{
  color:rgba(75, 75, 75);
	  font-size:3.5vw;
  font-weight:500;
	  margin:0 1vw;
  }.author-name b{
	  color:rgba(61, 61, 61);
	  cursor:pointer;
  }.faq-start-area p {
    font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h2, h3, h4, h5, h6 {
		font-weight: 600;
	}

	.faq-start-area h2 {
		font-size: 1.04vw; /* equivalent to 20px */
	}

	.faq-start-area h3 {
		font-size: 0.94vw; /* equivalent to 18px */
	}

	.faq-start-area h4 {
		font-size: 0.83vw; /* equivalent to 16px */
	}

	.faq-start-area h5 {
		font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h6 {
		font-size: 0.63vw; /* equivalent to 12px */
	}a{
		  cursor:pointer;
	  }.comment-section{
		background-color:rgba(255,255,255);
		padding:3vw;
		width:100%;
		border-radius:0.5vw;
		margin:2.5vw 0;
	  }
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.faq-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		height:auto;
	.top-area{
	  padding:5vw 2vw;
	  color:rgba(255, 255, 255);
	  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
  }.breadcrumb-item{
		font-size:4vw;  
	  }.top-area h1{
	  font-weight:500;
	  font-size:4.5vw;
  }.top-area h4{
	  font-weight:400;
	  font-size:2.5vw;
	  color:rgba(241, 241, 241);
  }.search{
	  width:100%;
	  padding:1vw 3vw;
	  border:none;
	  font-size:3vw;
	  outline:none;
	  margin:0.5vw 0;
	  border-radius:0.4vw;
	  font-weight:600;
	  color:rgba(0, 0, 0);
	  background-color:rgba(255,255,255);
  }::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(100,100,100);
  font-weight:500;
  opacity: 0.7; /* Firefox */
}.search:focus{
	background-color:rgba(250,250,250);
	color:rgba(0,0,0);
}.container-search{
	padding:2vw 5vw;
}.result-area{
	padding:2.5vw 2.5vw;
}.result-area-heading{
	font-size:3.5vw;
	margin-top:4vw;
	font-weight:500;
}.result-list{
	border:0.5vw solid rgba(241, 241, 241);
	padding:2.5vw;
	margin:2vw 0;
	background-color:rgba(255,255,255);
	border-radius:0.5vw;
}.result-heading{
	font-size:2.5vw;
	line-height:4vw;
	font-weight:600;
	color:rgba(0,0,0);
}.result-subheading{
	font-size:2.2vw;
	line-height:4vw;
	font-weight:400;
	color:rgba(150, 150, 150);
}.result-date-author{
	margin-top:1vw;
	font-size:2.2vw;
	color:rgba(150, 150, 150);
	font-weight:400;
}.result-date-author span{
	color:rgba(0, 0, 0);
}.result-list:hover{
	border:0.5vw solid rgba(0, 0, 0);
	background-color:rgba(255,255,255);
	cursor:pointer;
}.contact-support-area{
	background-color:rgba(251,251,251);
	  margin:2vw 0;
	  padding:4vw 8vw;
	  border-radius:0.5vw;
  }.contact-support-heading{
	  font-weight:500;
	  font-size:3vw;
  }.contact-support-subheading{
	  
	  font-weight:400;
	  font-size:2.5vw;
	  line-height:4.5vw;
	  color:rgba(95,95,95);
  }.contact-support-button{
	  background:rgba(2, 96, 105);
	  border:0.1vw solid rgba(2, 96, 105);
	  font-weight:500;
	  font-size:2.5vw;
	  padding:1vw 2vw;
	  color:rgba(254, 254, 254);
	  border-radius:1vw;
  }.support-avatar{
	  width:15vw;height:15vw;object-fit:cover;border-radius:50%;
  }#contact_desktop{
	  display:none;
  }#contact_mobile{
	  display:block;
  }.faq-container{
		  background:rgba(255,255,255);
		  padding:3.5vw 3.5vw;
		  border-radius:0.8vw;
	  }.faq-title{
	  font-weight:600;
	  font-size:4vw;
	  margin:5vw 0;
	  line-height:6.5vw;
	  display:block;
	  padding-bottom:1vw;
	  border-bottom:0.14vw solid rgba(241, 241, 241);
		letter-spacing: 0.02em;
  }.faq-date{
	  color:rgba(75, 75, 75);
	  font-size:3.5vw;
	  font-weight:500;
	  margin-right:2vw;
  }.author-name{
  color:rgba(75, 75, 75);
	  font-size:3.5vw;
  font-weight:500;
	  margin:0 1vw;
  }.author-name b{
	  color:rgba(61, 61, 61);
	  cursor:pointer;
  }.faq-start-area p {
    font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h2, h3, h4, h5, h6 {
		font-weight: 600;
	}

	.faq-start-area h2 {
		font-size: 1.04vw; /* equivalent to 20px */
	}

	.faq-start-area h3 {
		font-size: 0.94vw; /* equivalent to 18px */
	}

	.faq-start-area h4 {
		font-size: 0.83vw; /* equivalent to 16px */
	}

	.faq-start-area h5 {
		font-size: 0.73vw; /* equivalent to 14px */
	}

	.faq-start-area h6 {
		font-size: 0.63vw; /* equivalent to 12px */
	}a{
		  cursor:pointer;
	  }.comment-section{
		background-color:rgba(255,255,255);
		padding:3vw;
		width:100%;
		border-radius:0.5vw;
		margin:2.5vw 0;
	  }
}

}

