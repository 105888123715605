/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
}

/* Laptops and desktops */
@media only screen and (min-width: 1024px) {
			.page-content {
				font-family: 'Poppins', sans-serif;
				background-color: rgba(255, 255, 255);
			.main-heading{
				 font-size:1.5vw;
				 font-weight:600;
			}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item{
					font-size:1vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.main-subheading{
				 font-size:1.05vw;
				 font-weight:500;
				 color:rgba(125, 125, 125);
			}.header-area{
				padding:2vw;
			}.primary-button{
				border-radius: 0.4vw;
				font-size:1vw;
				font-weight:500;
				padding: 0.5vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(2,96,105);
			}.top-area{
				background:rgba(245, 245, 245);
				padding:2vw 2vw;
				
				border-radius:0.8vw;
			}.filter-area{
				padding:0vw 2vw;
				margin:0vw 0;
				padding-bottom:2vw;
				border-bottom:0.1vw solid rgba(240, 240, 240);
			}.select-input-form {
				display: flex;
				align-items: center;
				width: 100%;
				color: rgb(80, 80, 80);
				margin-top: 0.5vw;
				border: 0.14vw solid rgb(220, 220, 220);
				border-radius: 0.4vw;
				background: rgb(255, 255, 255);
				padding: 0.3vw 0.2vw 0.2vw 0.8vw;
				font-size: 2vw;
				font-weight: 500;
				outline: none;
			}.input-form{
				font-size:1vw;
			}

			.select-input-form .bi {
				margin-right: 1vw;
				font-size:1.5vw;
				/* Adjust space between icon and select box */
			}label{
				font-size:1vw;
				font-weight:500;
				display:block;
				margin-bottom:0.6vw;
				color:rgb(75, 75, 75);
			}.tab-area{
				padding:0 1vw;
				border-bottom:0.1vw solid rgba(240, 240, 240);
			}.tab-area .campaign-status-tab{
				display:inline-block;
				text-decoration:none;
				font-size:1.1vw;
				padding:1.5vw 1vw;
				font-weight:500;
				color:rgba(140, 140, 140);
			}.tab-area .campaign-status-tab .active{
				border-bottom:0.2vw solid rgba(2,96,105);
				padding:1.5vw 1.2vw;
				color:rgba(2,96,105);
			}.tab-area .campaign-status-tab .number{
				background:rgba(240, 240, 240);
				border:0.1vw solid rgba(230, 230, 230);
				padding:0.2vw 0.4vw;
				color:rgba(10, 10, 10);
				margin-left:0.5vw;
				font-size:0.8vw;
				border-radius:0.4vw;
			}
			}
			.email-campaigns-main-area{
				padding:1.5vw 2vw;
				background:rgba(248, 248, 248);
				
				.secondary-heading{
					font-size:1.5vw;
					font-weight:600;
				}.list-area{
					box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
					margin:1vw 0vw;
					background:rgba(255, 255, 255);
					padding:1vw 1.5vw;
					border-radius:0.8vw;
					cursor:pointer;
					border:0.2vw solid rgba(250, 250, 250);
				}.list-area:hover{
					border:0.2vw solid rgba(2,96,105);
				}.campaign-top-area{
					padding:1vw 0;
					border-bottom:0.1vw solid rgba(230, 230, 230);
				}.campaign-title{
					padding-top:0.4vw;
					font-size:1.2vw;
					font-weight:600;
					display:block;
					color: rgba(10, 10, 10);
					margin-bottom:0.8vw;
				}.campaign-description{
					font-size:0.98vw;
					font-weight:500;
					display:block;
					color:rgba(120, 120, 120);
					letter-spacing:0.02rem;
				}.campaign-bottom-area{
					padding-top:1.5vw;
				}.campaign-metric-column{
					text-align:center;
					border-left:0.2vw solid rgba(240, 240, 240);
				}.metric-heading{
					font-size:1.3vw;
					font-weight:600;
				}.metric-heading span{
					font-size:1vw;
					font-weight:500;
					color:rgba(50, 50, 50);
				}.metric-subheading{
					font-size:1vw;
					font-weight:500;
					color:rgba(125, 125, 125);
				}.campaign-status{
					background:rgba(240, 240, 240);
					font-size:1vw;
					padding:0.4vw 1vw 0.4vw 0.6vw;
					border-radius:50vw;
					font-weight:600;
					color:rgba(50, 50, 50);
					border:0.1vw solid rgba(50, 50, 50);
				}.campaign-status .bi{
					-webkit-text-stroke: 0.1vw;
				}.scheduled-status{
					background:rgba(254, 245, 231);
					color:rgba(243, 156, 18);
					border: 0.1vw solid rgba(243, 156, 18);
				}.sent-status{
					background:rgba(233, 247, 239);
					color:rgba(30, 132, 73);
					border: 0.1vw solid rgba(30, 132, 73);
				}.archived-status{
					background:rgba(253, 237, 236);
					color:rgba(231, 76, 60);
					border: 0.1vw solid rgba(231, 76, 60);
				}.dorpdown-menu i{
					font-size:1.5vw;
					color:rgba(100, 100, 100);
					padding:0.2vw 0.4vw;
				}.dorpdown-menu i:hover{
					background:rgba(240, 240, 240);
					padding:0.2vw 0.4vw;
					border-radius:0.5vw;
				}.campaign-tag-icon i{
					background:rgba(240, 240, 240);
					font-size:2vw;
					padding: 0.5vw;
					text-align:center;
					float:center;
					color:rgba(50, 50, 50);
					border-radius:0.4vw;
					display:inline-block;
					width:100%;
				}/* Custom Dropdown Styles */
				.dropdown-container {
				  position: relative;
				}

				.dropdown-toggle {
					background:none;
					border:none;
					color:rgba(10, 10, 10);
					font-size:2vw;
				}

				.dropdown-menu {
					background-color: rgba(255, 255, 255); /* Change to your desired background color */
					left: 3vw; /* Align dropdown to the left side of the container */
					right: 0; /* Remove right alignment */
					color: rgba(100, 100, 100);
					position: absolute; /* Position the dropdown absolutely within the container */
					bottom: 3.5vw; /* Position the dropdown below the button */
					transform: translateX(-100%); /* Move the dropdown to the left side of the button */
					border: none; /* Optional: Remove border */
					box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
				}

/* Customize hover style on the dropdown button */
				.dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle.show {
					background-color: rgba(245, 245, 245); /* Change background color on hover */
					color: rgba(100, 100, 100); /* Change text color on hover */
					border-color: #007bff; /* Change border color on hover */
					/* Add any other styles you want */
					border-radius:0.5vw;
				}

				/* Hide the default Bootstrap chevron */
				.dropdown-toggle::after {
					display: none;
				}

				.dropdown-menu.show {
				  display: block;
				}

				.dropdown-menu li a {
				  color:rgba(100, 100, 100);
				  font-weight:500;
				  text-decoration:none;
				  padding: 0.4vw 0.8vw; /* Adjust padding */
				  text-align: left; /* Align text to the left */
				  display:block;
				  font-size:1.1vw;
				}.dropdown-menu li a:hover {
				  background-color: rgba(220, 220, 220); /* Change hover color */
				}.line-break{
					border-bottom:0.001vw solid rgba(220, 220, 220);
					margin:0.4vw 0;
				}.modal-overlay {
				  position: fixed;
				  top: 0;
				  padding:150vw;
				  left: 0;
				  width: 100vw;
				  height: 100vh;
				  background: red; /* Semi-transparent background */
				  z-index: 1040; /* Ensure it is behind the modal */
				}.all {
				  background: rgba(200, 200, 200, 0.5);
				}

				.newsletter i{
				  color:rgba(255, 255, 255);
				  background: rgba(245, 9, 113, 0.7);
				}

				.promotional i {
				  color:rgba(255, 255, 255);
				  background: rgba(9, 84, 245, 0.7);
				}

				.transactional i {
				  color:rgba(255, 255, 255);
				  background: rgba(5, 221, 165, 0.7);
				}

				.automated i{
				  background: rgba(255, 165, 0, 0.7);
				}

				.event-invitations i{
				  color:rgba(10, 10, 10);
				  background: rgba(255, 255, 0, 0.7);
				}

				.survey i {
				  color:rgba(255, 255, 255);
				  background: rgba(0, 123, 255, 0.7);
				}

				.announcement i {
				  color:rgba(255, 255, 255);
				  background: rgba(255, 0, 0, 0.7);
				}

				.others i {
				  color:rgba(255, 255, 255);
				  background: rgba(128, 128, 128, 0.7);
				}

				.campaign-tag-icon:hover {
				  background: rgba(255, 255, 255, 0.2); /* Light hover effect */
				}

			}
}


