
				.campaign-overview-body{
					  font-family: "Poppins", sans-serif;
					  height:100%;
					  background:rgba(255, 255, 255);
				.breadcrumb .breadcrumb-item{
							font-size:1vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.top-area{
					padding:2vw;
				}.campaign-heading{
					font-size:1.2vw;
				}.campaign-status{
					background:rgba(240, 240, 240);
					font-size:0.8vw;
					padding:0.2vw 1vw 0.2vw 0.4vw;
					border-radius:50vw;
					font-weight:600;
					margin-left:0.5vw;
					color:rgba(50, 50, 50);
					border:0.1vw solid rgba(50, 50, 50);
				}.campaign-status .bi{
					-webkit-text-stroke: 0.1vw;
				}.scheduled-status{
					background:rgba(254, 245, 231);
					color:rgba(243, 156, 18);
					border: 0.1vw solid rgba(243, 156, 18);
				}.sent-status{
					background:rgba(233, 247, 239);
					color:rgba(30, 132, 73);
					border: 0.1vw solid rgba(30, 132, 73);
				}.archived-status{
					background:rgba(253, 237, 236);
					color:rgba(231, 76, 60);
					border: 0.1vw solid rgba(231, 76, 60);
				}.primary-button {
					  border-radius: 0.4vw;
					  font-size: 1vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(255, 255, 255);
					  border: 0.2vw solid rgba(2, 96, 105);
					  background: rgba(2, 96, 105);
					}.secondary-button {
					  border-radius: 0.4vw;
					  font-size: 1vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(50, 50, 50);
					  border: 0.2vw solid rgba(240, 240, 240);
					  background: rgba(240, 240, 240);
					}.secondary-outline-button {
					  border-radius: 0.4vw;
					  font-size: 1vw;
					  font-weight: 500;
					  padding: 0.5vw 1.2vw;
					  color: rgba(50, 50, 50);
					  border: 0.2vw solid rgba(240, 240, 240);
					  background: rgba(255, 255, 255);
					}.tab-area{
						margin:0 2vw;
						padding-bottom:0.5vw;
						border-bottom:0.2vw solid rgba(250, 250, 250);
					}.tab-area a{
						padding:0.5vw 1vw;
						color:rgba(150, 150, 150);
						text-decoration:none;
						font-weight:500;
						margin-right:0.2vw;
						font-size:0.9vw;
					}.tab-area .active{
						color:rgba(2, 96, 105);
						border-bottom:0.2vw solid rgba(2, 96, 105);
						font-weight:500;
					}.tab-area a:hover{
						color:rgba(2, 96, 105);
					}.metrics-col-area{
						padding:2vw 2vw;
					}.metrics-col{
						border-radius:0.4vw;
						border:0.15vw solid rgba(240, 240, 240);
					}.metrics-col-head-area{
						border-bottom:0.15vw solid rgba(240, 240, 240);
						padding:1vw;
						padding-bottom:0.5vw;
						margin-bottom:1vw;
					}.metrics-col-label{
						font-size:1vw;
						margin-bottom:1vw;
						color:rgba(150, 150, 150);
					}.metrics-col-heading{
						font-size:1.4vw;
					}.metrics-col-heading span{
						font-size:0.8vw;
						color:rgba(150, 150, 150);
					}.metrics-col img{
						width:100%;
						height:8vw;
						object-fit:fill;
						padding:0.5vw;
					}.basic-info-area{
						padding:2vw 1.5vw;
						margin:0 2vw;
						border-radius:0.4vw;
						border:0.15vw solid rgba(240, 240, 240);
					}.info-label{
						font-size:0.8vw;
						font-weight:500;
						color:rgba(150, 150, 150);
					}.info{
						color:rgba(10, 10, 10);
						font-size: 0.8vw;
					}.info-area{
						margin-bottom:1vw;
					}.email-preview{
						border-radius:0.4vw;
						width:100%;height:20vw;object-fit:contain;
						border:0.15vw solid rgba(240, 240, 240);
						padding:1vw;
					}.table-area{
						padding:2vw;
					}table {
					width: 100%;
					border:0.1vw solid #ddd;
					border-collapse: separate; /* Allows border-radius to work */
					border-spacing: 0; /* Removes spacing between cells */
					border-radius: 0.4vw; /* Rounds the corners of the table */
					overflow: hidden; /* Ensures the border-radius applies to the table content */
				}

				th, td {
					padding: 1.2vw;
					text-align: left;
					border-top: 0.1vw solid rgba(240, 240, 240);
					border-radius: 0; /* No border-radius on cells */
				}

				thead {
					background-color: rgba(150, 150, 150);
				}

				th {
					background-color: rgba(240, 240, 240);
					color: rgba(50, 50, 50);
					font-size:0.8vw;
					font-weight:500;
				}table .domain-email-name{
					font-size:0.9vw;
				}table .memeber-email{
					font-size:0.8vw;
					color:rgba(125, 125, 125);
				}table td{
					font-size:0.8vw;
				}table .member-icon{
					width:4.2vw;
					height:4.2vw;
					border-radius:50vw;
					border:0.1vw solid rgba(240, 240, 240);
					object-fit:cover;
				}table td .bi{
					font-size:1.2vw;
					margin:0 0.5vw;
					color:rgba(100, 100, 100);
				}.pagination-area{
					padding:2vw 0;
				}.pagination-area button{
					background-color: rgba(255, 255, 255);
					color: rgba(50, 50, 50);
					padding:0.5vw 1vw;
					border-radius:0.5vw;
					font-weight:500;
					font-size: 0.8vw;
					border:0.14vw solid rgba(220, 220, 220);
				}.pagination-area button:disabled{
					background-color: rgba(245, 245, 245);
					color: rgba(50, 50, 50);
					padding:0.5vw 1.5vw;
					border-radius:0.5vw;
					font-weight:500;
					opacity:0.5;
					cursor:not-allowed;
				}.pagination-area .active{
					background-color: rgba(255, 255, 255);
					color: rgba(50, 50, 50);
					padding:0.5vw 1.5vw;
					border-radius:0.5vw;
					font-weight:500;
					border:0.2vw solid rgba(220, 220, 220);
					opacity:0.5;
					cursor:not-allowed;
				}.pagination-numbers button{
					padding:0.5vw 1.5vw;
					margin:0 0.4vw;
				}.pagination-area button:hover{
					opacity:0.5;
				}.pagination-numbers .active, .pagination-numbers .active:hover{
					border:0.2vw solid rgba(230, 230, 230);
					background:rgba(230, 230, 230);
					cursor:not-allowed;
					opacity:1;
				}
				.top-table-area {
					padding: 2vw 2vw;
				  }

				  .top-table-heading {
					font-weight: 600;
					display: block;
					color: #474747;
					line-height: 0px;
					font-size: 1.5vw;
					line-height:1.8vw;
				  }.empty-area{
			padding:0px 20%;
		}.empty-area h3{
			font-size:24px;
			font-weight:600;
		}.empty-area p {
			font-size:14px;
			opacity:0.8;
			margin:10px 0px;
			margin-bottom:30px;
		}.empty-area a{
			background:#111111;
			color:white;
			border:1px solid #111111;
			padding:6px 14px;
			font-size:16px;
			margin:20px 0px;
			text-decoration:none;
			border-radius:5px;
		}.empty-area a:hover{
			opacity:0.6;
		}.version-metric-heading{
		font-size:1.1vw;
	}.progress_bg{
								margin:1vw 0;
								background:rgba(245, 245, 245);
								width:100%;
								border-radius:5vw;
							}.actual_progress{
								background:black;
								width:20%;
								border-radius:5vw;
								padding:0.25vw;
							}.progress_winner_bg{
								background:rgba(39, 174, 96);
							}.winner_text{
								color:rgba(34, 153, 84);
							}.ab-metric-heading{
								font-size:1.2vw;
								margin:1vw 0;
							}.ab-metric-col-heading{
								font-size:1.5vw;
							}.ab-metric-col-label{
								font-size:1.2vw;
							}
				}