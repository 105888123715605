.careers-area{
	/* Global font family */
font-family: 'Poppins', sans-serif;

.top-area {
    padding: clamp(2rem, 5vw, 8rem);
}

a {
    text-decoration: none;
}

.span-alert {
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
    padding: clamp(0.3rem, 0.6vw, 1rem) clamp(0.8rem, 1vw, 1.5rem);
    border-radius: 5vw;
    color: rgba(2, 96, 105);
    margin-right: clamp(0.5rem, 1vw, 2rem);
    font-size: clamp(0.9rem, 1vw, 1.2rem);
    font-weight: 600;
}

.span-alert-active {
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
    padding: clamp(0.3rem, 0.6vw, 1rem) clamp(0.8rem, 1vw, 1.5rem);
    border-radius: 5vw;
    font-weight: 600;
    font-size: clamp(0.9rem, 1vw, 1.2rem);
    background: rgba(2, 96, 105);
    margin-right: clamp(0.5rem, 1vw, 2rem);
    color: rgba(255, 255, 255);
}

.jobs-category-area a {
    line-height: clamp(1.5rem, 2vw, 2.5rem);
}

.span-alert-hiring {
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
    padding: clamp(0.3rem, 0.4vw, 1rem) clamp(0.8rem, 1vw, 1.5rem);
    border-radius: 5vw;
    color: rgba(255, 255, 255);
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    background: rgba(2, 96, 105);
    margin-right: clamp(0.5rem, 1vw, 2rem);
    font-weight: 600;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.main-heading {
    font-weight: 600;
    font-size: clamp(2rem, 3vw, 4rem);
    margin: clamp(1rem, 2vw, 3rem) 0;
}

.main-subheading {
    font-weight: 500;
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    line-height: clamp(2rem, 2.5vw, 3rem);
    margin: clamp(1rem, 2vw, 3rem) 0;
}

.jobs-category-area {
    margin: clamp(0.5rem, 1vw, 2rem) 0;
}

.jobs-list {
    margin: clamp(1rem, 3vw, 4rem) 0;
}

.job {
    border-top: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(241, 241, 241);
    padding: clamp(1rem, 2vw, 3rem) 0;
}

.job-heading {
    font-weight: 600;
    font-size: clamp(1.5rem, 2vw, 3rem);
}

.job-subheading {
    font-weight: 500;
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    margin: clamp(0.5rem, 1vw, 1.5rem) 0;
}

.job-key-points-area {
    margin: clamp(1rem, 2vw, 3rem) 0;
}

.feather-icon-list {
    width: clamp(1rem, 1.5vw, 2rem);
    padding-bottom: clamp(0.5rem, 1vw, 1.5rem);
    height: clamp(1rem, 1.5vw, 2rem);
    margin: 0 clamp(0.5rem, 1vw, 1.5rem);
}

.job:first-child {
    border-top: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(241, 241, 241);
}

.job:last-child {
    border-bottom: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(241, 241, 241);
}

.apply-button {
    font-weight: 600;
    font-size: clamp(1rem, 1.5vw, 2rem);
    text-align: right;
    float: right;
    padding: clamp(0.4rem, 0.5vw, 1rem) clamp(0.8rem, 1vw, 1.5rem);
    border-radius: 0.5vw;
    color: rgba(2, 96, 105);
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(241, 241, 241);
    margin: 0 clamp(0.5rem, 1vw, 2rem);
}

.apply-button:hover {
    background: rgba(2, 96, 105);
    color: rgba(255, 255, 255);
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
}

}
	
.jobs-list .bi{
		margin-right:0.5vw;
}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.careers-area{
	font-family: 'Poppins', sans-serif;
	.top-area{
	  padding:5vw;
	  
	}a{
	  text-decoration:none;
  }
  .span-alert{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  color:rgba(2,96,105);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
	  
  }.span-alert-active{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  font-weight:600;
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:0.8vw;
	  color:rgba(255,255,255);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
  }.jobs-category-area a{
	  line-height:2vw;
  }
  
  .span-alert-hiring{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1vw 1.5vw;
	  border-radius:5vw;
	  color:rgba(255,255,255);
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:1vw;
	  display:inline-block;
	  margin-bottom:2vw;
	  font-weight:600;
	  
  }@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}.span-alert-hiring{
  animation: blink 1s infinite;
}
  
  .main-heading{
	  font-weight:600;
	  font-size:5vw;
	  margin:2vw 0;
  }.main-subheading{
	  font-weight:500;
	  font-size:3vw;
	  line-height:4.5vw;
	  margin:2vw 0;
  }.jobs-category-area{
	  margin:1vw 0;
	  
	  overflow: auto;
	  white-space: nowrap;
  }.jobs-category-area a{
	  margin-right:1vw;
  }.jobs-list{
	  margin:3vw 0;
  }.job{
	  border-top:0.2vw solid rgba(241,241,241);
	  padding:2vw 0;
  }.job-heading{
	font-weight:600;
	margin-top:4vw;
	font-size:4vw;	
  }.job-subheading{
	font-weight:500;
	font-size:3vw;
	line-height:4vw;
	margin:1vw 0;
  }.job-key-points-area{
  margin:2vw 0;
  }.job:first-child{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job:last-child{
	  border-bottom:0.5vw solid rgba(241,241,241);
  }.apply-button{
	  font-weight:600;
	  font-size:3.5vw;
	  text-align:right;
	  float:right;
	  padding:1vw 1.5vw;
	  border-radius:0.5vw;
	  color:rgba(2,96,105);
	  border:0.1vw solid rgba(241,241,241);
	  margin:0 0.5vw;
  }.apply-button:hover{
	  background:rgba(2,96,105);
	  color:rgba(255,255,255);
	  border:0.1vw solid rgba(2,96,105);
  }
	}.jobs-list .bi{
		margin-right:0.5vw;
	}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.careers-area{
	font-family: 'Poppins', sans-serif;
	.top-area{
	  padding:5vw;
	  
	}a{
	  text-decoration:none;
  }
  .span-alert{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  color:rgba(2,96,105);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
	  
  }.span-alert-active{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  font-weight:600;
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:0.8vw;
	  color:rgba(255,255,255);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
  }.jobs-category-area a{
	  line-height:2vw;
  }
  
  .span-alert-hiring{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1vw 1.5vw;
	  border-radius:5vw;
	  color:rgba(255,255,255);
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:1vw;
	  display:inline-block;
	  margin-bottom:2vw;
	  font-weight:600;
	  
  }@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}.span-alert-hiring{
  animation: blink 1s infinite;
}
  
  .main-heading{
	  font-weight:600;
	  font-size:5vw;
	  margin:2vw 0;
  }.main-subheading{
	  font-weight:500;
	  font-size:3vw;
	  line-height:4.5vw;
	  margin:2vw 0;
  }.jobs-category-area{
	  margin:1vw 0;
	  
	  overflow: auto;
	  white-space: nowrap;
  }.jobs-category-area a{
	  margin-right:1vw;
  }.jobs-list{
	  margin:3vw 0;
  }.job{
	  border-top:0.2vw solid rgba(241,241,241);
	  padding:2vw 0;
  }.job-heading{
	font-weight:600;
	margin-top:4vw;
	font-size:4vw;	
  }.job-subheading{
	font-weight:500;
	font-size:3vw;
	line-height:4vw;
	margin:1vw 0;
  }.job-key-points-area{
  margin:2vw 0;
  }.job:first-child{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job:last-child{
	  border-bottom:0.5vw solid rgba(241,241,241);
  }.apply-button{
	  font-weight:600;
	  font-size:3.5vw;
	  text-align:right;
	  float:right;
	  padding:1vw 1.5vw;
	  border-radius:0.5vw;
	  color:rgba(2,96,105);
	  border:0.1vw solid rgba(241,241,241);
	  margin:0 0.5vw;
  }.apply-button:hover{
	  background:rgba(2,96,105);
	  color:rgba(255,255,255);
	  border:0.1vw solid rgba(2,96,105);
  }
	}.jobs-list .bi{
		margin-right:0.5vw;
	}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.careers-area{
	font-family: 'Poppins', sans-serif;
	.top-area{
	  padding:5vw;
	  
	}a{
	  text-decoration:none;
  }
  .span-alert{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  color:rgba(2,96,105);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
	  
  }.span-alert-active{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1.5vw 2.5vw;
	  border-radius:5vw;
	  font-weight:600;
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:0.8vw;
	  color:rgba(255,255,255);
	  display:inline-block;
	  margin-right: 3vw;
	  margin-bottom:2vw;
	  font-size:3.5vw;
	  font-weight:500;
  }.jobs-category-area a{
	  line-height:2vw;
  }
  
  .span-alert-hiring{
	  border:0.2vw solid rgba(2,96,105);
	  padding:1vw 1.5vw;
	  border-radius:5vw;
	  color:rgba(255,255,255);
	  font-size:3vw;
	  background:rgba(2,96,105);
	  margin-right:1vw;
	  display:inline-block;
	  margin-bottom:2vw;
	  font-weight:600;
	  
  }@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}.span-alert-hiring{
  animation: blink 1s infinite;
}
  
  .main-heading{
	  font-weight:600;
	  font-size:5vw;
	  margin:2vw 0;
  }.main-subheading{
	  font-weight:500;
	  font-size:3vw;
	  line-height:4.5vw;
	  margin:2vw 0;
  }.jobs-category-area{
	  margin:1vw 0;
	  
	  overflow: auto;
	  white-space: nowrap;
  }.jobs-category-area a{
	  margin-right:1vw;
  }.jobs-list{
	  margin:3vw 0;
  }.job{
	  border-top:0.2vw solid rgba(241,241,241);
	  padding:2vw 0;
  }.job-heading{
	font-weight:600;
	margin-top:4vw;
	font-size:4vw;	
  }.job-subheading{
	font-weight:500;
	font-size:3vw;
	line-height:4vw;
	margin:1vw 0;
  }.job-key-points-area{
  margin:2vw 0;
  }.job:first-child{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job{
	  border-top:0.5vw solid rgba(241,241,241);
  }.job:last-child{
	  border-bottom:0.5vw solid rgba(241,241,241);
  }.apply-button{
	  font-weight:600;
	  font-size:3.5vw;
	  text-align:right;
	  float:right;
	  padding:1vw 1.5vw;
	  border-radius:0.5vw;
	  color:rgba(2,96,105);
	  border:0.1vw solid rgba(241,241,241);
	  margin:0 0.5vw;
  }.apply-button:hover{
	  background:rgba(2,96,105);
	  color:rgba(255,255,255);
	  border:0.1vw solid rgba(2,96,105);
  }
	}.jobs-list .bi{
		margin-right:0.5vw;
	}


}
