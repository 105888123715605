.about-body{
  /* Global Styles */
overflow-y: hidden;
font-family: "Poppins", sans-serif;
background-color: rgba(255, 255, 255);

/* Main Banner */
.main-banner {
    width: 100%;
    background-image: url("https://app.aadow.com/images/about-us-banner-2.webp");
    min-height: clamp(30vw, 50vw, 70vw);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Top Content */
.top-content {
    padding: clamp(2rem, 5vw, 8rem) clamp(2rem, 6vw, 8rem);
}

.top-content .heading {
    font-size: clamp(2rem, 3vw, 5rem);
    font-weight: 600;
    color: rgba(2, 73, 80);
}

.top-content .heading span {
    color: grey;
}

.top-content .description {
    font-size: clamp(1rem, 1.5vw, 2rem);
    padding-top: clamp(3rem, 5vw, 7rem);
}

/* Job Content */
.job-content {
    padding: clamp(2rem, 5vw, 8rem) clamp(2rem, 6vw, 8rem);
    padding-top: clamp(4rem, 6vw, 8rem);
    padding-bottom: clamp(2rem, 3vw, 5rem);
    background-color: rgba(250, 250, 250);
}

.job-content-area {
    border-top: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 73, 80);
}

.job-content .description {
    font-size: clamp(1rem, 1.4vw, 1.6rem);
    font-weight: 400;
}

.job-content .link {
    font-size: clamp(1rem, 1.4vw, 1.8rem);
    font-weight: 600;
    color: rgba(2, 73, 80);
}

.job-content i {
    font-size: clamp(1rem, 1.4vw, 1.8rem);
    font-weight: bold;
}

/* Medium Content */
.med-content {
    margin: clamp(2rem, 5vw, 8rem) clamp(2rem, 6vw, 8rem);
    margin-top: clamp(4rem, 7.5vw, 10vw);
    padding: clamp(2rem, 5vw, 8rem) 0;
    border-top: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 73, 80);
}

.med-content .heading {
    font-size: clamp(2rem, 2.5vw, 5rem);
    font-weight: 600;
    color: rgba(2, 73, 80);
}

.med-content .subheading-large {
    font-size: clamp(1.4rem, 1.8vw, 2.2rem);
    line-height: clamp(1.8rem, 2.5vw, 3rem);
    font-weight: 500;
    color: rgba(2, 73, 80);
}

.med-content .heading span {
    color: grey;
}

.med-content .description {
    font-size: clamp(1rem, 1.2vw, 1.4rem);
    line-height: clamp(1.8rem, 2.2vw, 2.6rem);
}

.med-content .subheading {
    font-size: clamp(1rem, 1.5vw, 2rem);
    line-height: clamp(2rem, 2.5vw, 3rem);
    font-weight: 600;
    margin-bottom: clamp(1rem, 3vw, 5rem);
}

/* Founder Image */
.founder-image {
    width: 100%;
    height: clamp(25vw, 35vw, 45vw);
    object-fit: cover;
    border-radius: clamp(0.5vw, 1vw, 2vw);
}

/* Founder Image Container */
.founder-image-container {
    position: relative;
}

.founder-image-container .subheading-large {
    font-size: clamp(1rem, 1.4vw, 1.8rem);
}

.founder-image-container .overlay {
    position: absolute;
    top: clamp(25vw, 28vw, 35vw);
    left: clamp(1vw, 2.55vw, 4vw);
    transform: translate(-2.5vw, 2vw);
    padding: clamp(1rem, 2vw, 3rem) clamp(2rem, 4vw, 5rem);
    height: clamp(8vw, 10vw, 15vw);
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: clamp(0.5vw, 1vw, 2vw);
}

/* Founder Details */
.founder-name {
    font-size: clamp(1.5rem, 2vw, 2.5rem);
    font-weight: 600;
}

.founder-post {
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    font-weight: 500;
    color: rgba(119, 119, 119);
}

/* Contact Content */
.contact-content {
    background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
    padding: clamp(6rem, 12vw, 16rem);
    padding-bottom: clamp(4rem, 8vw, 12rem);
}

.contact-content .heading {
    font-size: clamp(2rem, 3vw, 5rem);
    font-weight: 600;
    color: rgba(255, 255, 255);
}

.contact-content .description {
    font-size: clamp(1rem, 1.4vw, 1.8rem);
    color: rgba(215, 215, 215);
    line-height: clamp(1.5rem, 2vw, 2.5rem);
    margin: clamp(1rem, 2vw, 3rem) 0;
}

/* Contact Button */
.contact-button {
    border-top: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(241, 241, 241);
    padding: clamp(1rem, 3vw, 5rem) 0;
}

.contact-button .button {
    background-color: rgba(250, 250, 250);
    color: rgba(2, 73, 80);
    border: none;
    padding: clamp(0.8rem, 1vw, 1.5rem) clamp(1rem, 2vw, 3rem);
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    font-weight: 500;
    border-radius: clamp(0.5vw, 1vw, 2vw);
}

.button:hover {
    opacity: 0.8;
}

/* Contact Image Container */
.contact-image-container {
    position: relative;
}

.contact-image-container .overlay {
    position: absolute;
    top: -20%;
    left: -10%;
    transform: translate(0%, 25%);
    padding: 0vw clamp(4vw, 6vw, 8vw);
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: clamp(0.5vw, 1vw, 2vw);
    opacity: 0.7;
}

.contact-image-container .overlay-top {
    position: absolute;
    top: -20%;
    left: -10%;
    transform: translate(0%, 25%);
    padding: clamp(4vw, 6vw, 8vw) clamp(4vw, 6vw, 8vw);
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: clamp(0.5vw, 1vw, 2vw);
    opacity: 0.7;
}

.contact-image {
    width: 100%;
    height: clamp(30vw, 40vw, 50vw);
    object-fit: cover;
    border-radius: clamp(0.5vw, 1vw, 2vw);
}

.contact-right-area {
    display: block;
}

}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.about-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255);
  .main-banner {
    width: 100%;
    background-image: url("https://app.aadow.com/images/about-us-banner-2.webp");
    min-height: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top-content {
    padding: 5vw 6vw;
	padding-bottom:1vw;
  }
  .top-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .top-content .heading span {
    color: grey;
  }
  .top-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    padding-top: 5vw;
  }
  .job-content {
    padding: 5vw 6vw;
    padding-top: 8vw;
    padding-bottom: 3vw;
    background-color: rgba(250, 250, 250);
  }
  .job-content-area {
    border-top: 0.2vw solid rgba(2, 73, 80);
  }
  .job-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 400;
  }
  .job-content .link {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .job-content i {
    font-size: 4.5vw;
    font-weight: bold;
  }
  .med-content {
    margin: 5vw 6vw;
    margin-top: 7.5vw;
    padding: 5vw 0vw;
    border-top: 0.1vw solid rgba(2, 73, 80);
  }
  .med-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .med-content .subheading-large {
    font-size: 4.5vw;
    line-height: 6.5vw;
	margin-top:12vw;
    font-weight: 500;
    color: rgba(2, 73, 80);
  }
  .med-content .heading span {
    color: grey;
  }
  .med-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
  }
  .med-content .subheading {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    margin-bottom: 3vw;
  }
  .founder-image {
    width: 100%;
    height: 75vw;
    object-fit: cover;
    border-radius: 1vw;
  }
  .founder-image-container {
    position: relative;
  }
  .founder-image-container .subheading-large {
    font-size: 2.4vw;
  }
  .founder-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 7vw;
    transform: translate(-2.5vw, 2vw);
    padding: 2vw 4vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 1vw;
  }
  .founder-name {
    font-size: 4vw;
    font-weight: 600;
  }
  .founder-post {
    font-size: 3vw;
    font-weight: 500;
    color: rgba(119, 119, 119);
  }
  .contact-content {
    background-image: linear-gradient(
      to right,
      rgba(0, 49, 53),
      rgba(2, 73, 80)
    );
    padding: 12vw;
    padding-bottom: 8vw;
  }
  .contact-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(255, 255, 255);
  }
  .contact-content .description {
    font-size: 3.4vw;
    color: rgba(215, 215, 215);
    line-height: 5vw;
    margin: 4vw 0;
  }.contact-right-area{
	  display:none;
  }
  .contact-button {
    border-top: 0.1vw solid rgba(241, 241, 241);
    padding: 6vw 0px;
	
  }
  .contact-button .button {
    background-color: rgba(250, 250, 250);
    color: rgba(2, 73, 80);
    border: none;
    padding: 2vw 4vw;
    font-size: 4.2vw;
    font-weight: 500;
    border-radius: 0.8vw;
  }
  .button:hover {
    opacity: 0.8;
  }
  .contact-image-container {
    position: relative;
  }
  .contact-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 20%;
    transform: translate(80%, 25%);
    padding: 6vw 8vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image-container .overlay-top {
    position: absolute;
    top: -20%;
    left: -10%;
    transform: translate(0%, 25%);
    padding: 6vw 6vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image {
    width: 100%;
    height: 40vw;
    object-fit: cover;
    border-radius: 0.5vw;
  }
}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.about-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255);
  .main-banner {
    width: 100%;
    background-image: url("https://app.aadow.com/images/about-us-banner-2.webp");
    min-height: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top-content {
    padding: 5vw 6vw;
	padding-bottom:1vw;
  }
  .top-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .top-content .heading span {
    color: grey;
  }
  .top-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    padding-top: 5vw;
  }
  .job-content {
    padding: 5vw 6vw;
    padding-top: 8vw;
    padding-bottom: 3vw;
    background-color: rgba(250, 250, 250);
  }
  .job-content-area {
    border-top: 0.2vw solid rgba(2, 73, 80);
  }
  .job-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 400;
  }
  .job-content .link {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .job-content i {
    font-size: 4.5vw;
    font-weight: bold;
  }
  .med-content {
    margin: 5vw 6vw;
    margin-top: 7.5vw;
    padding: 5vw 0vw;
    border-top: 0.1vw solid rgba(2, 73, 80);
  }
  .med-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .med-content .subheading-large {
    font-size: 4.5vw;
    line-height: 6.5vw;
	margin-top:12vw;
    font-weight: 500;
    color: rgba(2, 73, 80);
  }
  .med-content .heading span {
    color: grey;
  }
  .med-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
  }
  .med-content .subheading {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    margin-bottom: 3vw;
  }
  .founder-image {
    width: 100%;
    height: 75vw;
    object-fit: cover;
    border-radius: 1vw;
  }
  .founder-image-container {
    position: relative;
  }
  .founder-image-container .subheading-large {
    font-size: 2.4vw;
  }
  .founder-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 7vw;
    transform: translate(-2.5vw, 2vw);
    padding: 2vw 4vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 1vw;
  }
  .founder-name {
    font-size: 4vw;
    font-weight: 600;
  }
  .founder-post {
    font-size: 3vw;
    font-weight: 500;
    color: rgba(119, 119, 119);
  }
  .contact-content {
    background-image: linear-gradient(
      to right,
      rgba(0, 49, 53),
      rgba(2, 73, 80)
    );
    padding: 12vw;
    padding-bottom: 8vw;
  }
  .contact-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(255, 255, 255);
  }
  .contact-content .description {
    font-size: 3.4vw;
    color: rgba(215, 215, 215);
    line-height: 5vw;
    margin: 4vw 0;
  }.contact-right-area{
	  display:none;
  }
  .contact-button {
    border-top: 0.1vw solid rgba(241, 241, 241);
    padding: 6vw 0px;
	
  }
  .contact-button .button {
    background-color: rgba(250, 250, 250);
    color: rgba(2, 73, 80);
    border: none;
    padding: 2vw 4vw;
    font-size: 4.2vw;
    font-weight: 500;
    border-radius: 0.8vw;
  }
  .button:hover {
    opacity: 0.8;
  }
  .contact-image-container {
    position: relative;
  }
  .contact-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 20%;
    transform: translate(80%, 25%);
    padding: 6vw 8vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image-container .overlay-top {
    position: absolute;
    top: -20%;
    left: -10%;
    transform: translate(0%, 25%);
    padding: 6vw 6vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image {
    width: 100%;
    height: 40vw;
    object-fit: cover;
    border-radius: 0.5vw;
  }
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.about-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255);
  .main-banner {
    width: 100%;
    background-image: url("https://app.aadow.com/images/about-us-banner-2.webp");
    min-height: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top-content {
    padding: 5vw 6vw;
	padding-bottom:1vw;
  }
  .top-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .top-content .heading span {
    color: grey;
  }
  .top-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    padding-top: 5vw;
  }
  .job-content {
    padding: 5vw 6vw;
    padding-top: 8vw;
    padding-bottom: 3vw;
    background-color: rgba(250, 250, 250);
  }
  .job-content-area {
    border-top: 0.2vw solid rgba(2, 73, 80);
  }
  .job-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 400;
  }
  .job-content .link {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .job-content i {
    font-size: 4.5vw;
    font-weight: bold;
  }
  .med-content {
    margin: 5vw 6vw;
    margin-top: 7.5vw;
    padding: 5vw 0vw;
    border-top: 0.1vw solid rgba(2, 73, 80);
  }
  .med-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(2, 73, 80);
  }
  .med-content .subheading-large {
    font-size: 4.5vw;
    line-height: 6.5vw;
	margin-top:12vw;
    font-weight: 500;
    color: rgba(2, 73, 80);
  }
  .med-content .heading span {
    color: grey;
  }
  .med-content .description {
    font-size: 4.5vw;
    line-height: 8vw;
  }
  .med-content .subheading {
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 600;
    margin-bottom: 3vw;
  }
  .founder-image {
    width: 100%;
    height: 75vw;
    object-fit: cover;
    border-radius: 1vw;
  }
  .founder-image-container {
    position: relative;
  }
  .founder-image-container .subheading-large {
    font-size: 2.4vw;
  }
  .founder-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 7vw;
    transform: translate(-2.5vw, 2vw);
    padding: 2vw 4vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 1vw;
  }
  .founder-name {
    font-size: 4vw;
    font-weight: 600;
  }
  .founder-post {
    font-size: 3vw;
    font-weight: 500;
    color: rgba(119, 119, 119);
  }
  .contact-content {
    background-image: linear-gradient(
      to right,
      rgba(0, 49, 53),
      rgba(2, 73, 80)
    );
    padding: 12vw;
    padding-bottom: 8vw;
  }
  .contact-content .heading {
    font-size: 8vw;
    font-weight: 600;
    color: rgba(255, 255, 255);
  }
  .contact-content .description {
    font-size: 3.4vw;
    color: rgba(215, 215, 215);
    line-height: 5vw;
    margin: 4vw 0;
  }.contact-right-area{
	  display:none;
  }
  .contact-button {
    border-top: 0.1vw solid rgba(241, 241, 241);
    padding: 6vw 0px;
	
  }
  .contact-button .button {
    background-color: rgba(250, 250, 250);
    color: rgba(2, 73, 80);
    border: none;
    padding: 2vw 4vw;
    font-size: 4.2vw;
    font-weight: 500;
    border-radius: 0.8vw;
  }
  .button:hover {
    opacity: 0.8;
  }
  .contact-image-container {
    position: relative;
  }
  .contact-image-container .overlay {
    position: absolute;
    bottom: 0%;
    left: 20%;
    transform: translate(80%, 25%);
    padding: 6vw 8vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image-container .overlay-top {
    position: absolute;
    top: -20%;
    left: -10%;
    transform: translate(0%, 25%);
    padding: 6vw 6vw;
    background-color: rgba(241, 241, 241);
    color: rgba(2, 73, 80);
    border-radius: 0.5vw;
    opacity: 0.7;
  }
  .contact-image {
    width: 100%;
    height: 40vw;
    object-fit: cover;
    border-radius: 0.5vw;
  }
}
}

