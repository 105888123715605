			.import-contacts-body {
				font-family: 'Poppins', sans-serif;
				background-color: rgba(255, 255, 255);
				
			.mapping-area{
					margin:1vw 0;
					padding-top:2vw;
					border-top:0.1vw solid rgba(235, 235, 235);
				}.breadcrumb .breadcrumb-item{
					font-size:1vw;
				}label{
					font-size:0.8vw;
					display:block;
					color:rgba(100, 100, 100);
					font-weight:500;
					margin-bottom:0.5vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					font-size:1vw;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.header-area{
					padding:2vw;
				}.main-heading{
					 font-size:1.5vw;
					 font-weight:600;
				}.main-subheading{
					 font-size:1.05vw;
					 font-weight:500;
					 color:rgba(125, 125, 125);
				}.import-area{
					padding:0vw 2vw;
				}.file-uploader{
					border: 0.15vw dashed rgba(235, 235, 235);
					padding:2vw 1vw;
					border-radius:0.5vw;
				}.file-uploader:hover{
					border: 0.15vw dashed rgba(100, 100, 100);
					cursor:pointer;
				}.file-uploader img{
					width:5vw;
					height:5vw;
				}.uploader-heading{
					margin:1vw 0;
					font-size:1vw;
				}.uploader-subheading{
					font-size:0.8vw;
					margin:0.5vw 0;
					color:rgba(150, 150, 150);
				}.mid-area{
					padding:2vw;
				}.file-upload-progress{
					padding:1.5vw;
					margin:2vw 0;
					margin-bottom:0;
					border-radius:0.5vw;
					background:rgba(250, 250, 250);
					border:0.15vw solid rgba(238, 238, 238);
				}.file-upload-progress img{
					width:100%;
					height:3vw;
					object-fit:contain;
				}.upload-progress-heading{
					font-size:1vw;
				}.upload-progress-subheading{
					font-size:1vw;
					margin-top:1vw;
					color:rgba(150, 150, 150);
				}.progreess{
					background:rgba(240, 240, 240);
					width:100%;
					margin:1vw 0;
					height: 0.2vw;
					border-radius:1vw;
				}.completed-progress{
					padding:0.25vw;
					width:25%;
					border-radius:1vw;
					background:rgba(2,96, 105);
				}.uploader-label, .uploader-label a{
					color:rgba(125, 125, 125);
					font-size:0.8vw;
					margin-top:1.5vw;
					font-weight:400;
				}.custom-select {
				width:100%;
				  border-width: 1vw;
				  border-color: rgba(2,96, 105);
				  border-radius: 0.5vw;
				  font-size:1.2vw;
				  padding:0.1vw;
				  height:3.5vw;
				  margin:1vw 0;
				}

				.custom-select .MuiSelect-select {
				  display: flex;
				  align-items: center;
				}

				.custom-select:hover .MuiOutlinedInput-notchedOutline {
				  border-color: rgba(2,96, 105);
				}

				.custom-select.Mui-focused .MuiOutlinedInput-notchedOutline {
				  border-color: rgba(2,96, 105);
				}

				.custom-menu-item {
				  display: flex;
				  align-items: center;
				}

				.menu-item-icon {
				  margin-right: 1vw;
				  width: 1.5vw;
				  height: 1.5vw;
				}.right-arrow{
					font-size:1.5vw;
					margin-top:2vw;
					display:block;
					text-align:center;
				}.footer-area{
					border-top:0.12vw solid rgba(238, 238, 238);
					margin:1vw 0;
				}.primary-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 0.8vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(255, 255, 255);
				  border: 0.15vw solid rgba(2, 96, 105);
				  background: rgba(2, 96, 105);
				}
				.primary-outline-button {
				  margin-top: 1vw;
				  border-radius: 0.4vw;
				  font-size: 0.8vw;
				  font-weight: 500;
				  padding: 0.5vw 1.2vw;
				  color: rgba(100, 100, 100);
				  border: 0.15vw solid rgba(200, 200, 200);
				  background: rgba(255, 255, 255);
				}.total-contacts-area{
					margin:1vw 0;
					padding:2vw;
					background:rgba(250, 250, 250);
					border-radius:1vw;
					border:0.2vw solid rgba(240, 240, 240);
				}.import-contacts-heading{
					font-size:2vw;
					margin-bottom: 0.5vw;
					color:rgba(10, 10, 10);
				}.import-contacts-subheading{
					font-size:1vw;
					font-weight:400;
					color:rgba(150, 150, 150);
				}.listname-heading{
					font-size:1vw;
				}.listname-subheading{
					font-size:0.8vw;
					color:rgba(150, 150, 150);
					font-weight:400;
				}.input-form{
					margin:0.5vw 0;
					padding:0.5vw 0.8vw;
					border-radius:0.4vw;
					outline:none;
					margin-top: 0;
					margin-bottom:0;
					font-size:0.8vw;
					border:0.15vw solid rgba(200, 200, 200);
				}.input-form:focus{
				  border: 0.15vw solid rgba(2, 96, 105);
				}.custom-label {
				font-family: 'Poppins', sans-serif;
				  color: rgba(100, 100, 100); /* Custom color */
				  font-weight: 600; /* Custom font weight */
				  font-size: 1.5vw; /* Custom font size */
				  margin-left: -0.5vw; /* Adjust spacing */
				}textarea{
					width:100%;
					resize:none;
					height:20vw;
					font-size:0.8vw;
					padding:1vw;
					outline:none;
					border-radius:0.5vw;
					border:0.2vw solid rgba(245, 245, 245);
				}textarea:focus{
				border: 0.2vw solid rgba(2, 96, 105);
				}.success-content{
					padding:1vw 15vw;
				}.top-area{
					background-color: rgb(241, 241, 241);
					padding: 1.5vw;
					border-radius: 0.5vw;
				}.main-heading{
					font-size: 1.2vw;
					line-height: 1.5vw;
				}.main-subheading{
					font-size: 0.8vw;
				}.secondary-heading-modal{
					font-size: 1.4vw;
					margin-top:2vw;
				}.secondary-description-modal{
					font-size: 1vw;
					color:rgba(100, 100, 100);
					margin-top:1vw;
				}.success-icon{
					width: 100%;
					height: 8vw;
				}
			}

