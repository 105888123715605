/* CustomSwitch.css */

/* Change the track color when the switch is active (on) */
.custom-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: rgba(2, 96, 105); /* Change this color to whatever you want */
}

/* Optionally, change the color of the thumb when active */
.custom-switch .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: rgba(2, 96, 105); /* Thumb color when active (white by default) */
}
