.refund-policy-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(250, 250, 250);
  .top-area {
    padding: clamp(2rem, 4vw, 5rem);
    color: white;
    background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
}

.top-area h1 {
    font-weight: 500;
    font-size: clamp(1.5rem, 2vw, 3rem);
}

.top-area span {
    font-weight: 400;
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    color: rgba(241, 241, 241);
}

.container-top {
    padding: clamp(2rem, 5vw, 7rem) clamp(3rem, 10vw, 12rem);
}

.content-area {
    padding: clamp(1rem, 2vw, 4rem) clamp(5rem, 15vw, 20rem);
}

.policy-content {
    background-color: rgba(255, 255, 255);
    padding: clamp(2rem, 4vw, 6rem);
    margin-top: clamp(-10rem, -10vw, -10rem);
    border-radius: 5px;
}

.policy-content h2 {
    font-weight: 500;
    line-height: clamp(1.5rem, 2vw, 2.5rem);
    font-size: clamp(1.2rem, 1.5vw, 2rem);
}

.policy-content p {
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    line-height: clamp(1.2rem, 1.8vw, 2rem);
}

}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.refund-policy-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(250, 250, 250);
.top-area {
  padding: 8vw 4vw;
  color: white;
  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
}
.top-area h1 {
  font-weight: 500;
  font-size: 6vw;
}
.top-area span {
  font-weight: 400;
  font-size: 3.5vw;
  color: rgba(241, 241, 241);
}
.container-top {
  padding: 5vw 10vw;
}
.content-area {
  padding: 2vw 5vw;
}
.policy-content {
  background-color: rgba(255, 255, 255);
  padding: 4vw;
  margin-top: -10vw;
  border-radius: 5px;
}
.policy-content h2 {
  font-weight: 500;
  line-height: 5vw;
  font-size: 4vw;
}
.policy-content p {
  font-size: 2.5vw;
  line-height: 4vw;
}
}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.refund-policy-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(250, 250, 250);
.top-area {
  padding: 8vw 4vw;
  color: white;
  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
}
.top-area h1 {
  font-weight: 500;
  font-size: 6vw;
}
.top-area span {
  font-weight: 400;
  font-size: 3.5vw;
  color: rgba(241, 241, 241);
}
.container-top {
  padding: 5vw 10vw;
}
.content-area {
  padding: 2vw 5vw;
}
.policy-content {
  background-color: rgba(255, 255, 255);
  padding: 4vw;
  margin-top: -10vw;
  border-radius: 5px;
}
.policy-content h2 {
  font-weight: 500;
  line-height: 5vw;
  font-size: 4vw;
}
.policy-content p {
  font-size: 2.5vw;
  line-height: 4vw;
}
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.refund-policy-body {
  font-family: "Poppins", sans-serif;
  background-color: rgba(250, 250, 250);
.top-area {
  padding: 8vw 4vw;
  color: white;
  background-image: linear-gradient(to right, rgba(0, 49, 53), rgba(2, 73, 80));
}
.top-area h1 {
  font-weight: 500;
  font-size: 6vw;
}
.top-area span {
  font-weight: 400;
  font-size: 3.5vw;
  color: rgba(241, 241, 241);
}
.container-top {
  padding: 5vw 10vw;
}
.content-area {
  padding: 2vw 5vw;
}
.policy-content {
  background-color: rgba(255, 255, 255);
  padding: 4vw;
  margin-top: -10vw;
  border-radius: 5px;
}
.policy-content h2 {
  font-weight: 500;
  line-height: 5vw;
  font-size: 4vw;
}
.policy-content p {
  font-size: 2.5vw;
  line-height: 4vw;
}
}
}

