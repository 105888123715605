
			.sidebar-body{
				  background:rgba(255, 255, 255);
			.audience-overview-body{
			}.select-input-form {
				display: flex;
				align-items: center;
				width: 100%;
				color: rgb(80, 80, 80);
				margin-top: 0.5vw;
				border: 0.12vw solid rgb(220, 220, 220);
				border-radius: 0.4vw;
				background: rgb(255, 255, 255);
				padding: 0.3vw 0.2vw 0.2vw 0.8vw;
				font-size: 2vw;
				font-weight: 500;
				outline: none;
			}.input-forms{
					padding:0.5vw 0.8vw;
					border-radius:0.4vw;
					outline:none;
					font-size:1.1vw;
					border:0.2vw solid rgba(200, 200, 200);
				}.input-forms:focus{
				  border: 0.2vw solid rgba(2, 96, 105);
				}

			.select-input-form .bi {
				margin-right: 1vw;
				font-size:1.5vw;
				/* Adjust space between icon and select box */
			}.page-content {
				  font-family: 'Poppins', sans-serif;
				  background-color: rgba(255, 255, 255);
			.audience-overview-body{
				  background:rgba(255, 255, 255);
			.header-area{
				padding:2vw;
				padding-top:1vw;
				padding-bottom:1vw ;
			}.breadcrumb .breadcrumb-item{
					font-size:1vw;
				}.breadcrumb-area{
					padding:2vw 2vw 0vw 2vw;
				}.breadcrumb .breadcrumb-item a{
					font-size:1vw;
					text-decoration:none;
					font-weight:400;
					color:rgba(100, 100, 100);
				}.breadcrumb .active{
					font-weight:500;
					color:rgba(10, 10, 10);
				}.breadcrumb .breadcrumb-item a:hover{
					text-decoration:underline;
					color:rgba(20, 20, 20);
				}.audience-metric-col{
					border-radius:0.5vw;
					border: 0.1vw solid rgba(230, 230, 230);
					padding:1vw;
				}.audience-metric-area{
					padding:1vw 2vw;
				}.audience-metric-col .icon{
					border:0.1vw solid rgba(245, 245, 245);
					padding:0.5vw 1vw;
					font-size:1.5vw;
					border-radius:0.5vw;
				}.audience-metric-col .secondary-heading{
					font-size:1.5vw;
					padding-top:0.5vw;
					margin-left:1vw;
					font-weight:600;
					color:rgba(50, 50, 50);
				}.audience-metric-col .main-heading{
					font-size:2vw;
					padding-top:0.5vw;
					font-weight:600;
				}.audience-metric-col .metric-today-change-down, .audience-metric-col .metric-today-change-up{
					font-size:0.8vw;
					padding:0.4vw 0.8vw;
					color: rgba(34, 153, 84);
					background:rgba(233, 247, 239);
					border:0.1vw solid rgba(34, 153, 84);
					font-weight:600;
					display:inline-block;
					border-radius:10vw;
				}.audience-metric-col .metric-today-change-down{
					color: rgba(203, 67, 53);
					background:rgba(253, 237, 236);
					border:0.1vw solid rgba(203, 67, 53);
				}table {
					width: 100%;
					border-collapse: collapse;
				}

				th, td {
					border-top: 0.1vw solid rgba(235, 235, 235);
					border-bottom: 0.1vw solid rgba(235, 235, 235);
					padding: 0.8vw;
					font-size:0.8vw;
				}

				thead th {
					border-top: 0.2vw solid rgba(235, 235, 235); /* Optional: thicker border for the header */
					border-bottom: 0.2vw solid rgba(235, 235, 235); /* Optional: thicker border for the header */
				}

				tbody td:first-child {
					border-left: none; /* Optional: remove left border for first column */
				}

				tbody td:last-child {
					border-right: none; /* Optional: remove right border for last column */
				}

				thead th:first-child {
					border-left: none; /* Optional: remove left border for first header cell */
				}

				thead th:last-child {
					border-right: none; /* Optional: remove right border for last header cell */
				}.table-contact-area{
					padding:2vw 2vw;
				}table .listname{
					font-weight:500;
				}.person-image{
					width:3vw;
					height:3vw;
					border-radius:20vw;
					border:0.2vw solid rgba(240, 240, 240);
					object-fit:cover;
				}.person-name{
					font-size:1vw;
					padding-top:1vw;
				}.person-contact-info{
					font-size:0.8vw;
					color:rgba(100, 100, 100);
				}.person-status{
					background:rgba(233, 247, 239);
					padding:0.5vw 1vw;
					font-size:0.8vw;
					color:rgba(30, 132, 73);
					font-weight:600;
					border-radius:5vw;
				}.active-status{
					background:rgba(233, 247, 239);
					color:rgba(30, 132, 73);
				}.inactive-status{
					background:rgba(253, 237, 236);
					color:rgba(231, 76, 60);
				}.th-one, .td-one{
					width:40%;
				}.segment-container div{
					padding:0.5vw 1.4vw;
					display:inline-block;
					background-color:rgba(220, 220, 220);
					margin-right:0.1vw;
					margin-left:0.1vw;
				}.segment-container div:first-child{
					border-radius:1vw 0 0 1vw;
				}.segment-container div:last-child{
					border-radius:0 1vw 1vw 0;
				}.green{
					.first-segment, .second-segment, .third-segment{
					background-color:rgba(34, 153, 84);
					}
				}.orange{
					.first-segment, .second-segment{
					background-color:rgba(243, 156, 18);
					}
				}.red{
					.first-segment{
					background-color:rgba(250, 0, 15);
					}
				}.audience-filter-area{
					padding:1vw 2vw;
				}.contact-search {
				  width: 100%;
				  background-color: rgba(245, 245, 245);
				  border: 0.2vw solid rgba(245, 245, 245);
				  padding: 0.6vw 1vw;
				  border-radius: 0.4vw;
				  font-weight: 500;
				  font-size: 0.8vw;
				  outline: none;
				}.contact-search::placeholder {
				  /* Chrome, Firefox, Opera, Safari 10.1+ */
				  color: rgba(100, 100, 100);
				  font-weight: 400;
				  opacity: 1; /* Firefox */
				}.filter-button{
				  padding: 0.7vw 1.4vw;
				  border:0.2vw solid rgba(225, 225, 225);
				  background:none;
				  font-weight:500;
				  letter-spacing:0.02rem;
				  border-radius:0.8vw;
				}.dropdown-container {
				  position: relative;
				}

				.dropdown-toggle {
					background:none;
					border:none;
					color:rgba(10, 10, 10);
					font-size:1.2vw;
				}

				.dropdown-menu {
				  background-color: rgba(255, 255, 255); /* Change to your desired background color */
				  left: 3vw; /* Align dropdown to the left side of the container */
				  right: 0; /* Remove right alignment */
				  color:rgba(100, 100, 100);
				  position: absolute; /* Position the dropdown absolutely within the container */
				  top: 3.5vw; /* Position the dropdown below the button */
				  transform: translateX(-100%); /* Move the dropdown to the left side of the button */
				  border: none; /* Optional: Remove border */
				  box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
				}/* Customize hover style on the dropdown button */
				.dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle.show {
					background-color: rgba(245, 245, 245); /* Change background color on hover */
					color: rgba(100, 100, 100); /* Change text color on hover */
					border-color: #007bff; /* Change border color on hover */
					/* Add any other styles you want */
					border-radius:0.5vw;
				}

				/* Hide the default Bootstrap chevron */
				.dropdown-toggle::after {
					display: none;
				}

				.dropdown-menu.show {
				  display: block;
				}

				.dropdown-menu li a {
				  color:rgba(100, 100, 100);
				  font-weight:500;
				  text-decoration:none;
				  padding: 0.2vw 0.8vw; /* Adjust padding */
				  text-align: left; /* Align text to the left */
				  display:block;
				  font-size:0.8vw;
				}.dropdown-menu li a:hover {
				  background-color: rgba(220, 220, 220); /* Change hover color */
				}.pagination-area{
					padding:2vw 0;
				}.pagination-area button{
					background-color: rgba(255, 255, 255);
					color: rgba(50, 50, 50);
					padding:0.5vw 1vw;
					border-radius:0.5vw;
					font-weight:500;
					font-size: 0.8vw;
					border:0.14vw solid rgba(220, 220, 220);
				}.pagination-area button:disabled{
					background-color: rgba(245, 245, 245);
					color: rgba(50, 50, 50);
					padding:0.5vw 1.5vw;
					border-radius:0.5vw;
					font-weight:500;
					opacity:0.5;
					cursor:not-allowed;
				}.pagination-area .active{
					background-color: rgba(255, 255, 255);
					color: rgba(50, 50, 50);
					padding:0.5vw 1.5vw;
					border-radius:0.5vw;
					font-weight:500;
					border:0.2vw solid rgba(220, 220, 220);
					opacity:0.5;
					cursor:not-allowed;
				}.pagination-numbers button{
					padding:0.5vw 1.5vw;
					margin:0 0.4vw;
				}.pagination-area button:hover{
					opacity:0.5;
				}.pagination-numbers .active, .pagination-numbers .active:hover{
					border:0.2vw solid rgba(230, 230, 230);
					background:rgba(230, 230, 230);
					cursor:not-allowed;
					opacity:1;
				}.line-break{
					border-bottom:0.001vw solid rgba(220, 220, 220);
					margin:0.4vw 0;
				}.sidenav {
				  height: 100%;
				  width: 0;
				  position: fixed;
				  z-index: 2; /* Stay on top */
				  top: 0;
				  right: 0;
				  background-color: rgba(255, 255, 255);
				  overflow-x: hidden;
				  transition: 0.5s;
				}



				#main {
				  transition: margin-left .5s;
				  padding: 1vw;
				}

				.sidenav.open {
				  width: 25vw;
				}


				/* Overlay effect */
				.overlay {
				  position: fixed;
				  top: 0;
				  left: 0;
				  width: 100%;
				  height: 100%;
				  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
				  z-index: 1; /* Below the sidenav */
				  display: none;
				  transition: 0.5s;
				}

				.overlay.open {
				  display: block;
				}

			}
			}.cursor-pointer{
				cursor: pointer;
			}.top-area{
				background-color: rgb(241, 241, 241);
				padding: 1.5vw;
			}.main-heading{
				font-size: 1.2vw;
				line-height: 1.5vw;
			}.main-subheading{
				font-size: 0.8vw;
			}.primary-button {
				margin-top: 1vw;
				border-radius: 0.4vw;
				font-size: 0.9vw;
				font-weight: 500;
				padding: 0.5vw 1.2vw;
				color: rgba(255, 255, 255);
				border: 0.2vw solid rgba(2, 96, 105);
				background: rgba(2, 96, 105);
			  }
			  .primary-outline-button {
				margin-top: 1vw;
				border-radius: 0.4vw;
				font-size: 0.9vw;
				font-weight: 500;
				padding: 0.5vw 1.2vw;
				color: rgba(2, 96, 105);
				border: 0.2vw solid rgba(2, 96, 105);
				background: rgba(255, 255, 255);
			  }label{
				font-size:0.8vw;
			  }
			}