.contact-body {
  font-family: "Poppins", sans-serif;

  .main-area {
    padding: 0vh 1vw;
  }
  .left-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
    padding: 1vw;
    border-right: 0.1vw solid rgba(240, 240, 240);
    .main-content {
      padding-top: 2vw;
      flex-grow: 1;
      .logo {
        width: 8vw;
        height: auto;
        margin-bottom: 4vw;
      }
      .contact-option {
        margin-bottom: 4vw;
      }
      .contact-option a {
        font-size: clamp(1rem, 1.5vw, 2rem); /* Min, scalable, max */
        line-height: clamp(1.2rem, 2vw, 2.5rem);
        color: rgba(50, 50, 50);
        margin-right: clamp(0.5rem, 1vw, 1.5rem);
        padding: clamp(0.2rem, 0.3vw, 0.6rem) clamp(0.4rem, 0.6vw, 1rem);
        border-radius: clamp(0.2rem, 0.4vw, 0.6rem);
        border: clamp(0.1rem, 0.12vw, 0.2rem) solid rgba(240, 240, 240);
    }
    
      .contact-option .heading {
        font-size: clamp(1rem, 1vw, 1.6rem);
        line-height: clamp(1.2rem, 1.2vw, 1.8rem);
        font-weight: 500;
    }
    
    .contact-option .description {
        font-size: clamp(0.9rem, 0.8vw, 1.4rem);
        line-height: clamp(1rem, 1vw, 1.6rem);
        color: rgba(120, 120, 120);
        font-weight: 400;
    }
    
    .contact-option .contact-link {
        font-size: clamp(0.9rem, 0.8vw, 1.4rem);
        line-height: clamp(1rem, 1.2vw, 1.6rem);
        font-weight: 600;
    }
    
    }
    .footer-content {
      padding-bottom: clamp(1rem, 2vw, 3rem);
      a {
        font-size: clamp(1rem, 1vw, 2rem); /* Min, scalable, max */
        color: rgba(50, 50, 50);
        margin-right: clamp(0.5rem, 1vw, 1.5rem);
        padding: clamp(0.2rem, 0.3vw, 0.6rem) clamp(0.4rem, 0.6vw, 1rem);
        border-radius: clamp(0.2rem, 0.4vw, 0.6rem);
        border: clamp(0.1rem, 0.12vw, 0.2rem) solid rgba(240, 240, 240);
    }
    }
  }

  .right-area {
    align-items: center;
    height: 100vh;
    padding: 1vw 12vw;
	background: linear-gradient(to bottom, white 0%, white 90%, grey 100%);


    h2 {
      font-size: clamp(1.5rem, 2vw, 3rem); /* Min, scalable, max */
      font-weight: 600;
      font-size: 1.5vw;
      line-height: clamp(2rem, 3vw, 4rem);
      text-align: center;
      margin-bottom: clamp(1rem, 1vw, 3rem); /* Adjusting bottom margin responsively */
      padding: clamp(0.5rem, 2vw, 3rem);
  }
    .steps-area {
      margin: 2vw 0vw;
	  width:50vw;
    }
    .radio-card {
      border: clamp(0.08rem, 0.1vw, 0.2rem) solid rgba(240, 240, 240);
      text-align: center;
      margin-bottom: clamp(1rem, 1.5vw, 3rem);
      cursor: pointer;
      background: rgba(255, 255, 255);
      border-radius: clamp(0.3rem, 0.5vw, 0.7rem);
      padding: clamp(1rem, 2vw, 3rem) clamp(0.8rem, 1vw, 2rem);
  }
  
  .radio-card i {
      padding: clamp(0.3rem, 0.5vw, 1rem) clamp(0.5rem, 0.8vw, 1.5rem);
      font-size: clamp(1rem, 1.5vw, 2rem);
      border-radius: clamp(0.2rem, 0.3vw, 0.4rem);
      border: clamp(0.08rem, 0.1vw, 0.2rem) solid rgba(240, 240, 240);
  }
  
  .radio-card h4 {
      margin-top: clamp(1rem, 1.5vw, 2rem);
      font-size: clamp(1rem, 1vw, 1.6rem);
      line-height: clamp(1.2rem, 1.5vw, 2rem);
      font-weight: 500;
  }
  
  .radio-card h6 {
      font-size: clamp(0.8rem, 0.8vw, 1.2rem);
      line-height: clamp(1rem, 1.2vw, 1.5rem);
      font-weight: 400;
      color: rgba(150, 150, 150);
  }
  .primary-button {
    width: 100%;
    margin-top: clamp(0.5rem, 1vw, 2rem); /* Scales with screen size */
    border-radius: clamp(0.2rem, 0.4vw, 0.6rem);
    font-size: clamp(0.8rem, 0.9vw, 1.4rem);
    font-weight: 500;
    padding: clamp(0.3rem, 0.5vw, 1rem) clamp(0.6rem, 1.2vw, 2rem);
    color: rgba(255, 255, 255);
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
    background: rgba(2, 96, 105);
}

.primary-outline-button {
    width: 100%;
    margin-top: clamp(0.5rem, 1vw, 2rem);
    border-radius: clamp(0.2rem, 0.4vw, 0.6rem);
    font-size: clamp(0.8rem, 1vw, 1.4rem);
    font-weight: 500;
    padding: clamp(0.3rem, 0.5vw, 1rem) clamp(0.6rem, 1.2vw, 2rem);
    color: rgba(2, 96, 105);
    border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2, 96, 105);
    background: rgba(255, 255, 255);
}

label {
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    font-weight: 500;
    display: block;
    color: rgb(75, 75, 75);
}

.input-form {
    display: block;
    width: 100%;
    color: rgb(50, 50, 50);
    margin-top: clamp(0.3rem, 0.5vw, 1rem);
    margin-bottom: clamp(0.5rem, 1vw, 2rem);
    border: none;
    background: rgb(255, 255, 255);
    border: clamp(0.1rem, 0.14vw, 0.2rem) solid rgb(220, 220, 220);
    border-radius: clamp(0.2rem, 0.4vw, 0.6rem);
    padding: clamp(0.3rem, 0.5vw, 1rem) clamp(0.6rem, 1vw, 2rem);
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    font-weight: 500;
    outline: none;
}
.success-area img {
  width: clamp(4rem, 8vw, 12rem); /* Scales between 4rem and 12rem */
  height: auto;
  margin-bottom: clamp(1rem, 2vw, 3rem);
  object-fit: contain;
}

.success-area .secondary-heading {
  font-size: clamp(1.5rem, 2vw, 3rem);
}

.success-area .secondary-description {
  font-weight: 400;
  font-size: clamp(1rem, 1.2vw, 1.5rem);
  line-height: clamp(1.5rem, 2vw, 2.5rem);
  color: rgba(145, 145, 145);
}

.active {
  border: clamp(0.1rem, 0.2vw, 0.3rem) solid rgba(2,96,105);
}

.error-message {
  font-size: clamp(0.8rem, 1vw, 1.2rem);
  font-weight: 500;
  margin-top: clamp(0.3rem, 0.5vw, 1rem);
  color: rgba(231, 76, 60);
}

.input-error {
  color: rgba(231, 76, 60);
  border: clamp(0.1rem, 0.14vw, 0.2rem) solid rgba(231, 76, 60);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
  }
}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
.contact-body {
	overflow-x: hidden;
  font-family: "Poppins", sans-serif;

  .main-area {
    padding: 0vh 1vw;
  }
  .left-area {
    
    height: 100vh;
    padding: 10vw 2vw;
    border-top: 0.1vw solid rgba(240, 240, 240);
	.main-content {
      padding-top: 2vw;
      flex-grow: 1;
      .logo {
        width: 8vw;
        height: auto;
        margin-bottom: 4vw;
		display:none;
      }
      .contact-option {
        margin-bottom: 4vw;
		padding:5vw 5vw;
      }
      .contact-option a {
        font-size: 8vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 1vw;
        padding: 1vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
      }
      .contact-option .heading {
        font-size: 4.2vw;
        line-height: 6.2vw;
        font-weight: 500;
      }
      .contact-option .description {
        font-size: 3vw;
        line-height: 4vw;
        color: rgba(120, 120, 120);
        font-weight: 400;
      }
      .contact-option .contact-link {
        font-size: 4vw;
        line-height: 5vw;
        font-weight: 600;
      }
    }
    .footer-content {
      padding: 5vw;
      a {
        font-size: 6vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 2.5vw;
        padding: 2vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
		
      }
    }
  }

  .right-area {
    padding: 1vw 8vw;


    h2 {
      font-size: 5vw;
      font-weight: 600;
      line-height: 7vw;
      text-align: center;
      margin: 10vw 0;
	  padding:0 1vw;
    }
    .steps-area {
      margin: 2vw 0vw;
    }
    .radio-card {
      border: 0.5vw solid rgba(240, 240, 240);
      text-align: center;
      margin-bottom: 8vw;
      cursor: pointer;
	  background:rgba(255, 255, 255);
      border-radius: 1vw;
      padding: 2vw 1vw;
	  padding-top:6vw;
    }
    .radio-card i {
      padding: 1.5vw 2.8vw;
      font-size: 4.5vw;
      border-radius: 1vw;
      border: 0.4vw solid rgba(240, 240, 240);
    }
    .radio-card h4 {
      margin-top: 4.5vw;
	  display:block;
      font-size: 3.5vw;
      line-height: 5.5vw;
      font-weight: 500;
    }
    .radio-card h6 {
      font-size: 2.8vw;
      line-height: 1.2vw;
      font-weight: 400;
      color: rgba(150, 150, 150);
    }
    .primary-button {
		margin:5vw 0;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
    }
    .primary-outline-button {
	  margin:5vw 0;
	  border-radius: 0.8vw;
      font-size: 4vw;
      font-weight: 500;
		padding: 1.8vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.4vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }
    label {
      font-size:3.5vw;
	  font-weight:500;
	  display:block;
	  color:rgb(75, 75, 75);
    }
    .input-form {
        display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
    }.success-area{
		padding-top:5vw;
	}.success-area img{
		width:25vw;
		height:auto;
		margin-bottom:2vw;
		object-fit:contain;
	}.success-area .secondary-heading{
		font-size:6vw;
		line-height:10vw;
	}.success-area .secondary-description{
		font-weight:400;
		font-size:4vw;
		line-height:6vw;
		color:rgba(145, 145, 145);
	}.active{
      border: 0.2vw solid rgba(2,96,105);
	}.error-message{
					font-size:3.5vw;
					font-weight:500;
					margin-top:0.5vw;
					color: rgba(231, 76, 60);
	}.input-error{
		color: rgba(231, 76, 60);
		border:0.14vw solid rgba(231, 76, 60);
	}
  }
}
}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
.contact-body {
	overflow-x: hidden;
  font-family: "Poppins", sans-serif;

  .main-area {
    padding: 0vh 1vw;
  }
  .left-area {
    
    height: 100vh;
    padding: 10vw 2vw;
    border-top: 0.1vw solid rgba(240, 240, 240);
	.main-content {
      padding-top: 2vw;
      flex-grow: 1;
      .logo {
        width: 8vw;
        height: auto;
        margin-bottom: 4vw;
		display:none;
      }
      .contact-option {
        margin-bottom: 4vw;
		padding:5vw 5vw;
      }
      .contact-option a {
        font-size: 8vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 1vw;
        padding: 1vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
      }
      .contact-option .heading {
        font-size: 4.2vw;
        line-height: 6.2vw;
        font-weight: 500;
      }
      .contact-option .description {
        font-size: 3vw;
        line-height: 4vw;
        color: rgba(120, 120, 120);
        font-weight: 400;
      }
      .contact-option .contact-link {
        font-size: 4vw;
        line-height: 5vw;
        font-weight: 600;
      }
    }
    .footer-content {
      padding: 5vw;
      a {
        font-size: 6vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 2.5vw;
        padding: 2vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
		
      }
    }
  }

  .right-area {
    padding: 1vw 8vw;


    h2 {
      font-size: 5vw;
      font-weight: 600;
      line-height: 7vw;
      text-align: center;
      margin: 10vw 0;
	  padding:0 1vw;
    }
    .steps-area {
      margin: 2vw 0vw;
    }
    .radio-card {
      border: 0.5vw solid rgba(240, 240, 240);
      text-align: center;
      margin-bottom: 8vw;
      cursor: pointer;
	  background:rgba(255, 255, 255);
      border-radius: 1vw;
      padding: 2vw 1vw;
	  padding-top:6vw;
    }
    .radio-card i {
      padding: 1.5vw 2.8vw;
      font-size: 4.5vw;
      border-radius: 1vw;
      border: 0.4vw solid rgba(240, 240, 240);
    }
    .radio-card h4 {
      margin-top: 4.5vw;
	  display:block;
      font-size: 3.5vw;
      line-height: 5.5vw;
      font-weight: 500;
    }
    .radio-card h6 {
      font-size: 2.8vw;
      line-height: 1.2vw;
      font-weight: 400;
      color: rgba(150, 150, 150);
    }
    .primary-button {
		margin:5vw 0;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
    }
    .primary-outline-button {
	  margin:5vw 0;
	  border-radius: 0.8vw;
      font-size: 4vw;
      font-weight: 500;
		padding: 1.8vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.4vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }
    label {
      font-size:3.5vw;
	  font-weight:500;
	  display:block;
	  color:rgb(75, 75, 75);
    }
    .input-form {
        display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
    }.success-area{
		padding-top:5vw;
	}.success-area img{
		width:25vw;
		height:auto;
		margin-bottom:2vw;
		object-fit:contain;
	}.success-area .secondary-heading{
		font-size:6vw;
		line-height:10vw;
	}.success-area .secondary-description{
		font-weight:400;
		font-size:4vw;
		line-height:6vw;
		color:rgba(145, 145, 145);
	}.active{
      border: 0.2vw solid rgba(2,96,105);
	}.error-message{
					font-size:3.5vw;
					font-weight:500;
					margin-top:0.5vw;
					color: rgba(231, 76, 60);
	}.input-error{
		color: rgba(231, 76, 60);
		border:0.14vw solid rgba(231, 76, 60);
	}
  }
}	
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
.contact-body {
	overflow-x: hidden;
  font-family: "Poppins", sans-serif;

  .main-area {
    padding: 0vh 1vw;
  }
  .left-area {
    
    height: 100vh;
    padding: 10vw 2vw;
    border-top: 0.1vw solid rgba(240, 240, 240);
	.main-content {
      padding-top: 2vw;
      flex-grow: 1;
      .logo {
        width: 8vw;
        height: auto;
        margin-bottom: 4vw;
		display:none;
      }
      .contact-option {
        margin-bottom: 4vw;
		padding:5vw 5vw;
      }
      .contact-option a {
        font-size: 8vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 1vw;
        padding: 1vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
      }
      .contact-option .heading {
        font-size: 4.2vw;
        line-height: 6.2vw;
        font-weight: 500;
      }
      .contact-option .description {
        font-size: 3vw;
        line-height: 4vw;
        color: rgba(120, 120, 120);
        font-weight: 400;
      }
      .contact-option .contact-link {
        font-size: 4vw;
        line-height: 5vw;
        font-weight: 600;
      }
    }
    .footer-content {
      padding: 5vw;
      a {
        font-size: 6vw;
        line-height: 2vw;
        color: rgba(10, 10, 10);
        margin-right: 2.5vw;
        padding: 2vw 4vw;
        border-radius: 1vw;
        color: rgba(50, 50, 50);
        border: 0.12vw solid rgba(240, 240, 240);
		
      }
    }
  }

  .right-area {
    padding: 1vw 8vw;


    h2 {
      font-size: 5vw;
      font-weight: 600;
      line-height: 7vw;
      text-align: center;
      margin: 10vw 0;
	  padding:0 1vw;
    }
    .steps-area {
      margin: 2vw 0vw;
    }
    .radio-card {
      border: 0.5vw solid rgba(240, 240, 240);
      text-align: center;
      margin-bottom: 8vw;
      cursor: pointer;
	  background:rgba(255, 255, 255);
      border-radius: 1vw;
      padding: 2vw 1vw;
	  padding-top:6vw;
    }
    .radio-card i {
      padding: 1.5vw 2.8vw;
      font-size: 4.5vw;
      border-radius: 1vw;
      border: 0.4vw solid rgba(240, 240, 240);
    }
    .radio-card h4 {
      margin-top: 4.5vw;
	  display:block;
      font-size: 3.5vw;
      line-height: 5.5vw;
      font-weight: 500;
    }
    .radio-card h6 {
      font-size: 2.8vw;
      line-height: 1.2vw;
      font-weight: 400;
      color: rgba(150, 150, 150);
    }
    .primary-button {
		margin:5vw 0;
		border-radius: 0.8vw;
		font-size:4vw;
		font-weight:500;
		padding: 1.8vw 1.2vw;
		color:rgba(255, 255, 255);
		border:0.4vw solid rgba(2,96,105);
		background:rgba(2,96,105);
    }
    .primary-outline-button {
	  margin:5vw 0;
	  border-radius: 0.8vw;
      font-size: 4vw;
      font-weight: 500;
		padding: 1.8vw 1.2vw;
      color: rgba(2, 96, 105);
      border: 0.4vw solid rgba(2, 96, 105);
      background: rgba(255, 255, 255);
    }
    label {
      font-size:3.5vw;
	  font-weight:500;
	  display:block;
	  color:rgb(75, 75, 75);
    }
    .input-form {
        display:block;
		width:100%;
		color: rgb(50, 50, 50);
		margin-top:1vw;
		margin-bottom:5vw;
		border: none;
		background:rgb(255, 255, 255);
		border: 0.4vw solid rgb(220, 220, 220);
		border-radius: 0.8vw;
		padding: 1.8vw 3vw;
		font-size: 4vw;
		font-weight:500;
		outline: none;
    }.success-area{
		padding-top:5vw;
	}.success-area img{
		width:25vw;
		height:auto;
		margin-bottom:2vw;
		object-fit:contain;
	}.success-area .secondary-heading{
		font-size:6vw;
		line-height:10vw;
	}.success-area .secondary-description{
		font-weight:400;
		font-size:4vw;
		line-height:6vw;
		color:rgba(145, 145, 145);
	}.active{
      border: 0.2vw solid rgba(2,96,105);
	}.error-message{
					font-size:3.5vw;
					font-weight:500;
					margin-top:0.5vw;
					color: rgba(231, 76, 60);
	}.input-error{
		color: rgba(231, 76, 60);
		border:0.14vw solid rgba(231, 76, 60);
	}
  }
}
}

