.blog-area {
	font-family: 'Poppins', sans-serif;
  
  .top-area {
	padding: 2vw;
	background-color: rgba(255, 255, 255);
  }
  
  .blog-poster {
	width: 100%;
	min-height: 25vw;
	height: auto;
	object-fit: cover;
	object-position: center;
	border-radius: 2vw;
  }
  
  .blog-area {
	position: relative;
	padding: 2vw;
  }
  
  .blog-content-area {
	padding: 5vw 4.5vw;
	position: absolute;
	top: 28vw;
	left: 0;
	width: 100%;
	height: 100%;
  }
  
  .blog-content {
	background-color: rgba(255, 255, 255);
	padding: 2vw;
	width: 100%;
	border-radius: 2vw;
  }
  
  .blog-category span {
	font-size: 1vw;
	font-weight: 600;
	display: inline-block;
	text-align: center;
	padding: 0.8vw 1vw;
	border-radius: 50vw;
	background-color: rgba(2, 73, 80);
	margin-right: 1vw;
	cursor: pointer;
	color: white;
  }
  
  .blog-date {
	color: rgba(75, 75, 75);
	font-size: 1vw;
	font-weight: 500;
	margin-right: 1vw;
  }
  
  .author-name {
	color: rgba(75, 75, 75);
	font-size: 1vw;
	font-weight: 500;
	margin: 0 1vw;
  }
  
  .author-name b {
	color: rgba(61, 61, 61);
	cursor: pointer;
  }
  
  .blog-title {
	font-weight: 600;
	font-size: 2vw;
	margin: 1.5vw 0;
	line-height: 2.5vw;
	letter-spacing: 0.03em;
  }
  
  .sharer-links {
	display: block;
	color: rgba(75, 75, 75);
	margin-bottom: 1.5vw;
	text-align: center;
	font-size: 1.4vw;
	padding: 0.5vw;
	background-color: rgba(251, 251, 251);
	border-radius: 0.4vw;
  }
  
  .share-link-area {
	margin-top: 5vw;
  }
  
  .sharer-links:hover {
	background: rgba(2, 73, 80);
	color: rgba(255, 255, 255);
  }
  
  .related-blog-poster {
	width: 100%;
	height: auto;
	object-fit: fill;
	border-radius: 1.2vw 1.2vw 0 0;
  }
  
  .related-blog-heading {
	font-weight: 500;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Number of lines to show */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1vw;
	line-height: 1.8vw;
	margin: 1vw 0;
	color: rgba(50, 50, 50);
  }
  
  .related-blog-subheading {
	font-size: 0.8vw;
	line-height: 1.5vw;
	color: rgba(128, 128, 128);
	font-weight: 400;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Number of lines to show */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .related-blog {
	margin: 2vw 0;
	background: #ffffff;
	border-radius: 1vw;
	cursor: pointer;
  }
  
  .related-blog:hover {
	opacity: 0.7;
  }
  
  .relative-blog-date {
	font-size: 0.8vw;
	color: rgba(156, 156, 156);
	font-weight: 400;
  }
  
  .relative-blog-author {
	font-size: 0.8vw;
	color: rgba(2, 73, 80);
	font-weight: 400;
	text-align: right;
  }
  
  .related-blog-content-area {
	padding: 1.5vw 1.5vw;
  }
  
  .blog-author-area {
	border-top: 0.2vw solid rgba(241, 241, 241);
	padding: 2.2vw 0;
  }
  
  .auth-image-logo {
	width: 10vw;
	height: 10vw;
	object-fit: cover;
	border-radius: 50%;
  }
  
  .auth-name {
	font-size: 1.5vw;
	font-weight: 600;
  }
  
  .auth-bio {
	font-size: 1vw;
	font-weight: 500;
	color: rgba(128, 128, 128);
  }
  
  .comment-section {
	background-color: rgba(255, 255, 255);
	padding: 3vw 3vw;
	width: 100%;
	border-radius: 2vw;
	margin: 2vw 0px;
	margin-bottom: 10vw;
  }
  
  .blog-start-area p {
	font-size: 1vw; /* Equivalent to 14px */
  }
  
  .blog-start-area h2, 
  .blog-start-area h3, 
  .blog-start-area h4, 
  .blog-start-area h5, 
  .blog-start-area h6 {
	font-weight: 600;
  }
  
  .blog-start-area h2 {
	font-size: 1.15vw; /* Equivalent to 22px */
  }
  
  .blog-start-area h3 {
	font-size: 1.05vw; /* Equivalent to 20px */
  }
  
  .blog-start-area h4 {
	font-size: 0.94vw; /* Equivalent to 18px */
  }
  
  .blog-start-area h5 {
	font-size: 0.84vw; /* Equivalent to 16px */
  }
  
  .blog-start-area h6 {
	font-size: 0.73vw; /* Equivalent to 14px */
  }
  
  a {
	cursor: pointer;
  }
  
  #snackbar {
	visibility: hidden;
	min-width: 250px;
	margin-left: -125px;
	background-color: rgba(2, 73, 80);
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 16px;
	font-weight: 500;
	position: fixed;
	z-index: 1;
	left: 50%;
	bottom: 30px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  @-webkit-keyframes fadein {
	from { bottom: 0; opacity: 0; } 
	to { bottom: 30px; opacity: 1; }
  }
  
  @keyframes fadein {
	from { bottom: 0; opacity: 0; }
	to { bottom: 30px; opacity: 1; }
  }
  
  @-webkit-keyframes fadeout {
	from { bottom: 30px; opacity: 1; } 
	to { bottom: 0; opacity: 0; }
  }
  
  @keyframes fadeout {
	from { bottom: 30px; opacity: 1; }
	to { bottom: 0; opacity: 0; }
  }
  
  .display_none {
	display: none;
  }
  
  .blog-start-area img {
	max-width: 100%; /* Ensure images don't exceed container width */
	height: auto; /* Maintain aspect ratio */
  }
  
  .popular-post {
	font-size: 1.5vw;
  }
  
  .desk-cat, .desk-social-media {
	display: block;
  }
  
  .mob-cat {
	display: none;
  }
}
  
  .blog-footer-area {
	margin-top: 80vw;
  }
  

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.blog-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		padding:2vw;
		
	.top-area{
	  padding:2vw;
	  background-color:rgba(255, 255, 255);
  }.blog-poster{
	  width:100%;
	  min-height:25vw;
	  height:auto;
	  object-fit:cover;object-position: center;
	  border-radius:2vw;
	  

  }.blog-area {
  padding:2vw 2vw;
}

.blog-content-area {
  padding:5vw 0.8vw;
  left: 0;
  width: 100%;
  height: 100%;
  
  
}.blog-content{
	background-color:rgba(255, 255, 255);;
	padding:2vw 2vw;
	width:100%;
	border-radius:2vw;
}
 .blog-category span{
	  font-size:4.2vw;
	  font-weight:600;
	  display:inline-block;
	  text-align:center;
	  padding:1.8vw 2.2vw;
	  border-radius:50vw;
	  background-color:rgba(2,73,80);
	  margin-right:1vw;
	  cursor:pointer;
	  color:white;
  }.blog-date{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  
	  margin-right:2vw;
  }.author-name{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  margin:0 1vw;
  }.desk-cat, .desk-social-media{
	display:none;
}.mob-cat{
display:inline-block;
}.author-name b{
	  color:rgba(61,61,61);
	  cursor:pointer;
  }.blog-title{
	  font-weight:600;
	  font-size:5.5vw;
	  margin-top:3.5vw;
	  line-height:5vw;
	  letter-spacing: 0.03em;
  }.sharer-links{
	  display:block;
	  color:rgba(75,75,75);
	  margin-bottom:1.5vw;
	  text-align:center;
	  font-size:3.5vw;
	  padding:0.5vw;
	  background-color:rgba(251,251,251);
	  border-radius:0.4vw;
  }.share-link-area{
	  margin-top:5vw;
  }.sharer-links:hover{
	  background:rgba(2,73,80);
	  color:rgba(255,255,255);
  }

.related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-blog {
  margin: 8vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.related-blog:hover {
  opacity: 0.7;
}

.relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.related-blog-content-area {
  padding: 4.5vw 4.5vw;
}.blog-author-area{
	border-top:0.2vw solid rgba(241, 241, 241);
    padding:2.2vw 0;	
  }.auth-image-logo{
	  width:10vw;height:10vw;object-fit:cover;
	  border-radius:50%;
  }.auth-name{
	  font-size:4vw;
	  font-weight:600;
  }.popular-post-area{
	padding:2vw 6vw;
}.auth-bio{
	  font-size:3.2vw;
	  font-weight:500;
	  color:rgba(128, 128, 128);
  }.comment-section{
	background-color:rgba(255, 255, 255);
	padding:3vw 3vw;
	width:100%;
	border-radius:2vw;
	margin:2vw 0px;
	margin-bottom:10vw;
}.related-blog:hover{
		opacity:0.7;
  }.blog-start-area p {
    font-size: 3.5vw; /* Equivalent to 14px */
}

.blog-start-area h2, 
.blog-start-area h3, 
.blog-start-area h4, 
.blog-start-area h5, 
.blog-start-area h6 {
    font-weight: 600;
}

.blog-start-area h2 {
    font-size: 2.15vw; /* Equivalent to 22px */
}

.blog-start-area h3 {
    font-size: 2.05vw; /* Equivalent to 20px */
}

.blog-start-area h4 {
    font-size: 1.94vw; /* Equivalent to 18px */
}

.blog-start-area h5 {
    font-size: 1.84vw; /* Equivalent to 16px */
}

.blog-start-area h6 {
    font-size: 1.73vw; /* Equivalent to 14px */
}
a{
	  cursor:pointer;
  }#snackbar {
	  visibility: hidden;
	  min-width: 250px;
	  margin-left: -125px;
	  background-color: rgba(2,73,80);
	  color: #fff;
	  text-align: center;
	  border-radius: 4px;
	  padding: 16px;
	  font-weight:500;
	  position: fixed;
	  z-index: 1;
	  left: 50%;
	  bottom: 30px;
	  font-size: 17px;
	}

	#snackbar.show {
	  visibility: visible;
	  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	  animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	@-webkit-keyframes fadein {
	  from {bottom: 0; opacity: 0;} 
	  to {bottom: 30px; opacity: 1;}
	}

	@keyframes fadein {
	  from {bottom: 0; opacity: 0;}
	  to {bottom: 30px; opacity: 1;}
	}

	@-webkit-keyframes fadeout {
	  from {bottom: 30px; opacity: 1;} 
	  to {bottom: 0; opacity: 0;}
	}

	@keyframes fadeout {
	  from {bottom: 30px; opacity: 1;}
	  to {bottom: 0; opacity: 0;}
	}.display_none{
		display:none;
	}.blog-start-area img {
                            max-width: 100%; /* Make sure images don't exceed the width of their container */
                            height: auto; /* Maintain the aspect ratio of the images */
	}.popular-post{
		font-size:5.5vw;
	}
}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
	.blog-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		padding:2vw;
		
	.top-area{
	  padding:2vw;
	  background-color:rgba(255, 255, 255);
  }.blog-poster{
	  width:100%;
	  min-height:25vw;
	  height:auto;
	  object-fit:cover;object-position: center;
	  border-radius:2vw;
	  

  }.blog-area {
  padding:2vw 2vw;
}

.blog-content-area {
  padding:5vw 0.8vw;
  left: 0;
  width: 100%;
  height: 100%;
  
  
}.blog-content{
	background-color:rgba(255, 255, 255);;
	padding:2vw 2vw;
	width:100%;
	border-radius:2vw;
}
 .blog-category span{
	  font-size:4.2vw;
	  font-weight:600;
	  display:inline-block;
	  text-align:center;
	  padding:1.8vw 2.2vw;
	  border-radius:50vw;
	  background-color:rgba(2,73,80);
	  margin-right:1vw;
	  cursor:pointer;
	  color:white;
  }.blog-date{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  
	  margin-right:2vw;
  }.author-name{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  margin:0 1vw;
  }.desk-cat, .desk-social-media{
	display:none;
}.mob-cat{
display:inline-block;
}.author-name b{
	  color:rgba(61,61,61);
	  cursor:pointer;
  }.blog-title{
	  font-weight:600;
	  font-size:5.5vw;
	  margin-top:3.5vw;
	  line-height:5vw;
	  letter-spacing: 0.03em;
  }.sharer-links{
	  display:block;
	  color:rgba(75,75,75);
	  margin-bottom:1.5vw;
	  text-align:center;
	  font-size:3.5vw;
	  padding:0.5vw;
	  background-color:rgba(251,251,251);
	  border-radius:0.4vw;
  }.share-link-area{
	  margin-top:5vw;
  }.sharer-links:hover{
	  background:rgba(2,73,80);
	  color:rgba(255,255,255);
  }

.related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-blog {
  margin: 8vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.related-blog:hover {
  opacity: 0.7;
}

.relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.related-blog-content-area {
  padding: 4.5vw 4.5vw;
}.blog-author-area{
	border-top:0.2vw solid rgba(241, 241, 241);
    padding:2.2vw 0;	
  }.auth-image-logo{
	  width:10vw;height:10vw;object-fit:cover;
	  border-radius:50%;
  }.auth-name{
	  font-size:4vw;
	  font-weight:600;
  }.popular-post-area{
	padding:2vw 6vw;
}.auth-bio{
	  font-size:3.2vw;
	  font-weight:500;
	  color:rgba(128, 128, 128);
  }.comment-section{
	background-color:rgba(255, 255, 255);
	padding:3vw 3vw;
	width:100%;
	border-radius:2vw;
	margin:2vw 0px;
	margin-bottom:10vw;
}.related-blog:hover{
		opacity:0.7;
  }.blog-start-area p {
    font-size: 3.5vw; /* Equivalent to 14px */
}

.blog-start-area h2, 
.blog-start-area h3, 
.blog-start-area h4, 
.blog-start-area h5, 
.blog-start-area h6 {
    font-weight: 600;
}

.blog-start-area h2 {
    font-size: 2.15vw; /* Equivalent to 22px */
}

.blog-start-area h3 {
    font-size: 2.05vw; /* Equivalent to 20px */
}

.blog-start-area h4 {
    font-size: 1.94vw; /* Equivalent to 18px */
}

.blog-start-area h5 {
    font-size: 1.84vw; /* Equivalent to 16px */
}

.blog-start-area h6 {
    font-size: 1.73vw; /* Equivalent to 14px */
}
a{
	  cursor:pointer;
  }#snackbar {
	  visibility: hidden;
	  min-width: 250px;
	  margin-left: -125px;
	  background-color: rgba(2,73,80);
	  color: #fff;
	  text-align: center;
	  border-radius: 4px;
	  padding: 16px;
	  font-weight:500;
	  position: fixed;
	  z-index: 1;
	  left: 50%;
	  bottom: 30px;
	  font-size: 17px;
	}

	#snackbar.show {
	  visibility: visible;
	  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	  animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	@-webkit-keyframes fadein {
	  from {bottom: 0; opacity: 0;} 
	  to {bottom: 30px; opacity: 1;}
	}

	@keyframes fadein {
	  from {bottom: 0; opacity: 0;}
	  to {bottom: 30px; opacity: 1;}
	}

	@-webkit-keyframes fadeout {
	  from {bottom: 30px; opacity: 1;} 
	  to {bottom: 0; opacity: 0;}
	}

	@keyframes fadeout {
	  from {bottom: 30px; opacity: 1;}
	  to {bottom: 0; opacity: 0;}
	}.display_none{
		display:none;
	}.blog-start-area img {
                            max-width: 100%; /* Make sure images don't exceed the width of their container */
                            height: auto; /* Maintain the aspect ratio of the images */
	}.popular-post{
		font-size:5.5vw;
	}
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.blog-area{
		font-family: 'Poppins', sans-serif;
		background-color:rgba(255, 255, 255);
		padding:2vw;
		
	.top-area{
	  padding:2vw;
	  background-color:rgba(255, 255, 255);
  }.blog-poster{
	  width:100%;
	  min-height:25vw;
	  height:auto;
	  object-fit:cover;object-position: center;
	  border-radius:2vw;
	  

  }.blog-area {
  padding:2vw 2vw;
}

.blog-content-area {
  padding:5vw 0.8vw;
  left: 0;
  width: 100%;
  height: 100%;
  
  
}.blog-content{
	background-color:rgba(255, 255, 255);;
	padding:2vw 2vw;
	width:100%;
	border-radius:2vw;
}
 .blog-category span{
	  font-size:4.2vw;
	  font-weight:600;
	  display:inline-block;
	  text-align:center;
	  padding:1.8vw 2.2vw;
	  border-radius:50vw;
	  background-color:rgba(2,73,80);
	  margin-right:1vw;
	  cursor:pointer;
	  color:white;
  }.blog-date{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  
	  margin-right:2vw;
  }.author-name{
  color:rgba(75,75,75);
	  font-size:4vw;
  font-weight:500;
	  margin:0 1vw;
  }.desk-cat, .desk-social-media{
	display:none;
}.mob-cat{
display:inline-block;
}.author-name b{
	  color:rgba(61,61,61);
	  cursor:pointer;
  }.blog-title{
	  font-weight:600;
	  font-size:5.5vw;
	  margin-top:3.5vw;
	  line-height:5vw;
	  letter-spacing: 0.03em;
  }.sharer-links{
	  display:block;
	  color:rgba(75,75,75);
	  margin-bottom:1.5vw;
	  text-align:center;
	  font-size:3.5vw;
	  padding:0.5vw;
	  background-color:rgba(251,251,251);
	  border-radius:0.4vw;
  }.share-link-area{
	  margin-top:5vw;
  }.sharer-links:hover{
	  background:rgba(2,73,80);
	  color:rgba(255,255,255);
  }

.related-blog-poster {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 4vw 4vw 0 0;
}

.related-blog-heading {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vw;
  margin: 1vw 0;
  color: rgba(50, 50, 50);
}

.related-blog-subheading {
  font-size: 4vw;
  line-height: 6vw;
  color: rgba(128, 128, 128);
  font-weight: 400;
  margin-top: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-blog {
  margin: 8vw 0;
  background: #ffffff;
  border-radius: 4vw;
  cursor: pointer;
}

.related-blog:hover {
  opacity: 0.7;
}

.relative-blog-date {
  font-size: 4vw;
  color: rgba(156, 156, 156);
  font-weight: 400;
}

.relative-blog-author {
  font-size: 4vw;
  color: rgba(2,73,80);
  font-weight: 400;
  text-align: right;
}

.relative-blog-date i {
  width: 1.5vw;
  height: 1.5vw;
  color: rgba(75, 75, 75);
  margin-bottom: 1vw;
  margin-right: 0.5vw;
}

.related-blog-content-area {
  padding: 4.5vw 4.5vw;
}.blog-author-area{
	border-top:0.2vw solid rgba(241, 241, 241);
    padding:2.2vw 0;	
  }.auth-image-logo{
	  width:10vw;height:10vw;object-fit:cover;
	  border-radius:50%;
  }.auth-name{
	  font-size:4vw;
	  font-weight:600;
  }.popular-post-area{
	padding:2vw 6vw;
}.auth-bio{
	  font-size:3.2vw;
	  font-weight:500;
	  color:rgba(128, 128, 128);
  }.comment-section{
	background-color:rgba(255, 255, 255);
	padding:3vw 3vw;
	width:100%;
	border-radius:2vw;
	margin:2vw 0px;
	margin-bottom:10vw;
}.related-blog:hover{
		opacity:0.7;
  }.blog-start-area p {
    font-size: 3.5vw; /* Equivalent to 14px */
}

.blog-start-area h2, 
.blog-start-area h3, 
.blog-start-area h4, 
.blog-start-area h5, 
.blog-start-area h6 {
    font-weight: 600;
}

.blog-start-area h2 {
    font-size: 2.15vw; /* Equivalent to 22px */
}

.blog-start-area h3 {
    font-size: 2.05vw; /* Equivalent to 20px */
}

.blog-start-area h4 {
    font-size: 1.94vw; /* Equivalent to 18px */
}

.blog-start-area h5 {
    font-size: 1.84vw; /* Equivalent to 16px */
}

.blog-start-area h6 {
    font-size: 1.73vw; /* Equivalent to 14px */
}
a{
	  cursor:pointer;
  }#snackbar {
	  visibility: hidden;
	  min-width: 250px;
	  margin-left: -125px;
	  background-color: rgba(2,73,80);
	  color: #fff;
	  text-align: center;
	  border-radius: 4px;
	  padding: 16px;
	  font-weight:500;
	  position: fixed;
	  z-index: 1;
	  left: 50%;
	  bottom: 30px;
	  font-size: 17px;
	}

	#snackbar.show {
	  visibility: visible;
	  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	  animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	@-webkit-keyframes fadein {
	  from {bottom: 0; opacity: 0;} 
	  to {bottom: 30px; opacity: 1;}
	}

	@keyframes fadein {
	  from {bottom: 0; opacity: 0;}
	  to {bottom: 30px; opacity: 1;}
	}

	@-webkit-keyframes fadeout {
	  from {bottom: 30px; opacity: 1;} 
	  to {bottom: 0; opacity: 0;}
	}

	@keyframes fadeout {
	  from {bottom: 30px; opacity: 1;}
	  to {bottom: 0; opacity: 0;}
	}.display_none{
		display:none;
	}.blog-start-area img {
                            max-width: 100%; /* Make sure images don't exceed the width of their container */
                            height: auto; /* Maintain the aspect ratio of the images */
	}.popular-post{
		font-size:5.5vw;
	}
}

}

/* Laptops and desktops */