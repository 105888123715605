.home-body{
	font-family: "Poppins", sans-serif;
	background-color: rgba(250, 250, 250);
	height:100vh;
	.top-area{
		padding:4vw 2vw;
		background: linear-gradient(to right, rgba(250, 254, 255), rgba(250, 254, 255));
	}.home-blog-poster {
		width: 100%;
		height: 180px;
		object-fit: fill;
		border-radius: clamp(0.8vw, 1.2vw, 1.5vw) clamp(0.8vw, 1.2vw, 1.5vw) 0 0;
	  }
	  
	  .home-blog-heading {
		font-weight: 500;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: clamp(1rem, 1vw, 1.5rem);
		margin: clamp(0.5vw, 1vw, 1rem) 0;
		color: rgba(50, 50, 50);
	  }
	  
	  .home-blog-subheading {
		font-size: clamp(0.8rem, 0.8vw, 1.2rem);
		line-height: clamp(1.2rem, 1.2vw, 2rem);
		color: rgba(128, 128, 128);
		font-weight: 400;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	  }
	  
	  .home-blog {
		margin: clamp(0.5vw, 1vw, 1.2vw) 0;
		background: #ffffff;
		border-radius: clamp(0.5vw, 1vw, 1.5vw);
		cursor: pointer;
		margin: 0 20px;
	  }
	  
	  .home-blog:hover {
		opacity: 0.7;
	  }.home-blog-date{
		color: rgba(100, 100, 100);
	  }.home-blog-author{
		color: rgba(10, 10, 10);
	  }
	  
	  .home-blog-content-area {
		padding: clamp(1vw, 1.5vw, 2vw) clamp(1vw, 1.5vw, 2vw);
	  }.main-heading{
		display:block;
		text-align:center;
		padding:1vw 5vw;
		padding:1vw 25vw;
		font-weight:600;
		font-size:2.2vw;
		line-height:3.5vw;
	}.main-subheading{
		display:block;
		padding:1vw 20vw;
		color:rgba(100, 100, 100);
		font-size:1.25vw;
		line-height:1.50vw;
		text-align:center;
	}.primary-button{
		  border-radius: 0.4vw;
		  font-size:0.8vw;
		  font-weight:500;
		  padding: 0.5vw 1.2vw;
		  color:rgba(255, 255, 255);
		  border:0.2vw solid rgba(2,96,105);
		  background:rgba(2,96,105);
		  display:inline-block;
	  }.secondary-button{
		  border-radius: 0.4vw;
		  font-size:0.8vw;
		  font-weight:500;
		  display:inline-block;
		  padding: 0.5vw 1.2vw;
		  color:rgba(2,96,105);
		  border:0.2vw solid rgba(2,96,105);
		  background:rgba(255,255,255);
	  }.top-area button{
		  margin:1vw 0.5vw;
		  font-size:1vw;
		  border-radius:50vw;
	  }.top-area p{
		  font-size:1vw;
		  line-height:1.5vw;
		  margin:0.5vw;
		  color:rgba(10, 10, 10);
	  }.blocks-container {
		display: flex;
		margin:2vw 5vw;
		margin-top:5vw;
		align-items: flex-end; /* Aligns all blocks to the bottom */
		justify-content: space-between; /* Optional: Spreads blocks evenly */
		height: 15vw; /* The overall height of the container */
	  }

	  .block {
		background-color: rgba(255, 255, 255);
		border-radius:1vw;
		border:0.1vw solid rgba(240,240,240);
		box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
	  }

	  .block1 {
		width: 14vw;
		height: 20vw;
		img{
			width: 14vw;
			height: 20vw;
			object-fit: cover;
			border-radius: 1vw;
		}
	  }

	  .block2 {
		display: flex;
		flex-direction: column; /* Stack the elements vertically */
		justify-content: center; /* Center the items vertically */
		align-items: center;  
		width: 12vw;
		padding: 0.5vw;
		background-color: rgb(0, 58, 63);
		height: 15vw;      /* Ensure it takes the full height of the viewport */
		text-align: center; 
		.heading-block{
			font-weight: 600;
			font-size: 2vw;
			color: rgb(211, 255, 190);
		}
		.subheading-block{
			font-weight: 400;
			font-size: 1vw;
			line-height: 1.8vw;
			color:rgba(242, 242, 242);
		}.bi{
			color: rgb(211, 255, 190);
			background-color: rgb(10, 10, 10);
			padding: 0.5vw 0.8vw;
			margin: 1.2vw 0;
			font-size: 1vw;
			border-radius: 0.5vw;
			display: inline-block;
		}
	  }

	  .block3 {
		width: 16vw;
		height: 12vw;
		padding: 1vw;
		.subheading-block{
			font-weight: 400;
			font-size: 1vw;
			line-height: 1.8vw;
			color:rgba(245, 245, 245);
			
		}
		.heading-block{
			font-weight: 600;
			font-size: 1.5vw;
		}.description-block{
			font-weight: 400;
			font-size: 1vw;
			color:rgba(100, 100, 100);
		}.bi{
			color: rgb(211, 255, 190);
			background-color: rgb(0, 58, 63);
			padding: 0.5vw 1vw;
			margin: 1.2vw 0;
			font-size: 1vw;
			border-radius: 0.5vw;
			display: inline-block;
		}
	  }

	  .block4 {
		display: flex;
		flex-direction: column; /* Stack the elements vertically */
		justify-content: center; /* Center the items vertically */
		align-items: center;  
		text-align: center;
		background-color: rgb(211, 255, 190);
		width: 12vw;
		height: 15vw;
		.heading-block{
			font-weight: 600;
			font-size: 2vw;
			color:rgba(10, 10, 10);
		}
		.subheading-block{
			font-weight: 400;
			font-size: 1vw;
			line-height: 1.8vw;
			color:rgba(100, 100, 100);
		}.bi{
			color: rgb(211, 255, 190);
			background-color: rgb(0, 58, 63);
			padding: 0.5vw 0.8vw;
			margin: 1.2vw 0;
			font-size: 1vw;
			border-radius: 0.5vw;
			display: inline-block;
		}
	  }

	  .block5 {
		padding: 1.5vw;
		display: flex;
		flex-direction: column; /* Stack the elements vertically */
		justify-content: flex-end; /* Center the items vertically */
		align-items: left;  
		text-align: left;
		background-color: rgb(0, 58, 63);
		width: 14vw;
		height: 20vw;
		.heading-block{
			font-weight: 500;
			line-height: 2.2vw;
			font-size: 1.4vw;
			color:rgba(255, 255, 255);
			
		}.bi{
			color: rgb(211, 255, 190);
			margin: 1.2vw 0;
			font-size: 2vw;
			border-radius: 0.5vw;
			display: inline-block;
		}
	  }
	  
	 
	  .info-block{
		  padding:5vw 2vw;	
		  border-radius:1vw;	
		  margin:4vw 5vw;
		  background:rgba(255, 255, 255);
		  background-image:none;
		  box-shadow: 0 .125rem .25rem rgba(10, 10, 10, .2);
		  background-position: right top; /* Aligns the image to the top-right */
		  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
		  background-size: contain; /* Ensures the image maintains its aspect ratio */
		  height: 36vw; /* Adjust the height as needed */
		  width: 90vw; 
	  }.info-block-content-area{
		  width:40vw;
	  }.infoblock-heading{
		  font-size:1.5vw;
		  line-height:3vw;
		  font-weight:600;
	  }.infoblock-subheading{
		  font-size:1vw;
		  color:rgba(50, 50, 50);
		  margin:1.5vw 0;
		  font-weight:500;
	  }.info-block-bullet{
		  font-size:1vw;
		  margin:1vw 0;
		  color:rgba(100, 100, 100);
		  line-height:1.5vw;
	  }.info-block-bullet .bi{
		  margin-right:0.5vw;
	  }.stack-container {
		position: relative;
		height: 900vh; /* Increased height to accommodate four items */
	  }

	  .stack-item {
		position: sticky;
		top: 5vw;
		width: 100%;
		height: 100vh; /* Each item takes up the full viewport height */
		z-index: 1;
		transition: transform 0.5s ease, opacity 0.5s ease;
		transform-origin: top left; /* Shrink from the top left */
	  }

	  .stack-item.shrink {
		transform: scale(0.9); /* Shrinks the previous stack item */
		opacity: 0.8; /* Lower opacity to give a stacked feel */
	  }

	  .stack-item:nth-child(1) {
		z-index: 1;
	  }
	  .stack-item:nth-child(2) {
		z-index: 2;
	  }
	  .stack-item:nth-child(3) {
		z-index: 3;
	  }
	  .stack-item:nth-child(4) {
		z-index: 4;
	  }
	  .stack-item:nth-child(5) {
		z-index: 5;
	  }
	  .stack-item:nth-child(6) {
		z-index: 6;
	  }
	  .stack-item:nth-child(7) {
		z-index: 7;
	  }
	  .stack-item:nth-child(8) {
		z-index: 8;
	  }.stack-container-heading{
		  font-size:3vw;
		  display:block;
		  text-align:center;
		  margin:5vw;
		  font-weight:600;
	  }.info-area{
		  padding:2vw 0;
		  padding-bottom:4vw 0;
		  background:rgba(245, 245, 245);
	  }.pricing-area{
		  padding:4vw 8vw;
	  }.pricing-area-heading{
		  display:block;
		  font-size:1.5vw;
		  text-align:center;
		  font-weight:600;
		  margin:1vw;
	  }.pricing-area-subheading{
		  display:block;
		  font-size:1vw;
		  text-align:center;
		  font-weight:500;
		  color:rgba(100, 100, 100);
	  }.pricing-col{
		  margin:2vw 0;
		  border:0.15vw solid rgba(240, 240, 240);
		  border-radius:1vw;
		  padding:2vw;
	  }.pricing-col-heading{
		  font-size:1.2vw;
		  font-weight:600;
	  }.pricing-col-subheading{
		  font-size:0.8vw;
		  line-height:1.5vw;
		  font-weight:400;
		  color:rgba(150, 150, 150);
	  }.pricing-col-price{
		  font-size:1.5vw;
		  font-weight:600;
		  margin:2vw 0;
	  }.pricing-col-price span{
		  font-size:0.8vw;
		  color:rgba(150, 150, 150);
	  }.pricing-col button{
		  border-radius:50vw;
		  width:100%;
		  margin-bottom:1vw;
	  }.feature-list{
		  font-size:1vw;
		  margin:1vw 0;
		  color:rgba(50, 50, 50);
	  }.feature-list .bi{
		  margin-right:0.5vw;
		  color:rgba(2,96,105);
	  }.divider-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom:2vw;
	  }

	  .line {
		flex-grow: 1;
		height: 0.1vw;
		background-color: rgba(150, 150, 150);
		border: none;
	  }

	  .divider-text {
		padding: 0 1vw;
		font-size: 1vw;
		color: rgba(100, 100, 100);
	  }.custom-plan-area{
		  
				background: linear-gradient(to right, rgba(2,96,105), rgba(2,96,105, 0.8));
				padding:2vw;
				border-radius:1vw;
	  }.custom-plan-heading{
		  font-size:1.5vw;
		  display:block;
		  text-align:center;
		  color:rgba(255, 255, 255);
		  margin:1vw;
	  }.custom-plan-subheading{
		  font-size:1vw;
		  display:block;
		  text-align:center;
		  font-weight:400;
		  margin-top:2vw;
		  color:rgba(242, 242, 242);
	  }.custom-plan-area button{
		  margin:2vw 1vw;
		  border:none;
		  padding:1vw 2vw;
		  
	  }.custom-plan-area .primary-button{
		  background:rgba(10,10,10);
	  }

	  .contact-us-section{
		  
				background: rgba(0, 88, 88);
				padding:4vw 2vw;
	  }.contact-us-section-heading{
		  display:block;
		  font-size:1.5vw;
		  text-align:center;
		  margin:1vw 0;
		  color:rgba(255, 255, 255);
	  }.contact-us-section-subheading{
		  display:block;
		  font-size:1vw;
		  margin:2vw 0;
		  color:rgba(240, 240, 240);
		  text-align:center;
	  }.contact-us-section button{
		  background:rgba(255, 255, 255);	
		  border:none;
		  color:rgba(2,96,105);
		  margin:1vw 0;
		  border-radius:10vw;
		  padding:0.8vw 2vw;
	  }.faqss-area{
		padding:1vw 2vw;
	}.faqss-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:2vw;
		line-height:3vw;
		font-weight:500;
	}.faqss-area .description{
		text-align:center;
		font-size:1.1vw;
		font-weight:500;
		color:rgba(50,50,50);
	}.accordion {
	  max-width: 50vw;
	  margin: 2vw auto;
	}
	
	.faq-item {
	  border-bottom: 0.1vw solid rgba(240, 240, 240);
	  padding: 0.8vw 0;
	}
	
	.faq-question {
	  cursor: pointer;
	  padding: 1vw;
	  border-radius:0.5vw;
	  background-color: rgba(245, 245, 245);
	  font-weight: 600;
	  display: flex;
	  font-size:1vw;
	  justify-content: space-between;
	  align-items: center;
	}
	
	.faq-question.active {
	  background-color: rgba(235, 235, 235);
	}
	
	.faq-answer {
	  max-height: 0;
	  overflow: hidden;
	  transition: max-height 0.3s ease;
	  padding: 0 1vw;
	  font-size:1vw;
	  font-weight:500;
	}
	
	.faq-answer.open {
	  max-height: 20vw;
	  padding: 1.5vw 1vw;
	  margin-top: 1vw;
	  border-radius: 0.5vw;
	  background-color: #fefefe;
	}
}

/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
	.home-body{
		  font-family: "Poppins", sans-serif;
		  background-color: rgba(250, 250, 250);
		  height:100vh;
		  .top-area{
			  padding:5vw 1vw;
			  background: linear-gradient(to right, rgba(250, 254, 255), rgba(250, 254, 255));
		  }.main-heading{
			  display:block;
			  text-align:center;
			  padding:1vw 1vw;
			  padding:1vw 2vw;
			  font-weight:600;
			  font-size:5vw;
			  line-height:6.5vw;
		  }.main-subheading{
			  display:block;
			  padding:1vw 10vw;
			  color:rgba(100, 100, 100);
			  font-size:3.25vw;
			  line-height:4.50vw;
			  text-align:center;
		  }.primary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				padding: 0.5vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(2,96,105);
				display:inline-block;
			}.secondary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				display:inline-block;
				padding: 0.5vw 1.2vw;
				color:rgba(2,96,105);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(255,255,255);
			}.top-area button{
				margin:2vw 1vw;
				padding:2vw 4vw;
				font-size:4.2vw;
				border-radius:50vw;
			}.top-area p{
				font-size:3.5vw;
				line-height:5vw;
				margin:2vw;
				color:rgba(10, 10, 10);
			}
			
			.blocks-container {
				margin: 2vw 5vw;
				margin-top: 10vw;
				overflow-x: auto;  /* Allow horizontal scrolling */
				overflow-y: hidden;  /* Hide vertical scroll */
				height: 40vw;
				width: 100%;
				display: flex;  /* Flex layout for inline blocks */
				gap: 5vw;  /* Space between blocks */
			  }
			  
			  .block {
				background-color: rgba(255, 255, 255);
				display: flex;  /* Use flex for internal alignment */
				flex-direction: column;  /* Stack content vertically */
				border-radius: 1vw;
				border: 0.1vw solid rgba(240, 240, 240);
				box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
				overflow: hidden;  /* Hide any overflowed content */
			  }
			  
			  
			  
			  .block1, .block2, .block3, .block4, .block5 {
				flex-shrink: 0; /* Prevent blocks from shrinking */
				margin-right: 2vw; /* Space between blocks */
				
				border-radius: 2vw;
			  }
			  
		
			  .block1 {
				display: inline-block;
				width: 28vw;
				height: 40vw;
				img{
					width: 28vw;
					height: 40vw;
					object-fit: cover;
					border-radius: 1vw;
				}
			  }
		
			  .block2 {
				background-color: rgb(0, 58, 63);
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: center; /* Center the items vertically */
				align-items: center;  
				text-align: center;
				width: 30vw;
				height: 32vw;
				.heading-block{
					font-weight: 600;
					font-size: 3.5vw;
					margin:1vw 0;
					color: rgb(211, 255, 190);
				}
				.subheading-block{
					font-weight: 400;
					font-size: 2vw;
					line-height: 3vw;
					margin-top: 0;
					color:rgba(242, 242, 242);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(10, 10, 10);
					padding: 1vw 1.8vw;
					margin: 3vw 0;
					font-size: 3vw;
					border-radius: 1vw;
					display: inline-block;
				}
			  }
		
			  .block3 {
				display: inline-block;
				width: 46vw;
				height: 24vw;
				padding: 2vw 4vw;
				.subheading-block{
					font-weight: 400;
					font-size: 1vw;
					line-height: 1.8vw;
					color:rgba(245, 245, 245);
					
				}
				.heading-block{
					font-weight: 600;
					font-size: 3.5vw;
				}.description-block{
					font-weight: 400;
					margin-top: 0;
					font-size: 2.5vw;
					color:rgba(100, 100, 100);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(0, 58, 63);
					padding: 1vw 2vw;
					margin: 1.2vw 0;
					font-size: 3vw;
					border-radius: 1vw;
					display: inline-block;
				}
			  }
		
			  .block4 {
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: center; /* Center the items vertically */
				align-items: center;  
				text-align: center;
				background-color: rgb(211, 255, 190);
				width: 30vw;
				height: 32vw;
				.heading-block{
					font-weight: 600;
					font-size: 5vw;
					color:rgba(10, 10, 10);
				}
				.subheading-block{
					font-weight: 400;
					font-size: 2.6vw;
					line-height: 4vw;
					color:rgba(100, 100, 100);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(0, 58, 63);
					padding: 0.5vw 0.8vw;
					margin: 2vw 0;
					font-size: 3vw;
					border-radius: 0.5vw;
					display: inline-block;
				}
			  }
		
			  .block5 {
				padding: 1.5vw 4vw;
				margin-right: 10vw;
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: flex-end; /* Center the items vertically */
				align-items: left;  
				text-align: left;
				background-color: rgb(0, 58, 63);
				width: 28vw;
				height: 40vw;
				.heading-block{
					font-weight: 500;
					line-height: 4vw;
					font-size: 2.5vw;
					color:rgba(255, 255, 255);
					
				}.bi{
					color: rgb(211, 255, 190);
					margin: 4vw 0;
					font-size: 4vw;
					margin-top: 6vw;
					border-radius: 0.5vw;
					display: inline-block;
				}
			  }
			  
			.info-block{
				padding:5vw 2vw;	
				border-radius:4vw;	
				margin:4vw 5vw;
				background:rgba(255, 255, 255);
			    box-shadow: 0 .125rem .25rem rgba(10, 10, 10, .2);
			    background-position: right top; /* Aligns the image to the top-right */
			    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
			    background-size: contain; /* Ensures the image maintains its aspect ratio */
			    height: 140vw; /* Adjust the height as needed */
			    width: 90vw; 
			}.info-block-content-area{
				width:80vw;
				margin-top:60vw;
				padding:2vw;
			}.infoblock-heading{
				font-size:5vw;
				line-height:8vw;
				font-weight:600;
			}.infoblock-subheading{
				font-size:4vw;
				line-height:6vw;
				color:rgba(50, 50, 50);
				margin:1.5vw 0;
				font-weight:500;
			}.info-block-bullet{
				font-size:4vw;
				margin:3vw 0;
				color:rgba(100, 100, 100);
				line-height:1.5vw;
			}.info-block-bullet .bi{
				margin-right:0.5vw;
			}.stack-container {
			  position: relative;
			  height: 900vh; /* Increased height to accommodate four items */
			}

			.stack-item {
			  position: sticky;
			  top: 5vw;
			  width: 100%;
			  height: 100vh; /* Each item takes up the full viewport height */
			  z-index: 1;
			  transition: transform 0.5s ease, opacity 0.5s ease;
			  transform-origin: top left; /* Shrink from the top left */
			}

			.stack-item.shrink {
			  transform: scale(0.9); /* Shrinks the previous stack item */
			  opacity: 0.8; /* Lower opacity to give a stacked feel */
			}

			.stack-item:nth-child(1) {
			  z-index: 1;
			}
			.stack-item:nth-child(2) {
			  z-index: 2;
			}
			.stack-item:nth-child(3) {
			  z-index: 3;
			}
			.stack-item:nth-child(4) {
			  z-index: 4;
			}
			.stack-item:nth-child(5) {
			  z-index: 5;
			}
			.stack-item:nth-child(6) {
			  z-index: 6;
			}
			.stack-item:nth-child(7) {
			  z-index: 7;
			}
			.stack-item:nth-child(8) {
			  z-index: 8;
			}.stack-container-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				margin:5vw;
				font-weight:600;
			}.info-area{
				padding:2vw 0;
				padding-bottom:4vw 0;
				background:rgba(245, 245, 245);
			}.pricing-area{
				padding:4vw 6vw;
			}.pricing-area-heading{
				display:block;
				font-size:4.5vw;
				text-align:center;
				font-weight:600;
				margin:1vw;
			}.pricing-area-subheading{
				display:block;
				font-size:3vw;
				text-align:center;
				font-weight:500;
				color:rgba(100, 100, 100);
			}.col-price{
				display:inline-block;
			}.pricing-col{
				margin:2vw 0;
				border:0.15vw solid rgba(240, 240, 240);
				border-radius:4vw;
				padding:3vw;
			}.pricing-col-heading{
				font-size:4.6vw;
				font-weight:600;
			}.pricing-col-subheading{
				font-size:3vw;
				line-height:4.5vw;
				font-weight:400;
				color:rgba(150, 150, 150);
			}.pricing-col-price{
				font-size:5vw;
				font-weight:600;
				margin:5vw 0;
			}.pricing-col-price span{
				font-size:2.5vw;
				color:rgba(150, 150, 150);
			}.pricing-col button{
				border-radius:50vw;
				width:100%;
				padding:1vw 0;
				font-size:4vw;
				margin-bottom:1vw;
			}.feature-list{
				font-size:3vw;
				margin:2vw 0;
				color:rgba(50, 50, 50);
			}.feature-list .bi{
				margin-right:0.5vw;
				color:rgba(2,96,105);
			}.divider-container {
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  margin-bottom:2vw;
			}

			.line {
			  flex-grow: 1;
			  height: 0.1vw;
			  background-color: rgba(150, 150, 150);
			  border: none;
			}

			.divider-text {
			  padding: 0 2vw;
			  font-size: 3.2vw;
			  color: rgba(100, 100, 100);
			}.custom-plan-area{
					  background: linear-gradient(to right, rgba(2,96,105), rgba(2,96,105, 0.8));
					  padding:8vw 4vw;
					  border-radius:5vw;
					  margin:4vw 0;
			}.custom-plan-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				color:rgba(255, 255, 255);
				margin:2vw;
			}.custom-plan-subheading{
				font-size:3vw;
				padding:0 2vw;
				line-height:4vw;
				display:block;
				text-align:center;
				font-weight:400;
				margin-top:2vw;
				color:rgba(242, 242, 242);
			}.custom-plan-area button{
				margin:2vw 2vw;
				font-size:3vw;
				border-radius:2vw;
				border:none;
				padding:2vw 3vw;
				
			}.custom-plan-area .primary-button{
				background:rgba(10,10,10);
			}

			.contact-us-section{
				
					  background: rgba(0, 88, 88);
					  padding:6vw 4vw;
			}.contact-us-section-heading{
				display:block;
				font-size:5vw;
				text-align:center;
				margin:1vw 0;
				color:rgba(255, 255, 255);
			}.contact-us-section-subheading{
				display:block;
				font-size:3vw;
				line-height:4vw;
				margin:2vw 0;
				color:rgba(240, 240, 240);
				text-align:center;
			}.contact-us-section button{
				background:rgba(255, 255, 255);	
				border:none;
				font-size:3vw;
				color:rgba(2,96,105);
				margin:4vw 0;
				border-radius:10vw;
				padding:2vw 4vw;
			}.home-pricing-area .horizontalrow{
			  display: flex; /* Use flexbox for horizontal layout */
			  flex-wrap: nowrap; /* Prevent wrapping */
			  overflow:scroll;
			}.home-pricing-area .horizontalrow > div {
			  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
			  width: 100%; /* Set a specific width for each column */
			  margin-right:-12vw;
			}.faqss-area{
				padding:1vw 0vw;
			}.faqss-area .heading{
				text-align:center;
				color:rgba(2, 96, 105);
				font-size:4vw;
				line-height:5vw;
				font-weight:500;
			}.faqss-area .description{
				text-align:center;
				font-size:3vw;
				font-weight:500;
				line-height:4vw;
				color:rgba(50,50,50);
			}.accordion {
			  max-width: 100%;
			  margin: 4vw auto;
			}
		
			.faq-item {
			  border-bottom: 0.5vw solid rgba(240, 240, 240);
			  padding: 0.8vw 0;
			  margin:2.5vw 0;
			}
		
			.faq-question {
			  cursor: pointer;
			  padding: 2vw;
			  border-radius:1vw;
			  background-color: rgba(245, 245, 245);
			  font-weight: 500;
			  display: flex;
			  font-size:3.5vw;
			  justify-content: space-between;
			  align-items: center;
			}
		
			.faq-question.active {
			  background-color: rgba(240, 240, 240);
			}
		
			.faq-answer {
			  max-height: 0;
			  overflow: hidden;
			  transition: max-height 0.3s ease;
			  padding: 0.5vw 1vw;
			  font-size:3vw;
			  font-weight:500;
			}
		
			.faq-answer.open {
			  max-height: 50vw;
			  padding: 2.5vw 1vw;
			}
	}

}

/* Tablets in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
	.home-body{
		  font-family: "Poppins", sans-serif;
		  background-color: rgba(250, 250, 250);
		  height:100vh;
		  .top-area{
			  padding:5vw 1vw;
			  background: linear-gradient(to right, rgba(250, 254, 255), rgba(250, 254, 255));
		  }.main-heading{
			  display:block;
			  text-align:center;
			  padding:1vw 1vw;
			  padding:1vw 2vw;
			  font-weight:600;
			  font-size:5vw;
			  line-height:6.5vw;
		  }.main-subheading{
			  display:block;
			  padding:1vw 10vw;
			  color:rgba(100, 100, 100);
			  font-size:3.25vw;
			  line-height:4.50vw;
			  text-align:center;
		  }.primary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				padding: 0.5vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(2,96,105);
				display:inline-block;
			}.secondary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				display:inline-block;
				padding: 0.5vw 1.2vw;
				color:rgba(2,96,105);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(255,255,255);
			}.top-area button{
				margin:2vw 1vw;
				padding:2vw 4vw;
				font-size:4.2vw;
				border-radius:50vw;
			}.top-area p{
				font-size:3.5vw;
				line-height:5vw;
				margin:2vw;
				color:rgba(10, 10, 10);
			}
			.blocks-container {
				margin: 2vw 5vw;
				margin-top: 10vw;
				overflow-x: auto;  /* Allow horizontal scrolling */
				overflow-y: hidden;  /* Hide vertical scroll */
				height: 40vw;
				width: 100%;
				display: flex;  /* Flex layout for inline blocks */
				gap: 5vw;  /* Space between blocks */
			  }
			  
			  .block {
				background-color: rgba(255, 255, 255);
				display: flex;  /* Use flex for internal alignment */
				flex-direction: column;  /* Stack content vertically */
				border-radius: 1vw;
				border: 0.1vw solid rgba(240, 240, 240);
				box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
				overflow: hidden;  /* Hide any overflowed content */
			  }
			  
			  
			  
			  .block1, .block2, .block3, .block4, .block5 {
				flex-shrink: 0; /* Prevent blocks from shrinking */
				margin-right: 2vw; /* Space between blocks */
				
				border-radius: 2vw;
			  }
			  
		
			  .block1 {
				display: inline-block;
				width: 28vw;
				height: 40vw;
				img{
					width: 28vw;
					height: 40vw;
					object-fit: cover;
					border-radius: 1vw;
				}
			  }
		
			  .block2 {
				background-color: rgb(0, 58, 63);
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: center; /* Center the items vertically */
				align-items: center;  
				text-align: center;
				width: 30vw;
				height: 32vw;
				.heading-block{
					font-weight: 600;
					font-size: 3.5vw;
					margin:1vw 0;
					color: rgb(211, 255, 190);
				}
				.subheading-block{
					font-weight: 400;
					font-size: 2vw;
					line-height: 3vw;
					margin-top: 0;
					color:rgba(242, 242, 242);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(10, 10, 10);
					padding: 1vw 1.8vw;
					margin: 3vw 0;
					font-size: 3vw;
					border-radius: 1vw;
					display: inline-block;
				}
			  }
		
			  .block3 {
				display: inline-block;
				width: 46vw;
				height: 24vw;
				padding: 2vw 4vw;
				.subheading-block{
					font-weight: 400;
					font-size: 1vw;
					line-height: 1.8vw;
					color:rgba(245, 245, 245);
					
				}
				.heading-block{
					font-weight: 600;
					font-size: 3.5vw;
				}.description-block{
					font-weight: 400;
					margin-top: 0;
					font-size: 2.5vw;
					color:rgba(100, 100, 100);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(0, 58, 63);
					padding: 1vw 2vw;
					margin: 1.2vw 0;
					font-size: 3vw;
					border-radius: 1vw;
					display: inline-block;
				}
			  }
		
			  .block4 {
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: center; /* Center the items vertically */
				align-items: center;  
				text-align: center;
				background-color: rgb(211, 255, 190);
				width: 30vw;
				height: 32vw;
				.heading-block{
					font-weight: 600;
					font-size: 5vw;
					color:rgba(10, 10, 10);
				}
				.subheading-block{
					font-weight: 400;
					font-size: 2.6vw;
					line-height: 4vw;
					color:rgba(100, 100, 100);
				}.bi{
					color: rgb(211, 255, 190);
					background-color: rgb(0, 58, 63);
					padding: 0.5vw 0.8vw;
					margin: 2vw 0;
					font-size: 3vw;
					border-radius: 0.5vw;
					display: inline-block;
				}
			  }
		
			  .block5 {
				padding: 1.5vw 4vw;
				margin-right: 10vw;
				display: inline-block;
				flex-direction: column; /* Stack the elements vertically */
				justify-content: flex-end; /* Center the items vertically */
				align-items: left;  
				text-align: left;
				background-color: rgb(0, 58, 63);
				width: 28vw;
				height: 40vw;
				.heading-block{
					font-weight: 500;
					line-height: 4vw;
					font-size: 2.5vw;
					color:rgba(255, 255, 255);
					
				}.bi{
					color: rgb(211, 255, 190);
					margin: 4vw 0;
					font-size: 4vw;
					margin-top: 6vw;
					border-radius: 0.5vw;
					display: inline-block;
				}
			  }
			.info-block{
				padding:5vw 2vw;	
				border-radius:4vw;	
				margin:4vw 5vw;
				background:rgba(255, 255, 255);
			    box-shadow: 0 .125rem .25rem rgba(10, 10, 10, .2);
			    background-position: right top; /* Aligns the image to the top-right */
			    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
			    background-size: contain; /* Ensures the image maintains its aspect ratio */
			    height: 140vw; /* Adjust the height as needed */
			    width: 90vw; 
			}.info-block-content-area{
				width:80vw;
				margin-top:60vw;
				padding:2vw;
			}.infoblock-heading{
				font-size:5vw;
				line-height:8vw;
				font-weight:600;
			}.infoblock-subheading{
				font-size:4vw;
				line-height:6vw;
				color:rgba(50, 50, 50);
				margin:1.5vw 0;
				font-weight:500;
			}.info-block-bullet{
				font-size:4vw;
				margin:3vw 0;
				color:rgba(100, 100, 100);
				line-height:1.5vw;
			}.info-block-bullet .bi{
				margin-right:0.5vw;
			}.stack-container {
			  position: relative;
			  height: 900vh; /* Increased height to accommodate four items */
			}

			.stack-item {
			  position: sticky;
			  top: 5vw;
			  width: 100%;
			  height: 100vh; /* Each item takes up the full viewport height */
			  z-index: 1;
			  transition: transform 0.5s ease, opacity 0.5s ease;
			  transform-origin: top left; /* Shrink from the top left */
			}

			.stack-item.shrink {
			  transform: scale(0.9); /* Shrinks the previous stack item */
			  opacity: 0.8; /* Lower opacity to give a stacked feel */
			}

			.stack-item:nth-child(1) {
			  z-index: 1;
			}
			.stack-item:nth-child(2) {
			  z-index: 2;
			}
			.stack-item:nth-child(3) {
			  z-index: 3;
			}
			.stack-item:nth-child(4) {
			  z-index: 4;
			}
			.stack-item:nth-child(5) {
			  z-index: 5;
			}
			.stack-item:nth-child(6) {
			  z-index: 6;
			}
			.stack-item:nth-child(7) {
			  z-index: 7;
			}
			.stack-item:nth-child(8) {
			  z-index: 8;
			}.stack-container-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				margin:5vw;
				font-weight:600;
			}.info-area{
				padding:2vw 0;
				padding-bottom:4vw 0;
				background:rgba(245, 245, 245);
			}.pricing-area{
				padding:4vw 6vw;
			}.pricing-area-heading{
				display:block;
				font-size:4.5vw;
				text-align:center;
				font-weight:600;
				margin:1vw;
			}.pricing-area-subheading{
				display:block;
				font-size:3vw;
				text-align:center;
				font-weight:500;
				color:rgba(100, 100, 100);
			}.pricing-col-rows{
				overflow: auto;
				white-space: normal;
			}.col-price{
				display:inline-block;
			}.pricing-col{
				margin:2vw 0;
				border:0.15vw solid rgba(240, 240, 240);
				border-radius:4vw;
				padding:3vw;
			}.pricing-col-heading{
				font-size:4.6vw;
				font-weight:600;
			}.pricing-col-subheading{
				font-size:3vw;
				line-height:4.5vw;
				font-weight:400;
				color:rgba(150, 150, 150);
			}.pricing-col-price{
				font-size:5vw;
				font-weight:600;
				margin:5vw 0;
			}.pricing-col-price span{
				font-size:2.5vw;
				color:rgba(150, 150, 150);
			}.pricing-col button{
				border-radius:50vw;
				width:100%;
				padding:1vw 0;
				font-size:4vw;
				margin-bottom:1vw;
			}.feature-list{
				font-size:3vw;
				margin:2vw 0;
				color:rgba(50, 50, 50);
			}.feature-list .bi{
				margin-right:0.5vw;
				color:rgba(2,96,105);
			}.divider-container {
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  margin-bottom:2vw;
			}

			.line {
			  flex-grow: 1;
			  height: 0.1vw;
			  background-color: rgba(150, 150, 150);
			  border: none;
			}

			.divider-text {
			  padding: 0 2vw;
			  font-size: 3.2vw;
			  color: rgba(100, 100, 100);
			}.custom-plan-area{
					  background: linear-gradient(to right, rgba(2,96,105), rgba(2,96,105, 0.8));
					  padding:8vw 4vw;
					  border-radius:5vw;
					  margin:4vw 0;
			}.custom-plan-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				color:rgba(255, 255, 255);
				margin:2vw;
			}.custom-plan-subheading{
				font-size:3vw;
				padding:0 2vw;
				line-height:4vw;
				display:block;
				text-align:center;
				font-weight:400;
				margin-top:2vw;
				color:rgba(242, 242, 242);
			}.custom-plan-area button{
				margin:2vw 2vw;
				font-size:3vw;
				border-radius:2vw;
				border:none;
				padding:2vw 3vw;
				
			}.custom-plan-area .primary-button{
				background:rgba(10,10,10);
			}

			.contact-us-section{
				
					  background: rgba(0, 88, 88);
					  padding:6vw 4vw;
			}.contact-us-section-heading{
				display:block;
				font-size:5vw;
				text-align:center;
				margin:1vw 0;
				color:rgba(255, 255, 255);
			}.contact-us-section-subheading{
				display:block;
				font-size:3vw;
				line-height:4vw;
				margin:2vw 0;
				color:rgba(240, 240, 240);
				text-align:center;
			}.contact-us-section button{
				background:rgba(255, 255, 255);	
				border:none;
				font-size:3vw;
				color:rgba(2,96,105);
				margin:4vw 0;
				border-radius:10vw;
				padding:2vw 4vw;
			}.home-pricing-area .horizontalrow{
			  display: flex; /* Use flexbox for horizontal layout */
			  flex-wrap: nowrap; /* Prevent wrapping */
			  overflow:scroll;
			}.home-pricing-area .horizontalrow > div {
			  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
			  width: 100%; /* Set a specific width for each column */
			  margin-right:-12vw;
			}.faqss-area{
				padding:1vw 0vw;
			}.faqss-area .heading{
				text-align:center;
				color:rgba(2, 96, 105);
				font-size:4vw;
				line-height:5vw;
				font-weight:500;
			}.faqss-area .description{
				text-align:center;
				font-size:3vw;
				font-weight:500;
				line-height:4vw;
				color:rgba(50,50,50);
			}.accordion {
			  max-width: 100%;
			  margin: 4vw auto;
			}
		
			.faq-item {
			  border-bottom: 0.5vw solid rgba(240, 240, 240);
			  padding: 0.8vw 0;
			  margin:2.5vw 0;
			}
		
			.faq-question {
			  cursor: pointer;
			  padding: 2vw;
			  border-radius:1vw;
			  background-color: rgba(245, 245, 245);
			  font-weight: 500;
			  display: flex;
			  font-size:3.5vw;
			  justify-content: space-between;
			  align-items: center;
			}
		
			.faq-question.active {
			  background-color: rgba(240, 240, 240);
			}
		
			.faq-answer {
			  max-height: 0;
			  overflow: hidden;
			  transition: max-height 0.3s ease;
			  padding: 0.5vw 1vw;
			  font-size:3vw;
			  font-weight:500;
			}
		
			.faq-answer.open {
			  max-height: 50vw;
			  padding: 2.5vw 1vw;
			}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
	.home-body{
		  font-family: "Poppins", sans-serif;
		  background-color: rgba(250, 250, 250);
		  height:100vh;
		  .top-area{
			  padding:5vw 1vw;
			  background: linear-gradient(to right, rgba(250, 254, 255), rgba(250, 254, 255));
		  }.main-heading{
			  display:block;
			  text-align:center;
			  padding:1vw 1vw;
			  padding:1vw 2vw;
			  font-weight:600;
			  font-size:5vw;
			  line-height:6.5vw;
		  }.main-subheading{
			  display:block;
			  padding:1vw 10vw;
			  color:rgba(100, 100, 100);
			  font-size:3.25vw;
			  line-height:4.50vw;
			  text-align:center;
		  }.primary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				padding: 0.5vw 1.2vw;
				color:rgba(255, 255, 255);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(2,96,105);
				display:inline-block;
			}.secondary-button{
				border-radius: 0.4vw;
				font-size:2vw;
				font-weight:500;
				display:inline-block;
				padding: 0.5vw 1.2vw;
				color:rgba(2,96,105);
				border:0.2vw solid rgba(2,96,105);
				background:rgba(255,255,255);
			}.top-area button{
				margin:2vw 1vw;
				padding:2vw 4vw;
				font-size:4.2vw;
				border-radius:50vw;
			}.top-area p{
				font-size:3.5vw;
				line-height:5vw;
				margin:2vw;
				color:rgba(10, 10, 10);
			}.blocks-container {
			  display: flex;
			  margin:2vw 5vw;
			  margin-top:10vw;
			  align-items: flex-end; /* Aligns all blocks to the bottom */
			  justify-content: space-between; /* Optional: Spreads blocks evenly */
			  height: 15vw; /* The overall height of the container */
			}

			.block {
			  background-color: rgba(255, 255, 255);
			  border-radius:1vw;
			  border:0.1vw solid rgba(220,220,220);
			  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
			}

			.block1 {
			  width: 14vw;
			  height: 20vw;
			}

			.block2 {
			  width: 12vw;
			  height: 15vw;
			}

			.block3 {
			  width: 16vw;
			  height: 12vw;
			}

			.block4 {
			  width: 12vw;
			  height: 15vw;
			}

			.block5 {
			  width: 14vw;
			  height: 20vw;
			}.info-block{
				padding:5vw 2vw;	
				border-radius:4vw;	
				margin:4vw 5vw;
				background:rgba(255, 255, 255);
			    box-shadow: 0 .125rem .25rem rgba(10, 10, 10, .2);
			    background-position: right top; /* Aligns the image to the top-right */
			    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
			    background-size: contain; /* Ensures the image maintains its aspect ratio */
			    height: 140vw; /* Adjust the height as needed */
			    width: 90vw; 
			}.info-block-content-area{
				width:80vw;
				margin-top:60vw;
				padding:2vw;
			}.infoblock-heading{
				font-size:5vw;
				line-height:8vw;
				font-weight:600;
			}.infoblock-subheading{
				font-size:4vw;
				line-height:6vw;
				color:rgba(50, 50, 50);
				margin:1.5vw 0;
				font-weight:500;
			}.info-block-bullet{
				font-size:4vw;
				margin:3vw 0;
				color:rgba(100, 100, 100);
				line-height:1.5vw;
			}.info-block-bullet .bi{
				margin-right:0.5vw;
			}.stack-container {
			  position: relative;
			  height: 900vh; /* Increased height to accommodate four items */
			}

			.stack-item {
			  position: sticky;
			  top: 5vw;
			  width: 100%;
			  height: 100vh; /* Each item takes up the full viewport height */
			  z-index: 1;
			  transition: transform 0.5s ease, opacity 0.5s ease;
			  transform-origin: top left; /* Shrink from the top left */
			}

			.stack-item.shrink {
			  transform: scale(0.9); /* Shrinks the previous stack item */
			  opacity: 0.8; /* Lower opacity to give a stacked feel */
			}

			.stack-item:nth-child(1) {
			  z-index: 1;
			}
			.stack-item:nth-child(2) {
			  z-index: 2;
			}
			.stack-item:nth-child(3) {
			  z-index: 3;
			}
			.stack-item:nth-child(4) {
			  z-index: 4;
			}
			.stack-item:nth-child(5) {
			  z-index: 5;
			}
			.stack-item:nth-child(6) {
			  z-index: 6;
			}
			.stack-item:nth-child(7) {
			  z-index: 7;
			}
			.stack-item:nth-child(8) {
			  z-index: 8;
			}.stack-container-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				margin:5vw;
				font-weight:600;
			}.info-area{
				padding:2vw 0;
				padding-bottom:4vw 0;
				background:rgba(245, 245, 245);
			}.pricing-area{
				padding:4vw 6vw;
			}.pricing-area-heading{
				display:block;
				font-size:4.5vw;
				text-align:center;
				font-weight:600;
				margin:1vw;
			}.pricing-area-subheading{
				display:block;
				font-size:3vw;
				text-align:center;
				font-weight:500;
				color:rgba(100, 100, 100);
			}.pricing-col-rows{
				overflow: auto;
				white-space: normal;
			}.col-price{
				display:inline-block;
			}.pricing-col{
				margin:2vw 0;
				border:0.15vw solid rgba(240, 240, 240);
				border-radius:4vw;
				padding:3vw;
			}.pricing-col-heading{
				font-size:4.6vw;
				font-weight:600;
			}.pricing-col-subheading{
				font-size:3vw;
				line-height:4.5vw;
				font-weight:400;
				color:rgba(150, 150, 150);
			}.pricing-col-price{
				font-size:5vw;
				font-weight:600;
				margin:5vw 0;
			}.pricing-col-price span{
				font-size:2.5vw;
				color:rgba(150, 150, 150);
			}.pricing-col button{
				border-radius:50vw;
				width:100%;
				padding:1vw 0;
				font-size:4vw;
				margin-bottom:1vw;
			}.feature-list{
				font-size:3vw;
				margin:2vw 0;
				color:rgba(50, 50, 50);
			}.feature-list .bi{
				margin-right:0.5vw;
				color:rgba(2,96,105);
			}.divider-container {
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  margin-bottom:2vw;
			}

			.line {
			  flex-grow: 1;
			  height: 0.1vw;
			  background-color: rgba(150, 150, 150);
			  border: none;
			}

			.divider-text {
			  padding: 0 2vw;
			  font-size: 3.2vw;
			  color: rgba(100, 100, 100);
			}.custom-plan-area{
					  background: linear-gradient(to right, rgba(2,96,105), rgba(2,96,105, 0.8));
					  padding:8vw 4vw;
					  border-radius:5vw;
					  margin:4vw 0;
			}.custom-plan-heading{
				font-size:5vw;
				display:block;
				text-align:center;
				color:rgba(255, 255, 255);
				margin:2vw;
			}.custom-plan-subheading{
				font-size:3vw;
				padding:0 2vw;
				line-height:4vw;
				display:block;
				text-align:center;
				font-weight:400;
				margin-top:2vw;
				color:rgba(242, 242, 242);
			}.custom-plan-area button{
				margin:2vw 2vw;
				font-size:3vw;
				border-radius:2vw;
				border:none;
				padding:2vw 3vw;
				
			}.custom-plan-area .primary-button{
				background:rgba(10,10,10);
			}

			.contact-us-section{
				
					  background: rgba(0, 88, 88);
					  padding:6vw 4vw;
			}.contact-us-section-heading{
				display:block;
				font-size:5vw;
				text-align:center;
				margin:1vw 0;
				color:rgba(255, 255, 255);
			}.contact-us-section-subheading{
				display:block;
				font-size:3vw;
				line-height:4vw;
				margin:2vw 0;
				color:rgba(240, 240, 240);
				text-align:center;
			}.contact-us-section button{
				background:rgba(255, 255, 255);	
				border:none;
				font-size:3vw;
				color:rgba(2,96,105);
				margin:4vw 0;
				border-radius:10vw;
				padding:2vw 4vw;
			}.home-pricing-area .horizontalrow{
			  display: flex; /* Use flexbox for horizontal layout */
			  flex-wrap: nowrap; /* Prevent wrapping */
			  overflow:scroll;
			}.home-pricing-area .horizontalrow > div {
			  flex: 0 0 auto; /* Prevent shrinking and allow element width to define its size */
			  width: 100%; /* Set a specific width for each column */
			  margin-right:-12vw;
			}.home-blog-poster {
				width: 100%;
				height: 180px;
				object-fit: fill;
				border-radius: clamp(0.8vw, 1.2vw, 1.5vw) clamp(0.8vw, 1.2vw, 1.5vw) 0 0;
			  }
			  
			  .home-blog-heading {
				font-weight: 500;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: clamp(1rem, 1vw, 1.5rem);
				margin: clamp(0.5vw, 1vw, 1rem) 0;
				color: red;
			  }
			  
			  .home-blog-subheading {
				font-size: clamp(0.8rem, 0.8vw, 1.2rem);
				line-height: clamp(1.2rem, 1.2vw, 2rem);
				color: rgba(128, 128, 128);
				font-weight: 400;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			  }
			  
			  .home-blog {
				margin: clamp(0.5vw, 1vw, 1.2vw) 0;
				border-radius: clamp(0.5vw, 1vw, 1.5vw);
				cursor: pointer;
			  }
			  
			  .home-blog:hover {
				opacity: 0.7;
			  }.home-blog-date{
				color: rgba(100, 100, 100);
			  }.home-blog-author{
				color: rgba(10, 10, 10);
			  }
			  
			  .home-blog-content-area {
				padding: clamp(1vw, 1.5vw, 2vw) clamp(1vw, 1.5vw, 2vw);
			  }.faqss-area{
		padding:1vw 0vw;
	}.faqss-area .heading{
		text-align:center;
		color:rgba(2, 96, 105);
		font-size:4vw;
		line-height:5vw;
		font-weight:500;
	}.faqss-area .description{
		text-align:center;
		font-size:3vw;
		font-weight:500;
		line-height:4vw;
		color:rgba(50,50,50);
	}.accordion {
	  max-width: 100%;
	  margin: 4vw auto;
	}

	.faq-item {
	  border-bottom: 0.5vw solid rgba(240, 240, 240);
	  padding: 0.8vw 0;
	  margin:2.5vw 0;
	}

	.faq-question {
	  cursor: pointer;
	  padding: 2vw;
	  border-radius:1vw;
	  background-color: rgba(245, 245, 245);
	  font-weight: 500;
	  display: flex;
	  font-size:3.5vw;
	  justify-content: space-between;
	  align-items: center;
	}

	.faq-question.active {
	  background-color: rgba(240, 240, 240);
	}

	.faq-answer {
	  max-height: 0;
	  overflow: hidden;
	  transition: max-height 0.3s ease;
	  padding: 0.5vw 1vw;
	  font-size:3vw;
	  font-weight:500;
	}

	.faq-answer.open {
	  max-height: 50vw;
	  padding: 2.5vw 1vw;
	}
	}
}

